import enLocale from "../locales/en.json";
import esLocale from "../locales/es.json";
import ptLocale from "../locales/pt.json";
import frLocale from "../locales/fr.json";
import { enUS, esES, ptBR, frFR } from "@mui/x-data-grid/locales";

export default function getTranslations(locale) {
    let translationSet = enLocale;
    switch (locale) {
      case "es":
        translationSet = esLocale;
        break;
      case "pt":
        translationSet = ptLocale;
        break;
      case "fr":
        translationSet = frLocale;
        break;
      case "en":
      default:
        translationSet = enLocale;
        break;
    }
    return translationSet;
}

export function getDataGridLocale(locale) {
    let dataGridLocale = enUS;
    switch (locale) {
        case ('es'):
            dataGridLocale = esES;
            break;
        case ('pt'):
            dataGridLocale = ptBR;
            break;
        case ('fr'):
            dataGridLocale = frFR;
            break;
        case ('en'):
        default:
            dataGridLocale = enUS;
            break;
    }
    return dataGridLocale.components.MuiDataGrid.defaultProps.localeText;
}