import { setShowLoader } from './LoaderReducer';
import { enqueueSnackbar } from './NotifierReducer';
import { push } from 'connected-react-router';
import { getDealInvitationsListCount } from './DealInvitationCountReducer';
import { submitMakeOfferMessage } from './DealCommunicationReducer';

export const DEAL_SET_LIST = 'DEAL_SET_LIST';
export const DEAL_SET_DATA = 'DEAL_SET_DATA';
export const DEAL_SET_MESSAGELIST = 'DEAL_SET_MESSAGELIST';



export const submitDealRegisterForm = (payload = {}, loggedInUserData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true)); //loaders 
      const client = getState().restClient.rest;  //getting the current state from env url 
      const deal = await client.service('deal').create({
        "deal": payload
      });
      // const transaction = {
      //   dealId: deal.deal._id,
      //   equipment: deal.deal.dealEquipment,
      //   price: "$345",
      //   addedBy: `${loggedInUserData.user._id}`,
      //   selectionName: `${loggedInUserData.user.contact.firstName[0]} ${loggedInUserData.user.contact.lastName[0]}`
      // };
      // const transactionForDeal = await client.service('transaction').create({
      //   "transaction": transaction
      // });
      // const transactioncommunication = loggedInUserData.user.roleSystem === 'regular' && {
      //   transactioncommunication: [
      //     {
      //       dealId: deal.deal._id,
      //       message: `${loggedInUserData.user.contact.firstName[0]} ${loggedInUserData.user.contact.lastName[0]} was created this deal successfully`,
      //       transactionEquipment: deal.deal.dealEquipment,
      //       from: loggedInUserData.user._id,
      //       to: loggedInUserData.user._id,
      //       notificationType: "General Message",
      //       notes: "notes",
      //       askPrice: "",
      //       offerPrice: "",
      //       priceUnit: "USD",
      //       addedBy: loggedInUserData.user._id,
      //       selectionName: `${loggedInUserData.user.contact.firstName[0]} ${loggedInUserData.user.contact.lastName[0]}`
      //     },
      //     {
      //       dealId: deal.deal._id,
      //       message: `${loggedInUserData.user.contact.firstName[0]} ${loggedInUserData.user.contact.lastName[0]} was added to this deal communication successfully`,
      //       transactionEquipment: deal.deal.dealEquipment,
      //       from: loggedInUserData.user._id,
      //       to: loggedInUserData.user._id,
      //       notificationType: "General Message",
      //       notes: "notes",
      //       askPrice: "",
      //       offerPrice: "",
      //       priceUnit: "USD",
      //       addedBy: loggedInUserData.user._id,
      //       selectionName: `${loggedInUserData.user.contact.firstName[0]} ${loggedInUserData.user.contact.lastName[0]}`
      //     }
      //   ],
      //   dealname: deal.deal.dealName,
      //   dealparticipantemails: loggedInUserData.user.email
      // }

      // const dealMessageList = loggedInUserData.user.roleSystem === 'regular' && await client.service('transactioncommunication').create({
      //   "transactioncommunication": transactioncommunication.transactioncommunication,
      //   dealname: deal.deal.dealName,
      //   dealparticipantemails: loggedInUserData.user.email
      // });
      // const dealCommunicationList = loggedInUserData.user.roleSystem === 'regular' && {
      //   id: dealMessageList.transactioncommunication._id,
      //   dealId: dealMessageList.transactioncommunication.dealId,
      //   transactionEquipment: dealMessageList.transactioncommunication.transactionEquipment,
      //   // transactionId: dealMessageList.transactioncommunication.transactionId,
      //   message: dealMessageList.transactioncommunication.message,
      //   from: dealMessageList.transactioncommunication.from,
      //   to: dealMessageList.transactioncommunication.to,
      //   notificationType: dealMessageList.transactioncommunication.notificationType,
      //   notes: dealMessageList.transactioncommunication.notes,
      //   askPrice: dealMessageList.transactioncommunication.askPrice,
      //   offerPrice: dealMessageList.transactioncommunication.offerPrice,
      //   priceUnit: dealMessageList.transactioncommunication.priceUnit,
      //   addedBy: dealMessageList.transactioncommunication.addedBy,
      //   createdAt: dealMessageList.transactioncommunication.createdAt,
      //   updatedAt: dealMessageList.transactioncommunication.updatedAt,
      // }

      if (0) {  //Bulk Upload
        dispatch(getDealList())
      } else {
        dispatch({ type: DEAL_SET_DATA, payload: deal }) //
        // dispatch({ type: DEAL_SET_MESSAGELIST, payload: dealCommunicationList });
        dispatch(getDealInvitationsListCount(loggedInUserData.user._id));
        dispatch(push('/dealpage'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Deal Successfully Created.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/dealpage'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Deal Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const getDealList = (pageState, loggedInUserData, isInvited, filterChecked) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const quer = pageState?.participant.length > 0 ? {
        participant: pageState.participant,
        limit: pageState?.limit,
        page: pageState?.page,
        sort: pageState?.sort,
        search: pageState?.searchData,
      } : {
        limit: pageState?.limit,
        page: pageState?.page,
        sort: pageState?.sort,
        search: pageState?.searchData,
      }
      const fetchedDealList = await client.service('deal').find(
        {
          query: quer
        }
      );
      let dealList = [];
      if (fetchedDealList.deals && fetchedDealList.deals.data)
        dealList = fetchedDealList.deals.data.map(item => {
          const deals = {};
          deals.id = item._id;
          deals.dealName = item.dealName ?? '';
          deals.dealDescription = item.dealDescription ?? '';
          deals.dealStartDate = item.startDate == null ? '' : new Date(item.startDate).toLocaleString('en-US', {
            month: 'numeric', // numeric, 2-digit, long, short, narrow
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            hour: 'numeric', // numeric, 2-digit
            minute: 'numeric'
          }).split(', ').join(" ");
          // deals.dealEndDate = item.startDate == null ? '' : new Date(item.endDate).toLocaleString().split(', ').join(" ");
          // deals.buyerPrice = item.dealClosingSale.length > 0 ? item.dealClosingSale[0].buyerPrice : '';
          deals.creatorCompany = item.creatorCompany == null ? '' : item.creatorCompany.companyName[0]; //
          deals.creatorPeople = item.creatorPeople == null ? '' : `${item.creatorPeople.contact.firstName[0]} ${item.creatorPeople.contact.lastName[0]}`;
          deals.creatorId = item.creatorPeople == null ? '' : item.creatorPeople._id;
          deals.dealInvites = item.dealInvites ?? '';
          deals.dealParticipants = item.dealParticipants ?? '';
          deals.email = item.dealInvites.length > 0 ? item.dealInvites[0].email : "";
          deals.firstName = item.dealInvites.length > 0 ? item.dealInvites[0].firstName : '';
          deals.lastName = item.dealInvites.length > 0 ? item.dealInvites[0].lastName : '';
          deals.dealBrand = item.dealBrand == null ? '' : item.dealBrand.dealBrand.brandName;
          deals.brandDescription = item.dealBrand == null ? '' : item.dealBrand.dealBrand.brandDescription;
          deals.equipmentName = item.dealEquipment.length > 0 ? item.dealEquipment[0].equipmentName : '';
          deals.buyer = item.dealClosingSale.length > 0 ? item.dealClosingSale[0].buyer : '';
          deals.seller = item.dealClosingSale.length ? item.dealClosingSale[0].seller : '';
          deals.createdAt = item.createdAt ?? '';
          deals.UnreadMessages = item.UnreadMessages ?? 0;
          deals.dealEquipment = item.dealEquipment ?? [];
          deals.payment = item.dealClosingPayment?.length > 0 && item.dealClosingPayment[0]?.paymentStatus === "Completed" ? "Closed" : "Active";
          deals.dealClosingSale = item.dealClosingSale;
          return deals;
        })
      let filterDeals, sortedDeals;
      if (loggedInUserData, isInvited) {
        filterDeals = dealList.filter(
          (user) =>
            (user.dealInvites.some(
              (un) => un.invitedUser === loggedInUserData.user._id
            ) && user.dealInvites.some((un) => un.inviteStatus === "Sent" || un.inviteStatus === "Joined")) ||
            user.creatorId === loggedInUserData.user._id
        );
        sortedDeals = filterDeals.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedDeals = dealList;
      }
      dealList = { dealList: sortedDeals, total: fetchedDealList.deals.total, skip: fetchedDealList.deals.skip, limit: fetchedDealList.deals.limit }
      dispatch({ type: DEAL_SET_LIST, payload: dealList });
      dispatch(setShowLoader(false));
    } catch (error) {
      // console.log(error)
      dispatch({ type: DEAL_SET_LIST, payload: [] });
      dispatch(setShowLoader(false));
    }
  }
}

export const getDealInvitationsList = (loggedInUserData, isInvited) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(false));
      const client = getState().restClient.rest;
      const quer = {}
      const fetchedDealList = await client.service('deal').find(
        {
          query: quer
        }
      );
      let dealList = [];
      if (fetchedDealList.deals && fetchedDealList.deals.data)
        dealList = fetchedDealList.deals.data.map(item => {
          const deals = {};
          deals.id = item._id;
          deals.dealName = item.dealName ?? '';
          deals.dealDescription = item.dealDescription ?? '';
          deals.dealStartDate = item.startDate == null ? '' : new Date(item.startDate).toLocaleString('en-US', {
            month: 'numeric', // numeric, 2-digit, long, short, narrow
            day: 'numeric', // numeric, 2-digit
            year: 'numeric', // numeric, 2-digit
            hour: 'numeric', // numeric, 2-digit
            minute: 'numeric'
          }).split(', ').join(" ");
          deals.dealEndDate = item.startDate == null ? '' : new Date(item.endDate).toLocaleString().split(', ').join(" ");
          deals.buyerPrice = item.dealClosingSale.length > 0 ? item.dealClosingSale[0].buyerPrice : '';
          deals.creatorCompany = item.creatorCompany == null ? '' : item.creatorCompany.companyName[0]; //
          deals.creatorCompanyId = item.creatorCompany == null ? '' : item.creatorCompany._id; //
          deals.creatorPeople = item.creatorPeople == null ? '' : `${item.creatorPeople.contact.firstName[0]} ${item.creatorPeople.contact.lastName[0]}`;
          deals.creatorId = item.creatorPeople == null ? '' : item.creatorPeople._id;
          deals.dealInvites = item.dealInvites ?? '';
          deals.dealParticipants = item.dealParticipants ?? '';
          deals.email = item.dealInvites.length > 0 ? item.dealInvites[0].email : "";
          deals.firstName = item.dealInvites.length > 0 ? item.dealInvites[0].firstName : '';
          deals.lastName = item.dealInvites.length > 0 ? item.dealInvites[0].lastName : '';
          deals.dealBrand = item.dealBrand == null ? '' : item.dealBrand.dealBrand.brandName;
          deals.dealBrandId = item.dealBrand == null ? '' : item.dealBrand._id;
          deals.brandDescription = item.dealBrand == null ? '' : item.dealBrand.dealBrand.brandDescription;
          deals.equipmentName = item.dealEquipment.length > 0 ? item.dealEquipment[0].equipmentName : '';
          deals.buyer = item.dealClosingSale.length ? item.dealClosingSale[0].buyer : '';
          deals.seller = item.dealClosingSale.length ? item.dealClosingSale[0].seller : '';
          deals.createdAt = item.createdAt ?? '';
          deals.dealEquipment = item.dealEquipment ?? [];
          deals.UnreadMessages = item.UnreadMessages ?? []
          return deals;
        })
      let filterDeals, sortedDeals;
      if (loggedInUserData && isInvited) {
        filterDeals = dealList.filter(
          (user) =>
            user.dealInvites.some(
              (un) => un.invitedUser === loggedInUserData.user._id
            ) && user.dealInvites.some((un) => un.inviteStatus === "Sent" || un.inviteStatus === "Joined")
        );
        sortedDeals = filterDeals.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedDeals = dealList;
      }
      dealList = { dealList: sortedDeals, total: sortedDeals.length, skip: fetchedDealList.deals.skip, limit: fetchedDealList.deals.limit }
      dispatch({ type: DEAL_SET_LIST, payload: dealList });
      dispatch(setShowLoader(false));
    } catch (error) {
      // console.log(error)
      dispatch({ type: DEAL_SET_LIST, payload: [] });
      dispatch(setShowLoader(false));
    }
  }
}

export const getDealById = (dealID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getDeal = await client.service(`deal`).get(dealID, {})
      const fetchedDealData = fetchDealData(getDeal);
      // console.log("Deal Data: ", fetchedDealData);
      dispatch({ type: DEAL_SET_DATA, payload: fetchedDealData })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

const fetchDealData = (getDeal) => {
  try {
    const data = getDeal.deal || {};
    const values = {
      dealID: data._id,
      dealName: data.dealName,
      dealDescription: data.dealDescription,
      dealBrand: data.dealBrand,
      industry: data.industry,
      dealNetwork: data.dealNetwork,
      dealInvites: data.dealInvites,
      dealParticipants: data.dealParticipants,
      dealEquipment: data.dealEquipment,
      channelName: data.dealMessagingChannels.length ? data.dealMessagingChannels[0].channelName : '',
      channelType: data.dealMessagingChannels.length && data.dealMessagingChannels[0].channelType,
      buyer: data.dealClosingSale,
      dealClosingSale: data.dealClosingSale,
      buyerPrice: data.dealClosingSale && data.dealClosingSale.length ? data.dealClosingSale[0].buyerPrice : '',
      seller: data.dealClosingSale && data.dealClosingSale.length && data.dealClosingSale[0].seller,
      acceptedPrice: data.dealClosingSale && data.dealClosingSale.length ? data.dealClosingSale[0].acceptedPrice : '',
      author: data.rating && data.rating.length ? data.rating[0].author : '',
      bestRating: data.rating && data.rating.length ? data.rating[0].bestRating : '',
      worstRating: data.rating && data.rating.length ? data.rating[0].worstRating : '',
      ratingValue: data.rating && data.rating.length ? data.rating[0].ratingValue : '',
      reviewAspect: data.rating && data.rating.length ? data.rating[0].reviewAspect : '',
      ratingComments: data.rating && data.rating.length ? data.rating[0].ratingComments : '',
      lastUpdated: data.assetState && new Date(data.assetState.lastUpdated).toLocaleString().split(', ').join(" "),
      UnreadMessages: data.UnreadMessages
    };
    return values;
  } catch (err) {

  }
}
export const editDeal = (dealID, payload = {}, flag = false, messageInDeal) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(false));
      const client = getState().restClient.rest;
      const updatedDealData = await client.service('deal').patch(dealID, payload);
      const fetchedDealData = fetchDealData(updatedDealData);
      dispatch({ type: DEAL_SET_DATA, payload: fetchedDealData })
      if (!messageInDeal) {
        dispatch(enqueueSnackbar({
          message: 'Deal information EDITED And Saved.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            }
          },
        }));
      }
      if (flag === false) {
        dispatch(push(`/dealPage`));
      }
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Deal information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const editDealWithMessage = (dealID, payload = {}, flag = false) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(false));
      const client = getState().restClient.rest;
      const updatedDealData = await client.service('deal').patch(dealID, payload);
      const fetchedDealData = fetchDealData(updatedDealData);
      dispatch({ type: DEAL_SET_DATA, payload: fetchedDealData })
      // dispatch(enqueueSnackbar({
      //   message: 'Deal information EDITED And Saved.',
      //   options: {
      //     key: new Date().getTime() + Math.random(),
      //     variant: 'success',
      //     anchorOrigin: {
      //       vertical: 'top',
      //       horizontal: 'right',
      //     }
      //   },
      // }));
      if (flag === false) {
        dispatch(push(`/dealPage`));
      }
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Deal information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}


export const deleteDeal = (dealID, dealList, totalCount, id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('deal').remove(dealID);
      const newList = [...dealList];
      const index = dealList.findIndex(item => item.id === dealID);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = totalCount - 1; // Update totalRecords
        let listing = { dealList: newList, total: updatedTotalRecords }
        dispatch({ type: DEAL_SET_LIST, payload: listing });
        dispatch(getDealInvitationsListCount(id))
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Deal Information is deleted successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const dealInvitationAccept = (dealId, result, payload, id, transaction, transactioncommunication) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      //create the transaction object;
      const client = getState().restClient.rest;
      // const transactionForDeal = await client.service('transaction').create({
      //   "transaction": transaction
      // });
      const dealInvites = await client.service('deal').update(dealId, payload,
        {
          query: {
            '_id': dealId,
            'dealInvites._id': result
          }
        });
      if (Object.keys(dealInvites).length) {
        const result1 = dealInvites.deal.UnreadMessages.map((obj, i) => ({ ...obj, totalUnReadMessages: obj["totalUnReadMessages"] + 1 }));
        result1.push({
          "userId": id,
          "totalUnReadMessages": 0,
          "readMessages": true
        })
        dealInvites.deal.UnreadMessages = result1;
        await client.service('deal').patch(dealId, dealInvites.deal);
      }
      // transactioncommunication.transactioncommunication.transactionId = transactionForDeal.transaction._id;
      //Add the deal transactioncommunication object
      // dispatch(submitMakeOfferMessage(transactioncommunication));
      dispatch({ type: DEAL_SET_DATA, payload: dealInvites });
      dispatch(getDealInvitationsListCount(id));
      // dispatch(getDealList());
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Deal accepted successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));

      dispatch(push(`/dealpage`));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Deal accept failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const dealInvitationReject = (dealId, rejectResult, payload, id) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const dealInvites = await client.service('deal').update(dealId, payload,
        {
          query: {
            '_id': dealId,
            'dealInvites._id': rejectResult
          }
        });
      if (Object.keys(dealInvites).length) {
        const result1 = dealInvites.deal.UnreadMessages.map((obj, i) => ({ ...obj, totalUnReadMessages: obj["totalUnReadMessages"] + 1 }));
        result1.push({
          "userId": id,
          "totalUnReadMessages": 0,
          "readMessages": true
        })
        dealInvites.deal.UnreadMessages = result1;
        await client.service('deal').patch(dealId, dealInvites.deal);
      }
      dispatch({ type: DEAL_SET_DATA, payload: dealInvites });
      dispatch(getDealInvitationsListCount(id));
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Deal has been rejected`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      // dispatch(push(`/deal/grid?dealId=${dealId}`));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Deal Rejected Failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const getDealMessageList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedDealMessageList = await client.service('deals-Messages').find(
        {
          query: {
            $limit: 100
          }
        }
      );
      let dealMessageList = [];
      if (fetchedDealMessageList && fetchedDealMessageList.data)
        dealMessageList = fetchedDealMessageList.data.map(item => {
          const deal = {};
          deal.id = item._id;
          deal.name = item.dealName;
          deal.dealDescription = item.dealDescription;
          deal.startDate = item.startDate;
          deal.endDate = item.endDate;
          return deal;
        })
      dispatch({ type: DEAL_SET_MESSAGELIST, payload: dealMessageList });
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: DEAL_SET_MESSAGELIST, payload: [] });
    }
  }
}

export const getDealMessageListByDealId = (dealId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getDealMessageList = await client.service('deals-Messages').get(dealId, {})
      const fetchedDealMessageList = getDealMessageList.networks || {};
      let networkContactList = [];
      networkContactList.networkId = fetchedDealMessageList._id;
      if (fetchedDealMessageList && fetchedDealMessageList.networkContacts)
        networkContactList = fetchedDealMessageList.networkContacts.map(item => {
          const networkContact = {};
          networkContact.id = item._id;
          networkContact.firstName = item.firstName;
          networkContact.middleName = item.middleName;
          networkContact.lastName = item.lastName;
          networkContact.name = `${item.firstName} ${item.middleName} ${item.lastName}`;
          networkContact.emailAddress = item.emailAddress;
          networkContact.countryCode = item.countryCode;
          networkContact.mobileNumber = item.mobileNumber;
          networkContact.whatsAppNumber = item.whatsAppNumber;
          networkContact.smsNumber = item.smsNumber;
          return networkContact;
        })
      dispatch({ type: DEAL_SET_MESSAGELIST, payload: networkContactList })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const DealMessageCommunication = (messageInfo = {}) => {

  let payload = '';
  let message = '';
  let errorMessage = '';
  let redirectLogin = false;
  if (messageInfo.messageType === 'email') {
    payload = messageInfo;
    message = 'Your email updated successfully';
    errorMessage = 'Verify email'
    redirectLogin = true;
  }
  else if (messageInfo.messageType === 'sms') {
    payload = messageInfo;
    message = 'Your sms updated successfully';
    errorMessage = 'Verify sms'
    redirectLogin = true;
  }
  else if (messageInfo.messageType === 'whatsapp') {
    payload = messageInfo;
    message = 'Your whatsapp updated successfully';
    errorMessage = 'Verify whatsapp'
    redirectLogin = true;
  }
  return async (dispatch, getState) => {
    try {
      const client = getState().restClient.rest;
      const dealMessage = await client.service('deal-message').create(payload);
      dispatch({ type: DEAL_SET_MESSAGELIST, payload: dealMessage })
      dispatch(enqueueSnackbar({
        message: `${message}.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      if (redirectLogin) {
        dispatch(push('/deal/details?dealId=61666be60414460012fcd41f'));
      }
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: `${errorMessage} Failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export default function reducer(
  state = {
    dealList: [],
    dealMessageList: [],
    dealData: {},
  },
  action
) {
  switch (action.type) {
    case DEAL_SET_LIST:
      return {
        ...state,
        dealList: action.payload
      };
    case DEAL_SET_MESSAGELIST:
      return {
        ...state,
        dealMessageList: action.payload
      };
    case DEAL_SET_DATA:
      return {
        ...state,
        dealData: action.payload
      };
    default:
      break;
  }
  return state;
}