import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    submitDealRegisterForm,
    getDealList,
    dealInvitationAccept,
    dealInvitationReject,
    deleteDeal
} from '../../../reducers/DealReducer';
import { submitMakeOfferMessage } from "../../../reducers/DealCommunicationReducer";
import {
    Card,
    Typography,
    IconButton,
    Grid,
    Box,
    Tooltip,
    FormGroup,
    Switch,
    useMediaQuery,
    CardMedia,
    CardContent,
    Badge,
    Chip
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from '@mui/material/styles';
import { setLoggedInUserData } from '../../../reducers/UserReducer';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchBar from '../../../utils/Search';
import EquipmentTable from "./equipmentGrid"
import { DataGridPro } from '@mui/x-data-grid-pro';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ThemeProvider, createTheme } from '@mui/material';
import DealMessageChat from "../DealListGrid/dealChat";
import { Stack, width } from '@mui/system';
import CustomizedTooltips from "../../DealList/toolTip";
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import StarIcon from '@mui/icons-material/Star';
import equipmentImage from '../heavy-machinery-used-construction-industry-engineering_23-2151307723.avif';
import equipment2 from '../equipment2.jpg';
import equipment3 from '../equipment3.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel CSS
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import noImage from '../Image_not_available.png'
import { G } from '@react-pdf/renderer';
import { Container, LeftContainer, RightContainer, SubContainer, Component1, Component2 } from "../../../styles/appStyles"
import getTranslations, { getDataGridLocale } from "../../../utils/getTranslations";

const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
        color: '#0737AB', // Color when unchecked
        '&.Mui-checked': {
            color: '#0737AB', // Color when checked
            '& + .MuiSwitch-track': {
                backgroundColor: '#0737AB', // Track color when checked
            },
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#0737AB', // Track color when unchecked
    },
}));


const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        margin: '20px'
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center'
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 1.5rem'
    },
    actions: {
        color: "rgb(17, 82, 147)"
    },
    fullWidth: {
        marginLeft: "20px"
    },
    link: {
        color: "rgb(105, 105, 105)"
    },
    tableheader: {
        marginTop: 0
    },
    cardStyle: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Card takes 100% height of its parent
        minHeight: 0,   // Ensures proper handling of flexbox in some situations
    },
    toggleRadio: {
        display: "inline-block",
        marginLeft: "-10rem",
    },
    rejectText: {
        backgroundColor: "#EC7063",
    },
    dataGrid: {
        flexGrow: 1, // DataGridPro grows to fill the available space
        minHeight: 0, // Ensures it handles flexbox correctly
    },
    noScroll: {
        '& .MuiDataGrid-scrollbar': {
            overflowY: 'hidden !important',
            // color: "red"
        },
        '& .MuiDataGrid-main': {
            // height: "fixed",
        },
        '& .MuiBox-root': {
            // height: "fixed",
            position: "absolute"
        }
    },
    DataGridTitleStyle: {
        backgroundColor: "white",
        paddingLeft: "40px",
        width: "1310px",
        marginLeft: "-78px",
        marginTop: "18px",
        [theme.breakpoints.down('lg')]: {
            width: '90%',
            height: 'auto',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '1rem',
        },
        [theme.breakpoints.down('md')]: {
            width: '95%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '1rem',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '1rem',
        },
    }
}));

const scrollToTop = () => {
    window.scrollTo({ top: 133, behavior: 'smooth' });
};

const ImageCarousel = ({ dealEquipment, dealID }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleSlideChange = (index) => {
        setCurrentIndex(index);
    };
    return (
        <Carousel
            showThumbs={false}
            infiniteLoop={false}
            showStatus={false}
            emulateTouch
            dynamicHeight={false}
            selectedItem={currentIndex} // Set the current slide based on state
            onChange={handleSlideChange} // Update the current index on slide change
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                currentIndex > 0 && (
                    <IconButton
                        onClick={onClickHandler}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '15px',
                            transform: 'translateY(-50%)',
                            zIndex: 2,
                            color: 'white',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                        }}
                        aria-label={label}
                    >
                        <ArrowBackIosNewIcon />
                    </IconButton>
                )
            }
            renderArrowNext={(onClickHandler, hasNext, label) => {
                // Flatten all the images from dealEquipment
                const totalImages = dealEquipment.reduce((acc, equipment) => acc + equipment.pictures.length, 0);

                return (
                    currentIndex < totalImages - 1 && (
                        <IconButton
                            onClick={onClickHandler}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '15px',
                                transform: 'translateY(-50%)',
                                zIndex: 2,
                                color: 'white',
                            }}
                            aria-label={label}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    )
                );
            }}
        >
            {dealEquipment.map((equipment) => (
                equipment.pictures.length > 0 ? (
                    equipment.pictures.map((picture, idx) => (
                        <Link to={`/deal/dealEquipment?dealId=${dealID}&equipmentId=${equipment._id}`} key={idx}>
                            <div key={`${equipment._id}_${idx}`} style={{ position: 'relative' }}>
                                <img
                                    src={`https://atgdevstore.blob.core.windows.net/equipment/${equipment._id}/${picture}`}
                                    alt={equipment.equipmentName}
                                    style={{
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '200px',
                                    }}
                                />
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        color: 'white',
                                        padding: '5px 10px',
                                        borderTopLeftRadius: '16px',
                                        borderTopRightRadius: '16px',
                                        textAlign: 'left',
                                        fontSize: '16px',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    <Tooltip title={equipment.equipmentName}>
                                        <Chip
                                            label={
                                                equipment.equipmentName.length > 15
                                                    ? `${equipment.equipmentName.substring(0, 15)}...`
                                                    : equipment.equipmentName
                                            }
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </Link>
                    ))
                ) : (
                    <Link to={`/deal/dealEquipment?dealId=${dealID}&equipmentId=${equipment._id}`}>
                        <div key={equipment._id} style={{ position: 'relative' }}>
                            <img
                                src={noImage} // Fallback image when no pictures are available
                                alt="No image available"
                                style={{
                                    borderTopLeftRadius: '16px',
                                    borderTopRightRadius: '16px',
                                    objectFit: 'cover',
                                    width: '100%',
                                    height: '200px',
                                }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    color: 'white',
                                    padding: '5px 10px',
                                    borderTopLeftRadius: '16px',
                                    borderTopRightRadius: '16px',
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <Tooltip title={equipment.equipmentName}>
                                    <Chip
                                        label={
                                            equipment.equipmentName.length > 15
                                                ? `${equipment.equipmentName.substring(0, 15)}...`
                                                : equipment.equipmentName
                                        }
                                    />
                                </Tooltip>
                            </div>
                        </div>
                    </Link>
                )
            ))}
        </Carousel>
    );
};

const DealList = props => {
    const { dealList = [], totalCount, getDealList, loggedInUserData, locale } = props;
    const dataGridLocaleProps = getDataGridLocale(locale);

    const history = useHistory();
    const classes = useStyles();
    const [filterChecked, setFilterChecked] = useState((loggedInUserData?.user?.roleSystem === 'admin') ? false : true);
    const [pageState, setPageState] = useState({
        page: 1,
        limit: 100,
        sort: null,
        searchData: '',
        participant: loggedInUserData?.user?.roleSystem === 'admin' ? '' : loggedInUserData?.user?._id
    });
    const [transactionListForMakeOffer, setTransactionListForMakeOffer] = useState([]);

    const [search, setSearch] = React.useState('');
    const [filteredDealList, setFilteredDealList] = useState([]);
    const [rowCountState, setRowCountState] = React.useState(totalCount);
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = React.useState(
        [],
    );

    const dataGridRef = useRef(null);
    const transactionId = "6231b09f5292800b4074918e";

    const translationSet = getTranslations(locale);
    const deal_labels = translationSet.deal_page;

    // useEffect(() => {
    //     // Set filteredUserList to userList initially
    //     if (dealList.length > 0) {
    //         setFilteredDealList(dealList);
    //     }
    // }, [dealList]);

    useEffect(() => {
        // Filter user list when search state changes
        if (dealList?.length > 0) {
            const filteredList = dealList.filter(user =>
                (user.dealName && user.dealName.toLowerCase().includes(search.toLowerCase())) ||
                (user.dealDescription && user.dealDescription.toLowerCase().includes(search.toLowerCase())) ||
                (user.dealStartDate && user.dealStartDate.toLowerCase().includes(search.toLowerCase())) ||
                (user.creatorCompany && user.creatorCompany.toLowerCase().includes(search.toLowerCase()))
            );
            // console.log("filteredList-1",filteredList);
            setFilteredDealList(filteredList);
            if (filteredList.length > 0) {
                setRowCountState(filteredList?.length)
            }
        }
    }, [search, dealList]);

    useEffect(() => {
        const getList = async () => {
            if (pageState, loggedInUserData) {
                const isInvited = true;
                await getDealList(pageState, loggedInUserData, isInvited);
            } else {
                await getDealList(pageState);
            }
        }
        getList();
    }, [getDealList, loggedInUserData, pageState]);


    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            totalCount !== undefined ? totalCount : prevRowCountState
        );
    }, [totalCount, setRowCountState]);


    const handleActiveDealChange = (event) => {
        setFilterChecked(event.target.checked);
        if (event.target.checked) {
            setPageState((old) => ({ ...old, participant: loggedInUserData.user._id }));
        } else {
            setPageState((old) => ({ ...old, participant: '' }));
        }
    }

    const handleDealEdit = (event, deal) => {
        history.push(`/create/deals/create?action=edit&id=${deal.id}`);
    }

    const handleDealDelete = (event, deal) => {
        props.deleteDeal(deal.id, dealList, totalCount, loggedInUserData.user._id);
    }

    const toggleToMyDeals = () => {
        setFilterChecked(true);
        setPageState((old) => ({ ...old, participant: loggedInUserData.user._id }));
    };

    const handleAccept = async (event, deal) => {
        const dealId = deal.id;
        const dealInvitesId = (deal.dealInvites || []).filter(
            (un) => un.invitedUser === loggedInUserData.user._id
        );
        const filterInvites = dealInvitesId.map((un) => un._id);
        const result = filterInvites.reduce((acc, cur) => {
            acc = cur;
            return acc;
        }, {});
        const payload = {
            isAccepted: true,
            dealInvites: {
                "dealInvites.$.inviteStatus": "Joined",
            },
            dealParticipants: {
                dealParticipants: [
                    {
                        startDate: new Date().toISOString(),
                        endDate: new Date(
                            new Date().setDate(new Date().getDate() + 10)
                        ).toISOString(),
                        participant: loggedInUserData.user._id,
                    },
                ],
            },
        };

        const transactioncommunication = {
            transactioncommunication: {
                dealId: dealId,
                transactionId: transactionId,
                message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} added to this deal successfully`,
                transactionEquipment: deal.dealEquipment,
                from: loggedInUserData.user._id,
                to: deal.dealParticipants.map((p) => { return p.participant._id }),
                notificationType: "General Message",
                notes: "notes",
                askPrice: "",
                offerPrice: "",
                priceUnit: "USD",
                addedBy: loggedInUserData.user._id
            },
            dealname: deal.dealName,
            dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
        }
        await props.dealInvitationAccept(dealId, result, payload, loggedInUserData.user._id);
        await props.submitMakeOfferMessage(transactioncommunication);
        await getDealList({ ...pageState, participant: loggedInUserData.user._id }, loggedInUserData, true);
        setFilterChecked(true);
    };

    const handleReject = async (event, deal) => {
        const dealId = deal.id;
        const dealInvitesId = (deal.dealInvites || []).filter(
            (un) => un.invitedUser === loggedInUserData.user._id
        );
        const filterInvites = dealInvitesId.map((un) => un._id);
        const rejectResult = filterInvites.reduce((acc, cur) => {
            acc = cur;
            return acc;
        }, {});
        const payload = {
            isAccepted: false,
            dealInvites: {
                "dealInvites.$.inviteStatus": "Left",
            },
            dealParticipants: {
                // dealParticipants: [
                //   {
                //     startDate: "",
                //     endDate: "",
                //     participant: "",
                //   },
                // ],
            },
        };

        const transactioncommunication = {
            transactioncommunication: {
                dealId: dealId,
                transactionId: transactionId,
                message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} was rejected this deal`,
                transactionEquipment: deal.dealEquipment,
                from: loggedInUserData.user._id,
                to: deal.dealParticipants.map((p) => { return p.participant._id }),
                notificationType: "General Message",
                notes: "notes",
                askPrice: "",
                offerPrice: "",
                priceUnit: "USD",
                addedBy: loggedInUserData.user._id
            },
            dealname: deal.dealName,
            dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
        }
        await props.dealInvitationReject(dealId, rejectResult, payload, loggedInUserData.user._id);
        await props.submitMakeOfferMessage(transactioncommunication);
        await getDealList({ ...pageState, participant: loggedInUserData.user._id }, loggedInUserData, true);
        setFilterChecked(true);
    };

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiDataGrid-container--top [role=row]": {
                            background: '#3E5272',
                            color: "white"
                        },
                    },
                    columnSeparator: {
                        visibility: "hidden",
                    },
                    sortIcon: {
                        color: "#ee9907",
                    }
                },
            },
        },
    });

    const columns = [
        {
            field: "dealName",
            headerName: deal_labels.deal_name,
            width: 300,
            renderCell: (params) => (
                <>
                    <Stack
                        direction="row"
                        // spacing={2}
                        justifyContent="start"
                        alignItems="start"
                        marginTop="-15px"
                        fontFamily="sans-serif"
                        fontSize="smaller"
                        sx={{ position: 'sticky', top: 0, left: 0, zIndex: 1 }}
                    // fontWeight= "400"
                    >
                        {/* <Badge badgeContent={(Array.isArray(params?.row?.UnreadMessages) ? params?.row?.UnreadMessages.filter((row) => row?.userId === loggedInUserData?.user?._id).map((x) => x.totalUnReadMessages)[0] : 0)} color="primary">
                            <ChatBubbleOutlineIcon color="action" />
                        </Badge> */}
                        {/* <Link to={`/deal/grid?dealId=${params.id}`} style={{ textDecoration: "none", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self"> */}
                        <CustomizedTooltips data={params?.row} />
                        {/* </Link> */}
                    </Stack>
                </>
            ),
        },
        {
            field: "payment",
            headerName: deal_labels.deal_status,
            type: "string",
            width: 150,
        },
        {
            field: "dealDescription",
            headerName: deal_labels.deal_description,
            type: "string",
            width: 200,
        },
        {
            field: "dealStartDate",
            headerName: deal_labels.deal_start,
            type: "string",
            width: 220,
        },
        {
            field: "creatorCompany",
            headerName: deal_labels.company,
            width: 220,
        },
        {
            field: "actions",
            headerName: deal_labels.actions,
            sortable: false,
            width: 150,
            renderCell: (params) => {
                const dealInvites = params.row.dealInvites || [];
                const inviteSent = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Sent");
                const inviteJoined = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Joined");
                // const isAdmin = loggedInUserData.user.roleSystem === 'admin';
                const logged = params?.row?.dealParticipants?.some(un => un.participant?._id === loggedInUserData?.user?._id);
                const isLoggedInUser = dealInvites?.createtorid !== loggedInUserData?.user?._id;

                return (
                    <>
                        {inviteSent && isLoggedInUser ?
                            <div>
                                <button
                                    onClick={(event) => handleAccept(event, params.row)}
                                    className={classes.accept}
                                >
                                    {deal_labels.accept}
                                </button>
                                &nbsp;&nbsp;
                                <button
                                    onClick={(event) => handleReject(event, params.row)}
                                    className={classes.reject}
                                >
                                    {deal_labels.reject}
                                </button>
                            </div>
                            :
                            <div>
                                {logged || (loggedInUserData.user.roleSystem === 'admin') ?
                                    <>
                                        {filterChecked ?
                                            <>
                                                <Tooltip title="Click here to Communicate to the deal">
                                                    <IconButton
                                                        sx={{ color: "#3E5272" }}
                                                    >
                                                        <Link to={`/deal/grid?dealId=${params.id}`} style={{ textDecoration: "none", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self">
                                                            <ChatBubbleOutlineOutlinedIcon />
                                                        </Link>
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit Deal">
                                                    <IconButton
                                                        sx={{ color: "#3E5272" }}
                                                        onClick={(event) => {
                                                            handleDealEdit(event, params.row);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                            : null}
                                        {logged || (loggedInUserData.user.roleSystem === 'admin') ?
                                            <Tooltip title="Delete Deal">
                                                <IconButton
                                                    sx={{ color: "#3E5272" }}
                                                    onClick={(event) => {
                                                        window.confirm(
                                                            "Are you sure want to delete " +
                                                            params.row.dealName +
                                                            " ?"
                                                        ) && handleDealDelete(event, params.row);
                                                    }}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            null}
                                    </>
                                    :
                                    (
                                        <p className={classes.rejectText}>Rejected</p>
                                    )}

                            </div>
                        }
                    </>
                );
            },
        },
    ];
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

    function DataGridTitle() {
        return (
            <Grid container spacing={2} justifyContent="flex-start" my={0.5}>
                <Grid item xs={12} lg={4}>
                    <FormGroup row>
                        <Typography variant="h6" color="main">{deal_labels.all_deals}</Typography>
                        <CustomSwitch checked={filterChecked} onChange={handleActiveDealChange} name="checkedC" disabled={(loggedInUserData?.user?.roleSystem === 'admin')} />
                        <Typography variant="h6" color="main">{deal_labels.my_deals}</Typography>
                    </FormGroup>
                </Grid>
                <Grid item xs={12} lg={8}>
                    {isSmallScreen ? <Stack direction="row" spacing={1} justifyContent='flex-start'>
                        <SearchBar
                            searchText={search}
                            filterChange={onFilterChange}
                            onClickFunc={handleClearText}
                        />
                        <Tooltip title={deal_labels.create_deals_tooltip}>
                            <Link to="/create/deals">
                                <IconButton sx={{ color: "#3E5272" }}>
                                    <AddBoxIcon />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </Stack>
                        : <Stack direction="row" spacing={1} justifyContent='flex-end'>
                            <Tooltip title={deal_labels.create_deals_tooltip}>
                                <Link to="/create/deals">
                                    <IconButton sx={{ color: "#3E5272" }}>
                                        <AddBoxIcon />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                            <SearchBar
                                searchText={search}
                                filterChange={onFilterChange}
                                onClickFunc={handleClearText}
                            />
                        </Stack>}
                </Grid>
            </Grid >
        );
    }

    const onFilterChange = (event) => {
        setSearch(event.target.value);

    };

    const handleClearText = () => {
        setSearch('');
        setPageState((old) => ({ ...old, searchData: '' }));
    };


    const handleDetailPanelExpandedRowIdsChange = React.useCallback((newIds) => {
        setDetailPanelExpandedRowIds(
            newIds.length > 1 ? [newIds[newIds.length - 1]] : newIds,
        );
    }, []);


    const getDetailPanelContent = React.useCallback(
        ({ row }) =>
            <>
                <EquipmentTable dealData={row} loggedInUserData={loggedInUserData} dealID={row?.id} transactionListForMakeOffer={transactionListForMakeOffer.length && transactionListForMakeOffer} />
                {/* <DealMessageChat dealData={row} users={(row?.dealParticipants || [])?.filter((item) => item?.participant?._id != loggedInUserData?.user?._id)} equipment={row?.dealEquipment} setTransactionListForMakeOffer={setTransactionListForMakeOffer} /> */}
            </>,
        [loggedInUserData, transactionListForMakeOffer],
    );

    const getDetailPanelHeight = React.useCallback(() => 180, []);


    return (
        // <Card className={classes.cardStyle}>
        //     <DataGridTitle />
        //     <ThemeProvider theme={theme}>
        //         <DataGridPro
        //             ref={dataGridRef}
        //             className={classes.dataGrid}
        //             slots={{
        //                 detailPanelExpandIcon: ChevronRightIcon,
        //                 detailPanelCollapseIcon: ExpandMoreIcon,
        //             }}
        //             rowCount={filteredDealList.length >= 0 ? filteredDealList.length : rowCountState}
        //             rows={filteredDealList || []}
        //             columns={columns}
        //             getDetailPanelContent={getDetailPanelContent}
        //             getDetailPanelHeight={getDetailPanelHeight}
        //             onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
        //             detailPanelExpandedRowIds={detailPanelExpandedRowIds}
        //             componentsProps={{
        //                 row: { onClick: (params) => handleDetailPanelExpandedRowIdsChange([params.row.id]) },
        //                 cell: {
        //                     iconComponents: {
        //                         expandedIcon: <ExpandMoreIcon />,
        //                         collapsedIcon: <ChevronRightIcon />,
        //                     },
        //                 },
        //             }}
        //             disableColumnResize={true}
        //             disableExtendRowFullWidth={true}
        //             sx={{
        //                 "& .MuiDataGrid-scrollbar--horizontal": {
        //                     overflowX: 'hidden'
        //                 }
        //             }}
        //         />
        //     </ThemeProvider>
        // </Card>
        <Box sx={{ padding: '15px' }}>
            <DataGridTitle />
            <Grid container spacing={4}>
                {filteredDealList.map((deal) => {
                    const dealInvites = deal?.dealInvites || [];
                    const inviteSent = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Sent");
                    const inviteJoined = dealInvites.some(un => un.invitedUser === loggedInUserData.user._id && un.inviteStatus === "Joined");
                    const logged = deal?.dealParticipants?.some(un => un.participant?._id === loggedInUserData?.user?._id);
                    const isLoggedInUser = dealInvites?.createtorid !== loggedInUserData?.user?._id;
                    const dealEquipment = deal?.dealEquipment;
                    // const dealEquipmentName = deal?.dealEquipment?.equipmentName;
                    const dealImages = [
                        {
                            image: equipmentImage,
                            index: 1,
                            equipName: 'Single Man Lift',
                        },
                        {
                            image: equipment2,
                            index: 2,
                            equipName: 'Wheel Tractor-Scraper',
                        },
                        {
                            image: equipment3,
                            index: 3,
                            equipName: 'Compact Track and Multi-Terrain Loader',
                        }
                    ];
                    return (
                        <Grid item xs={12} sm={6} md={4} key={deal.id}>
                            <Card sx={{ borderRadius: '16px', boxShadow: 3, position: 'relative' }}>
                                {inviteSent && isLoggedInUser ?
                                    <Stack direction='row'
                                        sx={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            zIndex: 1,
                                        }}>
                                        <button
                                            onClick={(event) => handleAccept(event, deal)}
                                            className={classes.accept}
                                        >
                                            Accept
                                        </button>
                                        &nbsp;&nbsp;
                                        <button
                                            onClick={(event) => handleReject(event, deal)}
                                            className={classes.reject}
                                        >
                                            Reject
                                        </button>
                                    </Stack>
                                    :
                                    <Stack direction='row'
                                        sx={{
                                            position: 'absolute',
                                            top: '10px',
                                            right: '10px',
                                            zIndex: 1,
                                        }}>
                                        {logged || (loggedInUserData.user.roleSystem === 'admin') ?
                                            <>
                                                {filterChecked ?
                                                    <>
                                                        <Tooltip title="Click here to Communicate to the deal">
                                                            <IconButton
                                                            >
                                                                <Link to={`/deal/grid?dealId=${deal.id}`} style={{ textDecoration: "none", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self">
                                                                    <ChatBubbleIcon sx={{ color: "#3E5272" }} />
                                                                </Link>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit Deal">
                                                            <IconButton
                                                                sx={{ color: "#3E5272" }}
                                                                onClick={(event) => {
                                                                    handleDealEdit(event, deal);
                                                                }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </>
                                                    : null}
                                                {logged || (loggedInUserData.user.roleSystem === 'admin') ?
                                                    <Tooltip title="Delete Deal">
                                                        <IconButton
                                                            sx={{ color: "#3E5272" }}
                                                            onClick={(event) => {
                                                                window.confirm(
                                                                    "Are you sure want to delete " +
                                                                    deal.dealName +
                                                                    " ?"
                                                                ) && handleDealDelete(event, deal);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                    :
                                                    null}
                                            </>
                                            :
                                            (
                                                <p className={classes.rejectText}>Rejected</p>
                                            )}
                                    </Stack>
                                }
                                <ImageCarousel dealEquipment={dealEquipment} dealID={deal.id} />
                                <CardContent>
                                    <Tooltip title="Deal Name">
                                        <Typography variant="h6" component="div">
                                            {deal.dealName}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title="Creator Company">
                                        <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                                            {deal.creatorCompany}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title="Creator People">
                                        <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                                            {deal.creatorPeople}
                                        </Typography>
                                    </Tooltip>
                                    <Tooltip title='Deal Description'>
                                        <Typography variant="body2" color="text.primary">
                                            {deal.dealDescription}
                                        </Typography>
                                    </Tooltip>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    )
}
const mapStateToProps = state => ({
    dealList: state.deal && state.deal.dealList.dealList,
    loggedInUserData: state.user && state.user.loggedInUserData,
    totalCount: state.deal && state.deal.dealList.total,
    limit: state.deal && state.deal.dealList.limit,
    locale: state.locale
});
export default connect(mapStateToProps, { submitDealRegisterForm, getDealList, deleteDeal, setLoggedInUserData, dealInvitationAccept, dealInvitationReject, submitMakeOfferMessage })(DealList);
