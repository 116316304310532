import React, { useState, useEffect } from "react";
import { Visibility, VisibilityOff, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import useQuery from "../../utils/useQuery";
import { getDealMessageList } from '../../../reducers/DealCommunicationReducer';
import { submitTransactionRegisterForm } from "../../../reducers/TransactionReducer";
import { submitMakeOfferMessage, submitMakeOfferMessageWithoutTransactionId } from "../../../reducers/DealCommunicationReducer";
import { DataGrid } from "@mui/x-data-grid";
import {
    Card,
    Grid,
    Checkbox,
    Button,
    TextField,
    Box,
    Typography,
    InputAdornment,
    IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { getTransactionList } from "../../../reducers/TransactionReducer";
// import { DealMessageChat } from "../../DealChat/index";
// import DealSome from "../DealListGrid/dealCloseOut";
import getTranslations, { getDataGridLocale } from "../../../utils/getTranslations";


const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeaders: {
                    backgroundColor: '#3E5272',
                    color: 'white',
                },
                columnSeparator: {
                    visibility: "hidden",
                },
                sortIcon: {
                    color: "#ee9907",
                }
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    grid: {
        marginTop: 10,
    },
    makeOfferContainer: {
        paddingRight: "5rem",
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    makeOffer: {
        color: "rgb(204, 102, 0)",
        fontSize: "large",
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: "medium",
        },
    },

    makeOfferFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 10,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            justifyContent: "center",
        },
    },

    gridChild: {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    icon: {
        color: "rgb(204, 102, 0)",
        alignItems: "center",
    },
    parent: {
        display: "grid",
        gridTemplateColumns: "7rem 2rem 7rem",
        paddingLeft: "2px",
        marginTop: "-2px",
    },
    input: {
        padding: "7px 0px 8px 0px",
        width: "170px",
        textAlign: "center",
        borderRadius: 3,
        border: "1px solid #555",
    },
    topGrid: {
        paddingTop: "20px",
    },
    makeSeller: {
        paddingLeft: "90px",
        color: "rgb(204, 102, 0)",
        fontSize: "large",
        fontWeight: 700,
    },
    salConst: {
        paddingLeft: "3px",
        paddingBottom: "20px",
        // color: "#696969",
        fontSize: "X-large",
        fontWeight: "bolder",
    },
    salConsttwo: {
        paddingLeft: "3px",
        paddingBottom: "20px",
        // color: "#696969",
        fontSize: "large",
        fontWeight: "bolder",
        marginTop: "-2px",
        display: "flex"
    },
    bottonGrid: {
        paddingLeft: "90px",
        marginTop: "-78px",
    },
    link: {
        textDecoration: "none",
    },
    mediaLink: {
        textDecoration: "none",
        color: "black",
        "&:hover": {
            backgroundColor: "rgb(180, 180, 180)",
        },
    },
    button: {
        textAlign: "right",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    btnwidth: {
        width: "134%",
        paddingTop: "10px",
    },
    dataGrid: {
        // paddingBottom: "1px",
    },
    numberField: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    MakeButton: {
        color: "white",
        backgroundColor: "rgb(62, 82, 114)",
        "&:hover": {
            backgroundColor: "#fbbc05",
            //   color: "black"
        },
    },
    childDiv: {
        paddingTop: "10px",
        paddingLeft: "50px",
    },
    dealEquip: {
        paddingLeft: "10px",
    },
    hidePagination: {
        '& .MuiDataGrid-footerContainer': {
            display: 'none',
        },
    },
    totalPrice: {
        fontSize: "medium",
        fontWeight: "800",
        color: "rgb(120, 120, 120)",
    }
}));
const SerialNumberCell = ({ params }) => {
    const [showSerialNumbers, setShowSerialNumbers] = useState(false);

    const toggleSerialNumberVisibility = () => {
        setShowSerialNumbers(!showSerialNumbers);
    };
    return (
        <>
            {params.value ?
                <div>
                    {showSerialNumbers ? params.value : "••••••••••••••••"}
                    <IconButton
                        aria-label="toggle serial number visibility"
                        onClick={toggleSerialNumberVisibility}
                        edge="end"
                    >
                        {!showSerialNumbers ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </div> : ""}
        </>
    );
};

const DataGridDemo = (props) => {
    const {
        dealData,
        loggedInUserData,
        dealID,
        restClient: { rest },
        transactionListForMakeOffer,
        getTransactionList,
        transactionList = [],
        locale
    } = props;
    const equipment_grid_labels = getTranslations(locale).equipment_grid_component;
    const dataGridLocaleProps = getDataGridLocale(locale);

    const classes = useStyles();
    const [data, setData] = useState();
    const [rows, setRows] = React.useState([]);
    const [dealTransactionList, setDealTransactionList] = useState([]);


    useEffect(() => {
        if (Array.isArray(dealData.dealEquipment)) {
            setRows(dealData.dealEquipment);
        }
    }, [dealData.dealEquipment]);

    useEffect(() => {
        const getMessageList = async () => {
            try {
                if (dealID && rest) {
                    await getDealMessageList(dealID);
                }
            } catch (e) {
                console.log(e);
            }
        };
        getMessageList();
    }, [rest, getDealMessageList, dealID]);

    useEffect(() => {
        const getTransactionListByDealId = async () => {
            try {
                if (dealID && rest) {
                    await getTransactionList(dealID); // Fetch the transaction list
                }
            } catch (e) {
                console.log(e);
            }
        };
        getTransactionListByDealId();
    }, [rest, getTransactionList, dealID]);

    const val = dealData?.dealParticipants?.filter((p) => p.participant._id !== loggedInUserData.user._id);
    const [first, setFirst] = useState([]);

    function filterTransactions(transactionListForMakeOffer, dealData, loggedInUserData) {
        return (transactionListForMakeOffer || []).filter(transaction =>
            transaction.userGroup.includes(dealData.seller) &&
            transaction.userGroup.includes(loggedInUserData.user._id) &&
            transaction.userGroup.length === 2
        );
    }

    const getRowData = () => {
        const ifSellerAcceptedDeal = dealData?.dealParticipants.filter((participant) => participant.participant._id === dealData.seller);
        if (ifSellerAcceptedDeal.length) {
            // const ifTransactionIdExistsWithSeller = filterTransactions(transactionListForMakeOffer, dealData, loggedInUserData);//Previous ONE
            const ifTransactionIdExistsWithSeller = filterTransactions(dealTransactionList, dealData, loggedInUserData);
            if (ifTransactionIdExistsWithSeller.length > 0) {
                const transactioncommunication = {
                    transactioncommunication: {
                        dealId: dealID,
                        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
                        transactionId: ifTransactionIdExistsWithSeller[0]?.id,
                        transactionEquipment: first,
                        from: loggedInUserData.user._id,
                        to: dealData?.seller,
                        notificationType: "Make Offer",
                        notes: "notes",
                        askPrice: "",
                        offerPrice: data,
                        priceUnit: "USD",
                        addedBy: loggedInUserData.user._id,
                    },
                    dealname: dealData.dealName,
                    dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
                }
                props.submitMakeOfferMessage(transactioncommunication);
            } else {
                const transaction = {
                    dealId: dealID,
                    equipment: first,
                    selectionName: "selectionName",
                    price: data,
                    addedBy: loggedInUserData.user._id,
                    userGroup: [loggedInUserData.user._id, dealData?.seller],
                    transactionType: "Individual"
                };
                const transactioncommunication = {
                    transactioncommunication: {
                        dealId: dealID,
                        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
                        transactionEquipment: first,
                        from: loggedInUserData.user._id,
                        to: dealData?.seller,
                        notificationType: "Make Offer",
                        notes: "notes",
                        askPrice: "",
                        offerPrice: data,
                        priceUnit: "USD",
                        addedBy: loggedInUserData.user._id,
                    },
                    dealname: dealData.dealName,
                    dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
                }
                const dealId = props?.dealCommunicationList[0]?.dealId;
                const payload = { transaction };
                props.submitMakeOfferMessageWithoutTransactionId(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload, transactioncommunication);
            }
        } else {
            alert("Seller did not accept the deal.Please ask him to accept.If issue persists, make a REFRESH of the page.")
        }
    };

    function generateRandom() {
        var length = 8,
            charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const handleTextFieldChange = (event, rowId) => {
        if (rowId) {
            let val = 0;
            const elems = document.getElementsByName("num1");
            elems.forEach(function (valueCheck) {
                if (valueCheck.value !== "") val = val + parseFloat(valueCheck.value);
            });
            setData(val);
            setFirst([...first, rowId]);
        }
    };

    function formatCurrency(value) {
        if (value) {
            return `$${value}`;
        } else {
            return null;
        }
    }

    const reserveValue = loggedInUserData?.user?._id === dealData?.seller;

    const isSeller = loggedInUserData?.user?._id === dealData?.seller;
    const isBuyer = loggedInUserData?.user?._id === dealData?.buyer;
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: isSeller ? "#2ecd71" : "#1a75c3",
                            color: "white",
                        },
                    },
                    columnSeparator: {
                        visibility: "hidden",
                    },
                },
            },
        },
    });

    useEffect(() => {
        if (transactionList?.length > 0) {
            setDealTransactionList(transactionList);
        }
    }, [transactionList]);
    return (
        <div>
            <Card sx={{ width: "90%", marginLeft: "53px" }}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        autoHeight
                        rows={rows || []}
                        columns={[
                            {
                                field: "equipmentName",
                                headerName: equipment_grid_labels.equipment,
                                width: 270,
                            },
                            {
                                field: "modelYear",
                                headerName: equipment_grid_labels.model_year,
                                width: 100,
                                valueFormatter: (params) => {
                                    const date = new Date(params.value);
                                    return date.getFullYear();
                                },
                            },
                            {
                                field: "make",
                                headerName: equipment_grid_labels.make,
                                width: 120,
                            },
                            {
                                field: "serialNum",
                                headerName: equipment_grid_labels.serial_number,
                                width: 200,
                                renderCell: (params) => <SerialNumberCell params={params} />,
                            },
                            ...(reserveValue ? [{
                                field: "reservePrice",
                                headerName: equipment_grid_labels.reserve_price,
                                width: 150,
                                valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                            }] : []),
                            {
                                field: "listingPrice",
                                headerName: equipment_grid_labels.listing_price,
                                width: 150,
                                valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                            },
                            ((dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id)) ? {
                                field: "price",
                                width: 200,
                                headerName: equipment_grid_labels.buyer_price,
                                renderCell: (rowdata) => (
                                    <TextField
                                        // disabled={!selectedRows.includes(rowdata.row._id)}
                                        // value={data}
                                        className={classes.numberField}
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => {
                                            handleTextFieldChange(event, rowdata.row._id);
                                        }}
                                        id="textBox"
                                        name="num1"
                                        type="number"
                                        inputProps={{ min: 0 }}
                                        style={{ padding: "10px 0px 10px 0px", width: "147px" }}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                ),
                            } : ""),
                        ]}
                        getRowId={(row) => generateRandom()}
                        pageSize={5}
                        // rowsPerPageOptions={[5]}
                        pagination={false} // Disable pagination
                        disableSelectionOnClick
                        className={classes.hidePagination}
                        localeText={dataGridLocaleProps}
                        sx={{
                            "& .css-ptiqhd-MuiSvgIcon-root": {
                                color: "#ee9907",
                            },
                            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                                display: 'none'
                            }
                        }}
                    />
                </ThemeProvider>
            </Card>

            <div className={classes.grid}>
                <Grid container spacing={0} className={classes.makeOfferContainer}>
                    <Grid item lg={12}>
                        <div className={classes.makeOfferFlex}>
                            {(dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id)) ? <div className={classes.gridChild}>
                                <div style={{ lineHeight: 0 }}>
                                    {/* <AttachMoneyIcon className={classes.icon} fontSize="small" /> */}
                                </div>
                                <div className={classes.totalPrice}>{equipment_grid_labels.total_price}</div>
                                <div>
                                    <TextField
                                        type="number"
                                        value={data}
                                        readOnly
                                        size="small"
                                        placeholder={equipment_grid_labels.offer_placeholder}
                                        className={classes.input}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <AttachMoneyIcon />
                                                </InputAdornment>
                                            ),
                                            inputProps: { readOnly: true }
                                        }}
                                    />
                                </div>
                            </div> : ""}
                            {dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) ? (
                                <div>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!data}
                                        onClick={getRowData}
                                        className={classes.MakeButton}
                                    >
                                        {equipment_grid_labels.offer_button}
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
const mapStateToProps = state => ({
    restClient: state.restClient,
    dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
    transactionList: state.transaction && state.transaction.transactionList,
    locale: state.locale
});

export default connect(mapStateToProps, {
    getDealMessageList, submitMakeOfferMessage,
    submitTransactionRegisterForm,
    submitMakeOfferMessageWithoutTransactionId
})(DataGridDemo);
