import { enqueueSnackbar } from './NotifierReducer';
import { push } from 'connected-react-router';
import { setUserAuthenticated } from './UserReducer';
import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';

export const SET_ACCOUNT_DATA = 'SET_ACCOUNT_DATA';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';

const client = feathers();
const restClient = rest(process.env.REACT_APP_API_URL);
//client.configure(socketio(socketClient))
client.configure(restClient.fetch(window.fetch));
client.configure(auth({ storageKey: 'auth' }));
export const verifyUser = (action, value) => {
  let payload = '';
  let message = '';
  let errorMessage = '';
  let redirectLogin = false;
  let errorMsg = false;
  if (action === 'verifySignupLong') {
    payload = value;
    message = 'Account Verified';
    errorMessage = 'Account Verification'
  }
  else if (action === 'resendVerifySignup') {
    payload = {
      userName: value
    };
    message = 'Verification link has been sent successfully';
    errorMessage = 'Resend verification link'
  }
  else if (action === 'sendResetPwd') {
    payload = value
    if (value.role === "admin") {
      message = 'Reset password link has been sent successfully';
      errorMessage = 'Send reset password link';
    }
    else {
      message = 'Sent a link to the registered email account to reset the password';
      errorMessage = 'Sorry, we could not find a user with email ' + value.email + '. Please enter the registered email id';
      redirectLogin = true;
      errorMsg = true;
    }
  }
  else if (action === 'resetPwdLong') {
    payload = value;
    message = 'Your password updated successfully';
    errorMessage = 'Reset password'
    redirectLogin = true;
  }
  else if (action === 'verifySignupSetPasswordLong') {
    payload = value;
    message = 'Your password updated successfully';
    errorMessage = 'Verify Signup Set Password'
    redirectLogin = true;
  }
  return async (dispatch, getState) => {
    try {
      // const client = getState().restClient.rest;
      const verifiedUser = await client.service('authmanagement').create({
        action,
        value: payload
      });
      const role = verifiedUser && verifiedUser.roleSystem === 'regular' ? 'User' : 'Admin'
      dispatch({ type: SET_ACCOUNT_DATA, payload: verifiedUser })
      dispatch(enqueueSnackbar({
        message: errorMsg ? `${message}.` : `${role} ${message}.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      if (redirectLogin) {
        dispatch(push(verifiedUser.roleSystem === 'regular' ? '/Login' : '/Login'));
      }
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: errorMsg ? `${errorMessage}` : `${errorMessage} Failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const submitChangePassword = (userId, existingPassword, password) => {
  return async (dispatch, getState) => {
    try {
      const client = getState().restClient.rest;
      const verifyExisting = await client.service(`user`).update(userId, {
        existingPassword,
        password,
      });
      if (verifyExisting && verifyExisting.message) {
        return alert(`${verifyExisting.message}`);
      }
      else {
        dispatch(enqueueSnackbar({
          message: `Password Updated successfully.`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            }
          },
        }));
        dispatch(setUserAuthenticated(false));
      }
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: `Password Updation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}


export default function reducer(
  state = {
    accountData: {},
    accountList: null
  },
  action
) {
  switch (action.type) {

    // Account
    case SET_ACCOUNT_DATA:
      return {
        ...state,
        accountData: action.payload
      };
    case SET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload
      };
    default:
      break;
  }
  return state;
}
