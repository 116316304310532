import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import { deleteImage } from "./azureBlob";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default function InputFileUpload({ files, setFiles, action, equipmentID, setFilesUploaded }) {

    const handleFileUpload = (event) => {
        const selectedFiles = Array.from(event.target.files);
        setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    };

    const removeFile = (fileName, isNewFile) => {
        if (!isNewFile) {// Remove already uploaded file
            const decision = window.confirm(`Are you sure youneed to delete this ${fileName} image`);
            if (decision) {
                deleteImage('equipment', `${equipmentID}/${fileName}`);
                setFiles((prevFiles) => prevFiles.filter(file => file !== fileName));
            }
        } else {
            // Remove newly uploaded file
            setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
        }
    };


    return (
        <div>
            {/* Upload Button */}
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{ marginBottom: 2 }}
                fullWidth
            >
                Upload Images
                <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileUpload}
                    multiple
                    accept="image/*"
                />
            </Button>

            {/* Display Existing and Newly Uploaded Files */}
            {files.length > 0 && (
                <Box
                    mt={2}
                    display="flex"
                    overflow="auto"
                    whiteSpace="nowrap"
                    sx={{
                        '&::-webkit-scrollbar': {
                            height: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#ccc',
                            borderRadius: '4px',
                        },
                        gap: 2,
                    }}
                >
                    {/* Render Files */}
                    {files.map((file, index) => {
                        const fileUrl = file instanceof File
                            ? URL.createObjectURL(file)
                            : `https://atgdevstore.blob.core.windows.net/equipment/${equipmentID}/${file}`;

                        const isNewFile = file instanceof File;

                        return (
                            <Box
                                key={index}
                                position="relative"
                                display="inline-block"
                                sx={{
                                    minWidth: 100,
                                    height: 100,
                                    flex: '0 0 auto',
                                }}
                            >
                                <img
                                    src={fileUrl}
                                    alt={isNewFile ? file.name : file}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                        borderRadius: 8,
                                    }}
                                />
                                <IconButton
                                    onClick={() => removeFile(isNewFile ? file.name : file, isNewFile)}
                                    aria-label="remove"
                                    size="small"
                                    sx={{
                                        position: 'absolute',
                                        top: 5,
                                        right: 5,
                                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                        '&:hover': {
                                            backgroundColor: 'rgba(255, 255, 255, 1)',
                                        },
                                    }}
                                >
                                    <CancelIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        );
                    })}
                </Box>
            )}
        </div>
    );
}