import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { submitCreateCompanyBranchForm, getCompanyBranchListByCompanyId, removeCompanyBranch } from '../../../reducers/CompanyBranchReducer';
import { getCompanyById } from '../../../reducers/CompanyReducer';
import useQuery from '../../../utils/useQuery';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '20px'
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 1.5rem'
  },
  actions: {
    color: "#3E5272"
  },
  header: {
    maxWidth: "700px",
    backgroundColor: "#9E9E9E"
  },
  childDiv: {
    paddingTop: "10px"
  },
  cardStyle: {
    height: "480px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem",
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      height: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
  },
}));

const CompanyBranchList = props => {
  const classes = useStyles();
  const { companyBranchList = [], restClient: { rest }, getCompanyBranchListByCompanyId, companyData, getCompanyById } = props;
  const query = useQuery();
  const action = query.get('action');
  const companyId = query.get('id');
  const history = useHistory();

  //Get the data from companybranchreducer
  useEffect(() => {
    if (companyId && rest) {
      const getBranchList = async () => {
        try {
          if (rest) {
            await getCompanyBranchListByCompanyId(companyId);
          }
          getCompanyById(companyId);
        } catch (e) {
          console.log(e);
        }
      };
      getBranchList();
    }
  }, [action, companyId, getCompanyBranchListByCompanyId, getCompanyById, rest]);

  // edit company branch.
  const handleCompanyBranchEdit = (event, companyBranch) => {
    history.push(`/companybranchescreate?action=edit&id=${companyId}&branchId=${companyBranch.id}`);
  }
  // delete company branch.
  const handleCompanyBranchDelete = (event, companyBranch) => {
    props.removeCompanyBranch(companyId, companyBranch.id, companyBranchList);
  }

  const columns = [
    {
      field: 'branchID',
      headerName: 'Branch ID',
      width: 370
    },
    {
      field: 'branchName',
      headerName: 'Branch Name',
      type: 'string',
      width: 370
    },
    {
      field: 'region',
      headerName: 'Region',
      type: 'string',
      width: 350
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Company">
              <IconButton sx={{ color: "#3E5272" }} onClick={(event) => {
                handleCompanyBranchEdit(event, params.row);
              }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete Company'>
              <IconButton sx={{ color: "#3E5272" }} onClick={(event) => {
                window.confirm("Are you sure want to delete it?") && handleCompanyBranchDelete(event, params.row);
              }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    }
  ]

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              COMPANY BRANCHES
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              {companyData && companyData.companyName}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container spacing={0}>
              <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New Company Branch">
                    <Link to={"/companybranchescreate?action=create&id=" + companyId} >
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <div>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={companyBranchList || []}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            sx={{
              '& .css-ptiqhd-MuiSvgIcon-root': {
                color: '#ee9907'
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }
            }
            // density='standard'
            components={{ Toolbar: DataGridTitle }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  companyBranchList: state.companyBranch && state.companyBranch.companyBranchList,
  companyData: state.company.companyData
});

export default connect(mapStateToProps, { submitCreateCompanyBranchForm, getCompanyBranchListByCompanyId, removeCompanyBranch, getCompanyById })(CompanyBranchList);

