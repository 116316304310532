import { enqueueSnackbar } from './NotifierReducer';
import { push } from 'connected-react-router';
import { editDealWithMessage } from "./DealReducer";
import { editCompany } from './CompanyReducer';
import { setShowLoader } from './LoaderReducer';
import { getDealMessageList } from "./DealCommunicationReducer";
export const SET_TRANSACTION_DATA = 'SET_TRANSACTION_DATA';
export const SET_TRANSACTION_LIST = 'SET_TRANSACTION_LIST';
export const GROUP_TRANSACTION_DATA = 'GROUP_TRANSACTION_DATA';

export const submitTransactionRegisterForm = (dealID = "", dealData = {}, payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(false)); //loaders 
      const client = getState().restClient.rest;  //getting the current state from env url 
      const transaction = await client.service('transaction').create({
        "transaction": payload.transaction
      });
      dispatch(editDealWithMessage(dealID, dealData, true));
      dispatch({ type: SET_TRANSACTION_DATA, payload: transaction }) //
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Offer Made Successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
//For Group chat's transactoion
export const GenerateTransactionRegisterForm = (dealID, payload = {}, transactionCommunication) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(false)); //loaders 
      const client = getState().restClient.rest;  //getting the current state from env url 
      const transactionGroup = await client.service('transaction').create({
        "transaction": payload.transaction
      });
      transactionCommunication.transactionId = transactionGroup?.transaction?._id;
      transactionCommunication.selectionName = transactionGroup?.transaction?.selectionName;//group name
      const groupData = await client.service('transactioncommunication').create({
        "transactioncommunication": transactionCommunication
      });
      dispatch({ type: GROUP_TRANSACTION_DATA, payload: groupData }) //
      dispatch({ type: SET_TRANSACTION_DATA, payload: groupData }) //
      getTransactionList(dealID);
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction Made Successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const getTransactionList = (dealID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedTransactionList = await client.service('transaction').find(
        {
          query: {
            dealId: dealID
          }
        }
      );
      let transactionList = [];
      if (fetchedTransactionList.transactions && fetchedTransactionList.transactions.data)
        transactionList = fetchedTransactionList.transactions.data.map(item => {
          const transaction = {};
          transaction.id = item._id;
          transaction.equipment = item.equipment;
          transaction.dealId = item.dealId;
          transaction.selectionName = item.selectionName;
          transaction.price = item.price;
          transaction.addedBy = item.addedBy;
          transaction.userGroup = item.userGroup;
          transaction.transactionType = item.transactionType;
          transaction.updatedAt = item.updatedAt;
          return transaction;
        })
      dispatch({ type: SET_TRANSACTION_LIST, payload: transactionList });
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch({ type: SET_TRANSACTION_LIST, payload: [] });
      dispatch(setShowLoader(false));
    }
  }
}
export const getTransactionById = (transactionID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getTransaction = await client.service(`transaction`).get(transactionID, {})
      const data = getTransaction.transaction || {};
      const values = {
        transactionID: data._id,
        equipment: data.equipment,
        dealId: data.dealId,
        selectionName: data.selectionName,
        price: data.price,

      };

      dispatch({ type: SET_TRANSACTION_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const editTransaction = (dealID, transactionID, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('transaction').patch(transactionID, payload);
      dispatch(enqueueSnackbar({
        message: 'Transaction information EDITED And Saved.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(getTransactionList(dealID));
      dispatch(getDealMessageList(dealID));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Transaction information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const deleteTransaction = (transactionID, transactionList) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('transaction').remove(transactionID);
      const newList = [...transactionList];
      const index = transactionList.findIndex(item => item.id === transactionID);
      newList.splice(index, 1);
      // let listing = { transactionList: newList }
      dispatch({ type: SET_TRANSACTION_LIST, payload: newList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Transaction Information is deleted successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const patchTransaction = (transactionID, payload = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const client = state.restClient.rest;
    try {
      dispatch(setShowLoader(true));
      const transaction = await client.service('transaction').patch(transactionID, payload);
      const { transactionList } = state.transaction || {};
      const newTransactionList = transactionList.map((item) => {
        if (item.id === transaction._id) {
          item.isActive = transaction.user.isActive;
        }
        return item;
      });
      dispatch({ type: SET_TRANSACTION_LIST, payload: newTransactionList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction ${transaction.user.isActive ? 'Created' : 'Not Created'} successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction activation/deactivation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const submitPaymentForm = (payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true)); //loaders 
      const client = getState().restClient.rest;  //getting the current state from env url 
      const transaction = await client.service('transaction-payment').create(
        payload
      );
      dispatch({ type: SET_TRANSACTION_DATA, payload: transaction })
      dispatch(setShowLoader(false));
      // dispatch(enqueueSnackbar({
      //   message: `Transaction Successfully completed.`,
      //   options: {
      //     key: new Date().getTime() + Math.random(),
      //     variant: 'success',
      //     anchorOrigin: {
      //       vertical: 'top',
      //       horizontal: 'right',
      //     }
      //   },
      // }));

    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const submitSubscriptionPaymentForm = (payload = {}, companyId, loggedInUserData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true)); //loaders 
      const client = getState().restClient.rest;  //getting the current state from env url 
      const today = new Date();
      const nextYear = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
      const utcToday = today.toISOString();
      const utcNextYear = nextYear.toISOString();
      const companyData = {
        "subscriptionData": {
          subscriptionStatus: "ACTIVE",
          planType: payload.amount === "10" ? "BASIC" : payload.amount === "100" ? "PROFESSIONAL" : payload.amount === "1000" ? "ENTERPRISE" : null,
          startDate: utcToday,
          EndDate: utcNextYear,
          users: [{
            userId: loggedInUserData.user._id,
            addedDate: utcToday,
          }]
        }
      }
      const transaction = await client.service('transaction-payment').create(
        {
          "transaction": payload,
          companyId,
          companyData,
        }
      );
      dispatch({ type: SET_TRANSACTION_DATA, payload: transaction })
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction Successfully completed.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/mySubscription'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Transaction failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export default function reducer(
  state = {
    transactionData: {},
    transactionList: [],
    groupData: {}
  },
  action
) {
  switch (action.type) {
    case SET_TRANSACTION_DATA:
      return {
        ...state,
        transactionData: action.payload
      };
    case SET_TRANSACTION_LIST:
      return {
        ...state,
        transactionList: action.payload
      };
    case GROUP_TRANSACTION_DATA:
      return {
        ...state,
        groupData: action.payload
      };
    default:
      break;
  }
  return state;
}