export const SET_LOCALE = "SET_LOCALE";

export const setLocale = (data) => {
    return (dispatch) => {
      dispatch({ type: SET_LOCALE, payload: data });
    }
}

export default function reducer(state = "en", action) {
    switch(action.type) {
        case SET_LOCALE:
            state = action.payload;
            break;
        default:
            break;
    }
    return state;
}