import React from 'react';
import { FormControl, Select, MenuItem } from '@mui/material';
import { connect } from 'react-redux';
import { setLocale } from '../../reducers/LocaleReducer';

const SelectLanguage = props => {

    const {locale} = props;

    const handleChange = (event) => {
        const loc = event.target.value;
        if (loc !== null) {
            props.setLocale(loc);
        }
    }

    return (
        <FormControl>
            <Select
                value={locale}
                size="small"
                defaultValue="en"
                onChange={handleChange}
                sx={{ backgroundColor: 'white', mx: 2, my: -2 }}
            >
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="es">Español</MenuItem>
                <MenuItem value="pt">Português</MenuItem>
                <MenuItem value="fr">Français</MenuItem>
            </Select>
        </FormControl>
    )
}

const mapStateToProps = (state) => ({
    locale: state.locale
});

export default connect(mapStateToProps, { setLocale })(
    SelectLanguage
);