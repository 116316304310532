import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    FormControl,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    FormLabel,
    FormHelperText,
    OutlinedInput,
    Dialog,
    DialogContent,
    DialogActions,
    DialogContentText,
    DialogTitle,
    Slide, Box
} from '@mui/material';
import { submitDealRegisterForm, getDealById, editDeal } from '../../reducers/DealReducer';
import { getNetworkList } from '../../reducers/NetworkReducer';
import { getEquipmentList } from '../../reducers/EquipmentReducer';
import { getUserList } from '../../reducers/UserReducer';
import validate from 'validate.js';
import useQuery from '../../utils/useQuery';
import { schema, initialFormValues, getPayload } from './utils';
import { setLoggedInUserData } from '../../reducers/UserReducer';
import { getBrandList } from '../../reducers/BrandReducer';
import {
    AppContent,
    AppContentInner,
    AppWrapper,
    AppMain,
    Header,
    HeaderText,
} from '../../../src/styles/appStyles';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../../src/styles/formStyles';
import { DataGrid } from '@mui/x-data-grid'
import Chip from '@mui/material/Chip';
import { useLocation } from 'react-router-dom';
import { industryList } from '../Equipment/CreateEquipment/utils'
import getTranslations from '../../utils/getTranslations';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const ITEM_TOP = 5;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            marginTop: ITEM_TOP,
        },
    },
};

const ITEM_MULTI_HEIGHT = 30;
const ITEM_MULTI_PADDING_TOP = 8;
const ITEM_MULTI_TOP = 25;
const MultiMenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_MULTI_HEIGHT * 4.5 + ITEM_MULTI_PADDING_TOP,
            width: 250,
            marginTop: ITEM_MULTI_TOP,
        },
    },
};
const DealRegisterComponent = (props) => {
    const { restClient: { rest }, dealData, networkList, equipmentList, userList, userData, loggedInUserData, brandList, getNetworkList, getBrandList, getDealById, getUserList, getEquipmentList, locale } = props;
    const create_deal_labels = getTranslations(locale).create_deal_page;


    const query = useQuery();
    const action = query.get('action');
    const dealID = query.get('id');
    const location = useLocation();
    // console.log("dealData", dealData);

    // initialFormValues.buyer = location.state ? location.state.buyerName : initialFormValues.buyer;
    // initialFormValues.seller = location.state ? location.state.sellerName : initialFormValues.seller;
    // initialFormValues.dealInvites = location.state ? [location.state.buyerName, location.state.sellerName] : initialFormValues.dealInvites;

    useEffect(() => {
        if (location.state) {
            setFormState((prevFormState) => ({
                ...prevFormState,
                values: {
                    ...prevFormState.values,
                    buyer: location.state.buyerName || initialFormValues.buyer,
                    seller: location.state.sellerName || initialFormValues.seller,
                    dealInvites: initialFormValues.dealInvites,
                },
            }));
        }
    }, [location.state]);

    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });
    const [dealEquipment, setDealEquipment] = useState([])
    const history = useHistory();

    const handleCancel = (event) => {
        event.preventDefault();
        // Go back to the previous page
        history.goBack();
    };
    const [dealDescription, setDealDescription] = useState("");

    const handleChangeDescription = (e) => {
        const inputValue = e.target.value;
        setDealDescription(inputValue.charAt(0).toUpperCase() + inputValue.slice(1));
    }


    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

    useEffect(() => {
        if (action === 'edit' && dealID && rest) {
            const getDeal = async () => {
                getDealById(dealID);
            }
            getDeal();
        }
    }, [action, dealID, rest, getDealById])

    useEffect(() => {
        if (rest) {
            const getDealNetwork = async () => {
                getNetworkList();
            }
            getDealNetwork();
        }
    }, [rest, getNetworkList])

    useEffect(() => {
        if (rest) {
            const getDealBrand = async () => {
                getBrandList();
            }
            getDealBrand();
        }
    }, [rest, getBrandList])

    useEffect(() => {
        if (rest) {
            const getDealInvitees = async () => {
                getUserList();
            }
            getDealInvitees();
        }
    }, [rest, getUserList])

    useEffect(() => {
        const pageState = {
            page: 1,
            limit: 100,
            sort: null,
            searchData: '',
        };
        if (rest) {
            const getDealEquipment = async () => {
                await getEquipmentList(pageState, loggedInUserData);
            }
            getDealEquipment();
        }
    }, [rest, getEquipmentList, loggedInUserData])

    useEffect(() => {
        if (action === 'edit' && dealData) {
            setFormState({
                isValid: false,
                values: dealData,
                touched: {},
                errors: {},
            });
            setDealEquipment(dealData.dealEquipment)
            setDealDescription(dealData.dealDescription)
        }
        // setDealEquipment(dealData.dealEquipment)
        // setDealDescription(dealData.dealDescription)
    }, [action, dealData]);

    const handleDealInvitesChange = (event) => {
        // event.persist();
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]: event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };


    const handleChange = (event) => {
        // event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };
    const handleStateChange = (field, value) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [field]: value
            },
            touched: {
                ...prevFormState.touched,
                [field]: true
            }
        }));
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [name]: value.trim(), // Trim leading and trailing spaces on blur
            },
            touched: {
                ...prevFormState.touched,
                [name]: true,
            },
        }));
    };

    const handleDescriptionBlur = (event) => {
        const { name, value } = event.target;
        const trimmedValue = value.trim();

        setDealDescription(trimmedValue);

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [name]: value.trim(), // Trim leading and trailing spaces on blur
            },
            touched: {
                ...prevFormState.touched,
                [name]: true,
            },
        }));
    };

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);


    const handleStartCaseChange = (event) => {
        const { name, value } = event.target;
        handleStateChange(name, StartCase(value));
    }

    const StartCase = (text) => {
        return text
            .split(" ")
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    };


    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }
        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }
        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));
        return isErrorExist;
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="down" ref={ref} {...props} />;
    });
    const AlertDialogSlide = () => {

        const [open, setOpen] = React.useState(false);
        const [data, setData] = React.useState([]);
        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        try {
            var editAdminList = formState.values.dealEquipment;
            var editUserEquipList = editAdminList.map(({ _id, ...rest }) => ({ id: _id, ...rest }));
            var userEquipmentList = equipmentList.filter((un) => un.companyId === userData.user.parentCompany._id && un.availabilityStatus);
            var EquList = [...editUserEquipList, ...userEquipmentList];
            var filteredEquipments = loggedInUserData.user.roleSystem === 'admin' || (action === 'edit') ? Object.values(EquList.reduce((acc, cur) => Object.assign(acc, { [cur.id]: cur }), {})) : userEquipmentList;
        } catch (err) {
            //   console.log(err);
        }

        try {
            var selectedRowsData = (action === "edit") && loggedInUserData.user.roleSystem === 'admin' ? editAdminList.filter((o) => o._id).map((o) => o._id) : filteredEquipments.filter(({ id }) => formState.values.dealEquipment.some(({ _id }) => _id === id)).map((r) => r.id);
            setSelectionModel(selectedRowsData)
        } catch (err) {

        }
        const [selectionModel, setSelectionModel] = useState(selectedRowsData)
        const [selectedRows, setSelectedRows] = React.useState([]);

        const handleTableSelection = () => {
            setDealEquipment(data);
            handleClose();
        };

        return (
            <div>
                <Button variant="outlined" color="primary" onClick={handleClickOpen} fullWidth maxWidth="lg" sx={{ p: 1.7 }}>
                    {action === 'edit' ? create_deal_labels.manage_equipment : create_deal_labels.add_equipment}
                </Button>
                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        {(action === 'edit') ? create_deal_labels.manage_equipment : create_deal_labels.add_equipment}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div style={{ height: 400, width: "100%" }}>
                                <DataGrid
                                    checkboxSelection
                                    rowHeight={50}
                                    rows={filteredEquipments || []}
                                    columns={[
                                        { headerName: 'Equipment Name', field: 'equipmentName', width: 250 },
                                        { headerName: 'Make', field: 'make', width: 200 },
                                        { headerName: 'Model', field: 'model', width: 200 },
                                        { headerName: 'model Year', field: 'modelYear', width: 250 },
                                        { headerName: 'Listing Price', field: 'listingPrice', width: 200 },
                                    ]}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(e) => {
                                        setSelectionModel(e);
                                        const selectedIDs = new Set(e);
                                        const selectedRows = filteredEquipments.filter((r) => selectedIDs.has(r.id));
                                        setData(selectedRows);
                                        setSelectedRows(selectedRows);
                                    }}
                                    sx={{
                                        "& .css-ptiqhd-MuiSvgIcon-root": {
                                            color: "#ee9907",
                                        },
                                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                                            display: 'none'
                                        },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: '#3E5272',
                                            color: 'white',
                                        },
                                        "& .MuiDataGrid-columnSeparator": {
                                            visibility: "hidden"
                                        }
                                    }}
                                />
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <FormButtonWrapper className='fram'>
                        <DialogActions>
                            <Button onClick={handleClose} color="secondary" variant='contained'>
                                CLOSE
                            </Button>
                            <Button onClick={handleTableSelection} color="secondary" variant='contained'>
                                ADD
                            </Button>
                        </DialogActions>
                    </FormButtonWrapper>
                </Dialog>
            </div>
        );
    }
    function updateIds(originalArray, checkArray) {
        for (let i = 0; i < originalArray.length; i++) {
            if (!checkArray.includes(originalArray[i])) {
                // Update the ID 
                originalArray[i] = 2;  // Replace 'updatedValue' with the desired new value
            }
        }
    }

    const submitForm = (event) => {
        event.preventDefault();
        if (!checkErrors()) {
            const { values } = formState;
            const payload = getPayload(values);
            const commonArr = [].concat.apply([], [values.dealInvites, values.buyer, values.seller])
            const unique = [...new Set(commonArr.map(item => item))];
            const filArray = unique.filter((un) => un !== loggedInUserData.user._id);
            let invitedUsers, removedUsers;
            if (action == "edit") {
                let filArray = unique
                invitedUsers = dealData.dealFilInvites.filter((x) => filArray.includes(x.invitedUser._id)).map((x) => { return { ...x, invitedUser: x.invitedUser._id } });
                removedUsers = dealData.dealFilInvites.filter((x) => !filArray.includes(x.invitedUser._id)).map((x) => x.invitedUser._id);
                let newUsers = filArray.filter(element => !dealData.dealFilInvites.some(obj => obj.invitedUser._id === element));
                let another_array = userList.filter((un) => { return newUsers.includes(un.id) }).map((item) => ({ email: item.email, sendDate: new Date().toISOString(), invitedUser: item.id, inviteStatus: "Sent", firstName: item.firstName[0], lastName: item.lastName[0] }))
                invitedUsers = invitedUsers.concat(another_array);
            }
            let Arr = userList.filter((un) => { return filArray.includes(un.id) }).map((item) => ({ email: item.email, sendDate: new Date().toISOString(), invitedUser: item.id, inviteStatus: "Sent", firstName: item.firstName[0], lastName: item.lastName[0] }))
            let part = !(loggedInUserData.user.roleSystem === 'admin') ? [{ startDate: new Date().toISOString(), endDate: new Date(new Date().setDate(new Date().getDate() + 10)).toISOString(), participant: loggedInUserData.user._id }] : [];
            let UnreadMessages = !(loggedInUserData.user.roleSystem === 'admin') ? [{ "readMessage": false, "totalUnReadMessages": 0, userId: loggedInUserData.user._id }] : [];
            let dealEquipmentList = location.state ? location.state.selectedRows : dealEquipment;
            let Equipment = dealEquipmentList.filter((un) => un.id).length ? dealEquipmentList.map((item) => ({ _id: item.id, fleetType: item.fleetType, qtrProjected: item.qtrProjected, equipmentName: item.equipmentName, equipmentDescription: item.equipmentDescription, make: item.make, model: item.model, pricingModel: item.pricingModel, reservePrice: item.reservePrice, listingPrice: item.listingPrice, mediaLink: item.mediaLink })) : dealEquipmentList.map((item) => ({ _id: item._id, fleetType: item.fleetType, qtrProjected: item.qtrProjected, equipmentName: item.equipmentName, equipmentDescription: item.equipmentDescription, make: item.make, model: item.model, pricingModel: item.pricingModel, reservePrice: item.reservePrice, listingPrice: item.listingPrice, mediaLink: item.mediaLink }));
            // let editPart = userList.filter((un) => { return values.dealParticipants.includes(un.id) }).map((item) => ({ startDate: new Date().toISOString(), endDate: new Date(new Date().setDate(new Date().getDate() + 10)).toISOString(), participant: item.id }));
            // let buyerData = [values.buyer];
            // console.log("buyerData", buyerData);
            let dealClosingSale = userList.filter((user) => { return values.buyer.includes(user.id) }).map((item) => ({ buyer: item.id, buyerPrice: 215000, seller: values.seller, acceptedPrice: 225000, quantity: 1, totalPrice: 225000, agreementSignedDate: new Date().toISOString(), closingSaleStatus: "Pending" }));
            // console.log("dealClosingSale", dealClosingSale);
            // console.log("arr", Arr);
            if (action === 'edit' && dealID) {
                payload.dealEquipment = Equipment;
                payload.dealInvites = invitedUsers;
                payload.dealParticipants = dealData.dealFilParticipants.filter((x) => !removedUsers.includes(x.participant._id));
                payload.UnreadMessages = dealData.UnreadMessages.filter((x) => !removedUsers.includes(x.userId));
                payload.dealDescription = dealDescription;
                payload.dealClosingSale = dealClosingSale;
                props.editDeal(dealID, payload);
            }
            else {
                payload.dealInvites = Arr;
                payload.dealParticipants = part;
                payload.creatorCompany = loggedInUserData.user.parentCompany;
                payload.creatorPeople = loggedInUserData.user._id;
                payload.dealEquipment = Equipment;
                payload.UnreadMessages = UnreadMessages;
                payload.dealDescription = dealDescription;
                payload.dealClosingSale = dealClosingSale;
                props.submitDealRegisterForm(payload, loggedInUserData)
            }
        }
    }

    const getSellerList = () => {
        return userList;
    };

    const getBuyerList = () => {
        return (userList || []).filter(user => user.id !== formState.values.seller);
    };

    const getDealInvitesList = () => {
        return (userList || [])?.filter(user => user?.id !== formState?.values?.seller && !formState?.values?.buyer?.includes(user.id));
    };


    return (
        <Card sx={{ maxWidth: '100%' }}>
            <Header>
                <HeaderText >
                    <h5>{action === 'edit' ? create_deal_labels.page_title_edit : create_deal_labels.page_title_create}</h5>
                </HeaderText>
            </Header>
            <CardContent>
                <form onSubmit={submitForm}>
                    <Grid container spacing={4}>
                        <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                                <FormControl className="w-100">
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        data-test-id="Create-Deal-DealName"
                                        id="dealName"
                                        name="dealName"
                                        placeholder={create_deal_labels.deal_name}
                                        value={formState.values.dealName}
                                        onChange={handleStartCaseChange}
                                        onBlur={handleBlur}
                                        error={hasError('dealName')}
                                        helperText={
                                            hasError('dealName') ? formState.errors.dealName[0] : null
                                        }
                                    />
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl className="w-100">
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        data-test-id="Create-Deal-Description"
                                        id="dealDescription"
                                        name="dealDescription"
                                        type="text"
                                        placeholder={create_deal_labels.deal_description}
                                        value={dealDescription}
                                        onChange={handleChangeDescription}
                                        onBlur={handleDescriptionBlur}
                                        error={hasError('dealDescription')}
                                        helperText={
                                            hasError('dealDescription') ? formState.errors.dealDescription[0] : null
                                        }
                                    />
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl variant="outlined" className='w-100'
                                    error={hasError('dealBrand')}
                                    helperText={
                                        hasError('dealBrand') ? formState.errors.dealBrand[0] : null
                                    }
                                >
                                    <InputLabel >{create_deal_labels.brand_name}</InputLabel>
                                    <Select
                                        label="Select Deal Brand Name*"
                                        data-test-id="Create-Deal-DealBrand"
                                        value={formState.values.dealBrand}
                                        name="dealBrand"
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'dealBrand',
                                            id: 'dealBrand',
                                        }}
                                        MenuProps={MenuProps}
                                        error={hasError('dealBrand')}
                                        helperText={
                                            hasError('dealBrand') ? formState.errors.dealBrand[0] : null
                                        }
                                    >
                                        <MenuItem value="0" disabled>{create_deal_labels.brand_name}</MenuItem>
                                        {(brandList || []).map((brand) => <MenuItem value={brand.id}>{brand.brandName}</MenuItem>)}
                                    </Select>
                                    {hasError('dealBrand') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('dealBrand')}>
                                            {formState.errors.dealBrand[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl variant="outlined" className='w-100'
                                    error={hasError('dealNetwork')}
                                    helperText={
                                        hasError('dealNetwork') ? formState.errors.dealNetwork[0] : null
                                    }
                                >
                                    <InputLabel >{create_deal_labels.network_name}</InputLabel>
                                    <Select
                                        label={create_deal_labels.network_name}
                                        data-test-id="Create-Deal-DealNetwork"
                                        value={formState.values.dealNetwork}
                                        name="dealNetwork"
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'dealNetwork',
                                            id: 'dealNetwork',
                                        }}
                                        MenuProps={MenuProps}
                                        error={hasError('dealNetwork')}
                                        helperText={
                                            hasError('dealNetwork') ? formState.errors.dealNetwork[0] : null
                                        }
                                    >
                                        <MenuItem value="0" disabled>{create_deal_labels.network_name}</MenuItem>
                                        {(networkList || []).map((network) => <MenuItem value={network.id}>{network.networkName}</MenuItem>)}
                                    </Select>
                                    {hasError('dealNetwork') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('dealNetwork')}>
                                            {formState.errors.dealNetwork[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl variant="outlined" className='w-100'
                                    error={hasError('industry')}
                                    helperText={
                                        hasError('industry') ? formState.errors.industry[0] : null
                                    }
                                >
                                    <InputLabel id="create-Deal-country-select-outlined-label">{create_deal_labels.industry_type}</InputLabel>
                                    <Select
                                        error={hasError('industry')}
                                        helperText={
                                            hasError('industry') ? formState.errors.industry[0] : null
                                        }
                                        label={create_deal_labels.industry_type}
                                        data-test-id="Create-Equipment-industry"
                                        value={formState.values.industry}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'industry',
                                            id: 'industry',
                                        }}
                                    >
                                        <MenuItem value="0" disabled>{create_deal_labels.industry_type}</MenuItem>
                                        {(industryList || []).map((industry) => <MenuItem value={industry}>{industry}</MenuItem>)}
                                    </Select>
                                    {hasError('industry') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('industry')}>
                                            {formState.errors.industry[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl className="w-100"
                                    error={hasError('channelName')}
                                    helperText={
                                        hasError('channelName') ? formState.errors.channelName[0] : null
                                    }
                                >
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        data-test-id="Create-Deal-channelName"
                                        id="channelName"
                                        name="channelName"
                                        placeholder={create_deal_labels.message_channel_name}
                                        label={create_deal_labels.message_channel_name}
                                        value={formState.values.channelName}
                                        onChange={handleStartCaseChange}
                                        onBlur={handleBlur}
                                        error={hasError('channelName')}
                                        helperText={
                                            hasError('channelName') ? formState.errors.channelName[0] : null
                                        }
                                    />
                                </FormControl>
                            </FormControlWrapper>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <FormControlWrapper hidden={action !== 'edit'}>
                                <FormControl
                                    sx={{ width: 300 }}
                                    variant="outlined"
                                    error={hasError('dealParticipants')}
                                >
                                    {/* InputLabel to float above the Select when clicked */}
                                    <InputLabel id="demo-multiple-chip-label">Select Deal Participants</InputLabel>

                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        data-test-id="Create-Deal-DealParticipants"
                                        id="demo-multiple-chip"
                                        multiple
                                        disabled={action === 'edit'}
                                        value={formState.values.dealParticipants}
                                        onChange={handleDealInvitesChange}
                                        name="dealParticipants"
                                        input={<OutlinedInput label="Select Deal Participants" id="select-multiple-chip" />}
                                        MenuProps={MenuProps}
                                    >
                                        {(userList || []).map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name} - ({user.companyName})
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {hasError('dealParticipants') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('dealParticipants')}>
                                            {formState.errors.dealParticipants[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl className='w-100'
                                    error={hasError('channelType')}
                                    helperText={
                                        hasError('channelType') ? formState.errors.channelType[0] : null
                                    }
                                >
                                    <InputLabel id="create-Deal-Channel-select-outlined-label">{create_deal_labels.message_channel_type}</InputLabel>
                                    <Select
                                        labelId="create-company-city-select-outlined-label"
                                        id="create-company-city-select-outlined"
                                        label={create_deal_labels.message_channel_type}
                                        value={formState.values.channelType}
                                        data-test-id="Create-Deal-channelType"
                                        onChange={handleChange}
                                        variant='outlined'
                                        inputProps={{
                                            name: 'channelType',
                                            id: 'channelType',
                                        }}
                                        error={hasError('channelType')}
                                    >
                                        <MenuItem value="0" disabled>{create_deal_labels.select_channel_type}</MenuItem>
                                        {[{ name: 'App' }, { name: 'SMS' }, { name: 'WhatsApp' }, { name: 'Email' }, { name: 'Voice' }].map((channelType) => <MenuItem key={channelType.name} value={channelType.name}>{channelType.name}</MenuItem>)}
                                    </Select>
                                    {hasError('channelType') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('channelType')}>
                                            {formState.errors.channelType[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl variant="outlined" className='w-100'
                                    error={hasError('seller')}
                                    helperText={
                                        hasError('seller') ? formState.errors.seller[0] : null
                                    }
                                >
                                    <InputLabel >{create_deal_labels.seller_name}</InputLabel>
                                    <Select
                                        label={create_deal_labels.seller_name}
                                        data-test-id="Create-Deal-seller"
                                        value={formState.values.seller}
                                        name="seller"
                                        placeholder={create_deal_labels.seller_name}
                                        onChange={handleChange}
                                        inputProps={{
                                            name: 'seller',
                                            id: 'seller',
                                        }}
                                    >
                                        <MenuItem value="0" disabled>{create_deal_labels.seller_name}</MenuItem>
                                        {(getSellerList() || []).map((user) => <MenuItem value={user.id}>{user.name}-({user.companyName})</MenuItem>)}
                                    </Select>
                                    {hasError('seller') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('seller')}>
                                            {formState.errors.seller[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            {/* <FormControlWrapper>
                                                            <FormControl className='w-100'
                                                                error={hasError('buyer')}
                                                                helperText={
                                                                    hasError('buyer') ? formState.errors.buyer[0] : null
                                                                }
                                                            >
                                                                <InputLabel >Select Buyer Name*</InputLabel>
                                                                <Select
                                                                    error={hasError('buyer')}
                                                                    helperText={
                                                                        hasError('buyer') ? formState.errors.buyer[0] : null
                                                                    }
                                                                    label="Select Buyer Name*"
                                                                    data-test-id="Create-Deal-buyer"
                                                                    value={formState.values.buyer}
                                                                    name="buyer"
                                                                    onChange={handleChange}
                                                                    variant="outlined"
                                                                    inputProps={{
                                                                        name: 'buyer',
                                                                        id: 'buyer',
                                                                    }}
                                                                    MenuProps={MenuProps}
                                                                >
                                                                    <MenuItem value="0" disabled>Select Buyer Name</MenuItem>
                                                                    {getBuyerList().map((user) => <MenuItem value={user.id}>{user.name}-({user.companyName})</MenuItem>)}
                                                                </Select>
                                                                {hasError('buyer') && (
                                                                    <FormHelperText htmlFor="form-selector" error={hasError('buyer')}>
                                                                        {formState.errors.buyer[0]}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </FormControlWrapper> */}
                            <FormControlWrapper>
                                <FormControl
                                    variant="outlined"
                                    className="w-100"
                                    error={hasError('buyer')}
                                >
                                    {/* InputLabel to float above the Select when clicked */}
                                    <InputLabel id="demo-multiple-chip-label">{create_deal_labels.buyer_name}</InputLabel>

                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        data-test-id="Create-Deal-buyer"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={formState.values.buyer}
                                        onChange={handleDealInvitesChange}
                                        name="buyer"
                                        input={<OutlinedInput label={create_deal_labels.buyer_name} id="select-multiple-chip" />}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip
                                                        key={value}
                                                        label={(getBuyerList() || [])
                                                            .filter((un) => value.includes(un.id))
                                                            .map((i) => `${i.name}-(${i.companyName})`)}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MultiMenuProps}
                                    >
                                        {(getBuyerList() || []).map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name}-({user.companyName})
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {hasError('buyer') && (
                                        <FormHelperText error={hasError('buyer')}>
                                            {formState.errors.buyer[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                                <FormControl
                                    sx={{ width: 300 }}
                                    variant="outlined"
                                    error={hasError('dealInvites')}
                                >
                                    {/* InputLabel to float above the Select when clicked */}
                                    <InputLabel id="demo-multiple-chip-label">{create_deal_labels.deal_invitees}</InputLabel>

                                    <Select
                                        labelId="demo-multiple-chip-label"
                                        data-test-id="Create-Deal-DealInvites"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={formState.values.dealInvites}
                                        onChange={handleDealInvitesChange}
                                        name="dealInvites"
                                        input={<OutlinedInput label={create_deal_labels.deal_invitees} id="select-multiple-chip" />}
                                        renderValue={(selected) => {
                                            const validSelected = selected.filter(value =>
                                                (getDealInvitesList() || []).some(user => user.id === value)
                                            );
                                            return (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {validSelected.map((value) => {
                                                        const user = (getDealInvitesList() || []).find(user => user.id === value);
                                                        return <Chip key={value} label={`${user.name} - (${user.companyName})`} />;
                                                    })}
                                                </Box>
                                            );
                                        }}
                                        MenuProps={MultiMenuProps}
                                    >
                                        {(getDealInvitesList() || []).map((user) => (
                                            <MenuItem key={user.id} value={user.id}>
                                                {user.name} - ({user.companyName})
                                            </MenuItem>
                                        ))}
                                    </Select>

                                    {hasError('dealInvites') && (
                                        <FormHelperText htmlFor="form-selector" error={hasError('dealInvites')}>
                                            {formState.errors.dealInvites[0]}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlWrapper>
                            {
                                location.state ? null
                                    : <FormControlWrapper>
                                        <FormControl sx={{ mt: 4 }}>
                                            <AlertDialogSlide />
                                        </FormControl>
                                    </FormControlWrapper>
                            }
                        </Grid >
                    </Grid >
                    <Grid container spacing={4}>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}>
                            <FormButtonWrapper>
                                {/* <Link to="/dealpage" className='linkStyle'> */}
                                <Button
                                    type="cancel"
                                    data-test-id="Create-Deal-Cancel"
                                    variant="contained"
                                    size="large"
                                    onClick={handleCancel}
                                    sx={{ backgroundColor: "#f50057" }}
                                >
                                    {create_deal_labels.cancel}
                                </Button>
                                {/* </Link> */}
                            </FormButtonWrapper>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start'
                            }}
                        >
                            <FormButtonWrapper>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-test-id="Create-Deal-Submit"
                                    size="large"
                                    className="Buttons"
                                >
                                    {action === 'edit' ? create_deal_labels.update_deal : create_deal_labels.create_deal}
                                </Button>
                            </FormButtonWrapper>
                        </Grid>
                    </Grid>
                </form >
            </CardContent >
        </Card >
    );
};

const mapStateToProps = state => ({
    restClient: state.restClient,
    dealData: {
        dealID: state.deal.dealData.dealID,
        dealName: state.deal.dealData.dealName,
        dealDescription: state.deal.dealData.dealDescription,
        dealBrand: state.deal.dealData.dealBrand,
        dealNetwork: state.deal.dealData.dealNetwork,
        dealEquipment: (state.deal.dealData.dealEquipment || []),
        industry: state.deal.dealData.industry,
        dealStartDate: state.deal.dealData.dealStartDate,
        dealEndDate: state.deal.dealData.dealEndDate,
        creatorCompany: state.deal.dealData.creatorCompany,
        creatorPeople: state.deal.dealData.creatorPeople,
        dealInvites: (state.deal.dealData.dealInvites || []).filter((un) => un.invitedUser).map((item) => item.invitedUser._id),
        dealParticipants: (state.deal.dealData.dealParticipants || []).filter((un) => un.participant).map((item) => item.participant._id),
        channelName: state.deal.dealData.channelName,
        channelType: state.deal.dealData.channelType,
        buyer: (state.deal.dealData.dealClosingSale || []).filter((item) => item.buyer).map((un) => un.buyer),
        buyerPrice: state.deal.dealData.buyerPrice,
        seller: state.deal.dealData.seller,
        acceptedPrice: state.deal.dealData.acceptedPrice,
        ratingValue: state.deal.dealData.ratingValue,
        worstRating: state.deal.dealData.worstRating,
        bestRating: state.deal.dealData.bestRating,
        lastUpdated: state.deal.dealData.lastUpdated,
        checked: state.deal.dealData.checked,
        UnreadMessages: state.deal.dealData.UnreadMessages,
        dealFilInvites: state.deal.dealData.dealInvites,
        dealFilParticipants: state.deal.dealData.dealParticipants,
        // dealClosingSale: (state.deal.dealData.dealClosingSale || []).filter((item) => item.buyer).map((un) => un.id)
    },
    networkList: state?.network?.networkList?.networkList,
    equipmentList: state?.equipment?.equipmentList?.equipmentList,
    userList: state.user && state.user.userList.userList,
    loggedInUserData: state.user && state.user.loggedInUserData,
    brandList: state?.brand?.brandList?.brandList,
    userData: state.user.userData,
    locale: state.locale
})

export default connect(mapStateToProps, { submitDealRegisterForm, getDealById, editDeal, getNetworkList, getEquipmentList, getUserList, setLoggedInUserData, getBrandList })(DealRegisterComponent);