import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitBrandRegisterForm,
  getBrandList,
  removeBrand,
} from "../../../reducers/BrandReducer";
import { getUploadPayload } from "./utils";
import { getCompanyList } from "../../../reducers/CompanyReducer";
import { getNetworkList } from "../../../reducers/NetworkReducer";
import { getUserList } from "../../../reducers/UserReducer";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridPagination
} from "@mui/x-data-grid";
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  Tooltip,
  Button
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchBar from '../../../utils/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';
import { uploadImage } from "../../DealChat/blobStorage";
import useMediaQuery from '@mui/material/useMediaQuery';


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  header: {
    maxWidth: "700px",
    marginLeft: "-5rem"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-31px"
  },
  cardStyle: {
    height: "480px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem",
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      height: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
  },
  textInput: {
    paddingLeft: "10px"
  }
});

function getBlobUrls(uploadResults) {
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) {
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}

const BrandList = (props) => {
  const {
    brandList = [],
    restClient: { rest },
    getBrandList,
    companyList = [],
    networkList = [],
    userList = [],
    getCompanyList,
    getNetworkList,
    getUserList,
    totalCount
  } = props;
  const history = useHistory();
  const classes = useStyles();
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });
  const [rowCountState, setRowCountState] = React.useState(totalCount);
  const [search, setSearch] = React.useState('');
  const [file, setFile] = useState();
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [filteredBrandList, setFilteredBrandList] = useState([]);
  const [files, setFiles] = useState([]);

  // useEffect(() => {
  //   // Set filteredUserList to userList initially
  //   if(brandList.length > 0){
  //     setFilteredBrandList(brandList);
  //   }
  // }, [brandList]);

  useEffect(() => {
    // Filter user list when search state changes
    if (brandList?.length > 0) {
      const filteredList = brandList.filter(user =>
        (user.brandName && user.brandName.toLowerCase().includes(search.toLowerCase())) ||
        (user.brandPeople && user.brandPeople.toLowerCase().includes(search.toLowerCase())) ||
        (user.brandNetwork && user.brandNetwork.toLowerCase().includes(search.toLowerCase())) ||
        (user.brandCompany && user.brandCompany[0].toLowerCase().includes(search.toLowerCase())) ||
        (user.brandDescription && user.brandDescription.toLowerCase().includes(search.toLowerCase())) ||
        (user.brandURL && user.brandURL.toLowerCase().includes(search.toLowerCase()))
      );

      setFilteredBrandList(filteredList);
      if (filteredList.length > 0) {
        setRowCountState(filteredList?.length)
      }
    }
  }, [search, brandList]);


  useEffect(() => {
    if (rest) {
      const fetchData = async () => {
        if (pageState) {
          await getBrandList(pageState);
        }
        getCompanyList();
        getNetworkList();
        getUserList();
      };
      fetchData();
    }
  }, [rest, getBrandList, getCompanyList, getNetworkList, getUserList, pageState]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  function handleChange(event) {
    setFile(event.target.files[0])
    setDisabled(false);
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }

  const handleBrandEdit = (event, brand) => {
    history.push(`/brand/create?action=edit&id=${brand.id}`);
  };
  const handleBrandView = (event, brand) => {
    history.push(`/view/brand?action=ViewColumn&id=${brand.id}`);
  };
  const handleBrandDelete = (event, brand) => {
    props.removeBrand(brand.id, brandList, totalCount);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const columns = [
    {
      field: "brandName",
      headerName: "Brand",
      width: 170,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
                fontWeight: "bold",
              },
            }}
            onClick={(event) => {
              handleBrandView(event, params.row);
            }}
          >
            {params.row.brandName}
          </Box>
        );
      },
    },
    {
      field: "brandPeople",
      headerName: "Brand People",
      type: "string",
      width: 180,
    },
    {
      field: "brandNetwork",
      headerName: "Brand Network",
      type: "string",
      width: 170,
    },
    {
      field: "brandCompany",
      headerName: "Brand Company",
      width: 200,
    },
    {
      field: "brandDescription",
      headerName: "Brand Description",
      width: 200,
    },
    {
      field: "brandURL",
      headerName: "Brand URL",
      width: 170,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Brand">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleBrandView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Brand">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleBrandEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Brand">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " + params.row.brandName + " ?"
                  ) && handleBrandDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const ImportExport = (event) => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase()) && companyList.length > 0 && networkList.length > 0 && userList.length > 0) {
      const companies = companyList
        .filter((un) => un.companyName)
        .map((item) => ({
          id: item.id,
          company: item.companyName,
          branchName: item.branch[0]?.branchName,
          branchID: item.branch[0]?.branchID,
        }));
      const users = userList
        .filter((un) => un.name)
        .map((item) => ({
          id: item.id,
          name: item.name,
          roleSystem: item.roleSystem,
        }));
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = async (e) => {
            const payload = getUploadPayload(
              reader.result,
              companies,
              networkList,
              users
            );
            if (payload?.length > 0) {
              let blobPath = [];
              if (files.length > 0) {
                blobPath = files.map((file) => file.name);
                await uploadMultipleFiles("fileupload", "brand", files);
                setFiles([]);
              }
              props.submitBrandRegisterForm(payload, "upload");
            } else {
              return 0;
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref.current.form.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              BRANDS
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New Brand">
                    <Link to="/brand/create">
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  // const onFilterChange = (event) => {
  //   // Here you save the data you need from the filter model and perform server-side search/filtering
  //   // Once the server-side search is completed, you can update the searchInput state with the new value

  //   // Example: Simulating server-side search delay with setTimeout
  //   setSearch(event.target.value);
  //   // if (search.length > 4 || search.trim() == '') {
  //   if (event?.target?.value?.length >= 3)
  //     setTimeout(() => {
  //       setPageState((old) => ({ ...old, searchData: event.target.value }));
  //     }, 2000);
  //   if (event?.target?.value?.length < 1)
  //     setPageState((old) => ({ ...old, searchData: '' }));
  //   // }
  // };

  const onFilterChange = (event) => {
    setSearch(event.target.value);

  };

  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  return (
    <Card className={classes.cardStyle}>
      <Grid container xs={12} my={2}>
        <Grid item xs={12} lg={8}>
          <form>
            <h3>
              Upload Brand Excel File (.xlsx) :
              <input type="file" onChange={handleChange} ref={ref} className={classes.textInput} />
              <Button component="label" variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={ImportExport} disabled={disabled}>
                Upload file
              </Button>
            </h3>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} sx={isSmallScreen ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        } : {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}>
          <SearchBar
            searchText={search}
            filterChange={onFilterChange}
            onClickFunc={handleClearText}
          />
        </Grid>
      </Grid>
      <ThemeProvider theme={theme}>
        <DataGrid
          rowCount={filteredBrandList.length >= 0 ? filteredBrandList.length : rowCountState}
          paginationMode="server"
          rowsPerPageOptions={[100]}
          pagination
          pageSize={pageState.pageSize}
          onPageChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage + 1 }));
          }}
          rows={filteredBrandList || []}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          sortingMode="server"
          onSortModelChange={(col) => {
            if (col.length > 0) {
              if (col[0].sort === "asc") {
                setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
              } else {
                setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
              }
            } else {
              setPageState((old) => ({ ...old, sort: null }));
            }
          }}
          sx={{
            "& .css-ptiqhd-MuiSvgIcon-root": {
              color: "#ee9907",
            },
            // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            //   display: 'none'
            // }
          }}
          // density='standard'
          // components={{ Toolbar: DataGridTitle }}
          components={{
            Toolbar: DataGridTitle,
            Pagination: GridPagination,
          }}
        />
      </ThemeProvider>
    </Card >
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state.company.companyList.companyList,
  networkList: state.network && state.network?.networkList?.networkList,
  brandList: state.brand && state.brand.brandList.brandList,
  userList: state.user.userList.userList,
  totalCount: state.brand && state.brand.brandList.total,
  limit: state.brand && state.brand.brandList.limit,
});
export default connect(mapStateToProps, {
  submitBrandRegisterForm,
  getBrandList,
  removeBrand,
  getCompanyList,
  getNetworkList,
  getUserList,
})(BrandList);
