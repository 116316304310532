import React, { useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { submitPaymentForm } from '../../reducers/TransactionReducer';
import { editDeal } from "../../reducers/DealReducer";
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    FormControl,
    TextField
} from '@mui/material';
import {
    AppContent,
    AppContentInner,
    AppWrapper,
    AppMain,
    HeaderText,
} from '../../styles/appStyles';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../styles/formStyles';
import { setLoggedInUserData } from "../../reducers/UserReducer";
import { submitPaymentMessage } from "../../reducers/DealCommunicationReducer";
// import html2canvas from 'html2canvas';
// import jsPDF from 'jspdf';
// import accept from "../../assets/images/image.png"

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '400px',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    numberField: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0

        },
    },
    buttontoggle: {
        paddingLeft: "20px"
    }
}));

const initialFormValues = {
    accountNumber: '',
    routingNumber: '',
    accountHolder: '',
    emailAddress: '',
    isACHPayment: true,
    amount: ''
};

const schema = {
    accountNumber: {
        presence: { allowEmpty: false, message: 'Account number is required' }
    },
    routingNumber: {
        presence: { allowEmpty: false, message: 'Routing number is required' }
    },
    accountHolder: {
        presence: { allowEmpty: false, message: 'Account holder Name is required' }
    },
    emailAddress: {
        presence: { allowEmpty: false, message: 'Email Address is required' }
    },
    amount: {
        presence: { allowEmpty: false, message: 'Amount is required' }
    }
};

const Payment = (props) => {
    const { dealData, loggedInUserData } = props;
    const classes = useStyles();
    // const transactionId = "6231b09f5292800b4074918e";

    const dealId = props.dealData.dealID;
    // const dealID = props.dealData.dealID;
    //   console.log("dealSta", dealId);
    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });

    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

    const handleChange = (event) => {
        // event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);


    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }

        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));

        return isErrorExist;
    }
    // const generatePDF = (values, accept) => {
    //     const pdf = new jsPDF();
    //     pdf.addImage(accept, 'PNG', 0, 0);

    //     // Add text to the PDF
    //     const invoiceNumber = 'INV-001';
    //     const customerName = values.amount;
    //     const customerAddress = values.accountNumber;

    //     pdf.setFontSize(16);
    //     pdf.text(`Invoice Number: ${invoiceNumber}`, 10, 20);
    //     pdf.text(`Customer Name: ${customerName}`, 10, 30);
    //     pdf.text(`Customer Address: ${customerAddress}`, 10, 40);


    //     pdf.save('demo.pdf')
    // }
    const submitForm = event => {
        event.preventDefault();

        function updateUnreadMessages(masterObj, filterObj) {
            if (masterObj.userId === filterObj.participant._id) {
                masterObj.totalUnReadMessages += 1;
                masterObj.readMessage = false;
            }
        }
        // Iterate through masterData and filterData

        if (dealData?.UnreadMessages) {
            // Iterate through UnreadMessages and users
            dealData?.UnreadMessages.forEach((masterObj) => {
                props?.users.forEach((filterObj) => {
                    updateUnreadMessages(masterObj, filterObj);
                });
            });
        }

        if (!checkErrors()) {
            const { values } = formState;
            const transactioncommunication = {
                transactioncommunication: {
                    dealId: dealId,
                    transactionId: props?.transaction?.id,
                    message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} made payment of $${values.amount} successfully`,
                    transactionEquipment: dealData.dealEquipment,
                    from: loggedInUserData.user._id,
                    to: dealData.dealParticipants.map((p) => { return p.participant._id }),
                    notificationType: "Payment",
                    notes: "notes",
                    askPrice: "$435",
                    offerPrice: `$${values.amount}`,
                    priceUnit: "USD",
                    addedBy: loggedInUserData.user._id
                },
                dealname: dealData.dealName,
                dealparticipantemails: dealData.dealParticipants.map((p) => { return p.participant.email })
            }
            const dealdata = {
                dealClosingPayment: {
                    paymentMethod: "ACH",
                    paymentDate: new Date(),
                    paymentStatus: "Completed"
                },
                UnreadMessages: dealData.UnreadMessages
            }
            const transaction = values;
            const payload = { dealdata, transaction, dealId }
            // generatePDF(values, accept);
            props.submitPaymentForm(payload);
            props.submitPaymentMessage(transactioncommunication);
            props.setOpen(false);
            props.editDeal(dealId, {
                dealClosingPayment: {
                    paymentMethod: "ACH",
                    paymentDate: new Date(),
                    paymentStatus: "Completed"
                }
            });
            // html2canvas(values).then((canvas) => {
            //     const imgData = canvas.toDataURL(accept);
            //     const pdf = new jsPDF();
            //     pdf.addImage(imgData, 'PNG', 0, 0);

            //     // Add text to the PDF
            //     const invoiceNumber = 'INV-001';
            //     const customerName = `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName}`;
            //     const accountNumber = values.accountNumber;
            //     pdf.setFontSize(16);
            //     pdf.text(`Invoice Number: ${invoiceNumber}`, 10, 20);
            //     pdf.text(`Customer Name: ${customerName}`, 10, 30);
            //     pdf.text(`Customer Address: ${accountNumber}`, 10, 40);

            //     pdf.save('invoice.pdf');
            // });

        }
    }

    return (
        <AppContent>
            <AppContentInner>
                <AppWrapper>
                    <AppMain>
                        <Container maxWidth="lg">
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={12}>
                                    <Card sx={{ border: "1px solid #ccc" }}>
                                        <HeaderText >
                                            <h5>Payment</h5>
                                        </HeaderText>
                                        <CardContent >
                                            <form onSubmit={submitForm}>
                                                <Grid container spacing={4}>
                                                    <Grid item lg={12} xs={12}>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('accountNumber')}
                                                                    helperText={
                                                                        hasError('accountNumber') ? formState.errors.accountNumber[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    className={classes.numberField}
                                                                    label="Account Number"
                                                                    fullWidth
                                                                    data-test-id="Payment-AccountNumber"
                                                                    placeholder="Account Number"
                                                                    type="number"
                                                                    name="accountNumber"
                                                                    value={formState.values.accountNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('routingNumber')}
                                                                    helperText={
                                                                        hasError('routingNumber') ? formState.errors.routingNumber[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    className={classes.numberField}
                                                                    label="Routing Number"
                                                                    fullWidth
                                                                    data-test-id="Payment-RoutingNumber"
                                                                    placeholder="Routing Number"
                                                                    type="number"
                                                                    name="routingNumber"
                                                                    value={formState.values.routingNumber}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('accountHolder')}
                                                                    helperText={
                                                                        hasError('accountHolder') ? formState.errors.accountHolder[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Account Holder"
                                                                    fullWidth
                                                                    data-test-id="Payment-AccountHolder"
                                                                    placeholder="Account Holder"
                                                                    type="text"
                                                                    name="accountHolder"
                                                                    value={formState.values.accountHolder}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('amount')}
                                                                    helperText={
                                                                        hasError('amount') ? formState.errors.amount[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    className={classes.numberField}
                                                                    label="Amount"
                                                                    fullWidth
                                                                    data-test-id="Payment-amount"
                                                                    placeholder="Amount"
                                                                    type="text"
                                                                    name="amount"
                                                                    value={formState.values.amount}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('emailAddress')}
                                                                    helperText={
                                                                        hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Email Address"
                                                                    fullWidth
                                                                    data-test-id="Payment-EmailAddress"
                                                                    placeholder="Email Address"
                                                                    type="email"
                                                                    name="emailAddress"
                                                                    value={formState.values.emailAddress}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent="center" spacing={5}>
                                                                <Grid item>
                                                                    <FormButtonWrapper className="align-box-row justify-box-row">
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                            variant="contained"
                                                                            data-test-id="Payment-Submit"
                                                                            size="large"
                                                                            className="Buttons">
                                                                            Submit
                                                                        </Button>
                                                                    </FormButtonWrapper>
                                                                </Grid>
                                                                {/* <Grid item>
                                                                    <FormButtonWrapper className="align-box-row justify-box-row">
                                                                        <Link to={'/deal/grid?dealId=' + dealId} className='linkStyle'>
                                                                            <Button
                                                                                type="submit"
                                                                                color="secondary"
                                                                                data-test-id="Payment-Cancel"
                                                                                variant="contained"
                                                                                size="large"
                                                                                className="my-2">
                                                                                cancel
                                                                            </Button>
                                                                        </Link>
                                                                    </FormButtonWrapper>
                                                                </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </AppMain>
                </AppWrapper>
            </AppContentInner>
        </AppContent>
    );
}
const mapStateToProps = state => ({
    restClient: state.restClient,
    dealData: state.deal && state.deal.dealData,
    loggedInUserData: state.user && state.user.loggedInUserData,
});

export default connect(mapStateToProps, { submitPaymentForm, submitPaymentMessage, setLoggedInUserData, editDeal })(Payment);
