import styled from 'styled-components';

export const FormControlWrapper = styled.div`
    margin-bottom: 1rem;
    // margin-left: 5rem;
    & .MuiFormControl-root {
        width: 100%;
    }
    & .form-label {
        color: #000;
        margin-bottom: 10px;
        margin-left: 5px;
        font-weight: 300;
    }
    .user{
        margin:-14px 13px -18px 81px;
        padding-left: 120px;
    }
    & .strong{
        margin-left:100px;
    }
    .equipment{
        margin:-14px 13px -18px 81px;
        padding-left: 120px;
    }
    .deal{
        margin:-14px 12px -18px 129px;
        padding-left: 120px;
    }
    .company{
        margin:-14px 13px -18px 81px;
        padding-left: 120px;
    }
    .MarginBottom{
        margin:-14px 12px 18px 129px;
        padding-left: 120px;
    }
    & .add-Equipment{
        margin: 14px 0 0 0;
    }
`;

export const FormButtonWrapper = styled.div`
    .linkStyle{
        text-decoration: none;
    };
    .Buttons{
        background-color: rgb(62, 82, 114)
    };
    &:hover .Buttons{
        background-color: #fbbc05;
        color: black;
    }
`;
export const Alignment = styled.div`
    margin-bottom: 0rem;
    margin-left: 15rem;
    & .MuiFormControl-root {
        width: 100%;
    }        
`;
export const Wrapper = styled.div`

    display: 'flex',
    flex-direction: row;
     
   
`;

