import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  FormControl,
  Link as MaterialLink,
  TextField,
  FormLabel,
  Typography,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import OtpInput from "react-otp-input";
import { submitLoginForm, setAskLoginOtp } from "../../reducers/UserReducer";
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from "../../styles/appStyles";
import { FormControlWrapper, FormButtonWrapper } from "../../styles/formStyles";
import getTranslations from "../../utils/getTranslations";

const LoginComponent = (props) => {
  const { setAskLoginOtp, locale } = props;
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [otp, setOTP] = React.useState("");
  // Show Passwords ----
  const [checked, setChecked] = React.useState(false);
  const [type, setType] = React.useState("password");

  const handleUserNameChange = (event) => {
    setUserName(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleOTPChange = (value) => {
    setOTP(value);
  };
  useEffect(() => {
    setAskLoginOtp(false);
  }, [setAskLoginOtp]);
  const submitForm = (event, resend) => {
    event.preventDefault();
    props.submitLoginForm(userName, password, otp, resend);
  };

  //   ------  SHOW PASSWORD
  const showPassword = (event) => {
    //console.log(event.target);
    setChecked(!checked);
    if (event.target.checked) {
      setType("text");
    } else {
      setType("password");
    }
  };

  const translationSet = getTranslations(locale);
  const login_labels = translationSet.login_page;

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5', // Optional, adds a background color to the page
      }}
    >
      <Card sx={{ maxWidth: 600, width: '100%', padding: 2 }}>
        <HeaderText>
          <h5 style={{ margin: 'auto' }}>{login_labels.welcome}</h5>
        </HeaderText>
        <CardContent>
          <form onSubmit={submitForm}>
            {!props.askOTP && (
              <>
                <FormControlWrapper>
                  <FormControl className="w-100">
                    <FormLabel className="form-label" required>
                      {login_labels.email_address}
                    </FormLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="email"
                      name="email"
                      placeholder={login_labels.email_placeholder}
                      value={userName}
                      onChange={handleUserNameChange}
                    />
                  </FormControl>
                </FormControlWrapper>
                <FormControlWrapper>
                  <FormControl>
                    <FormLabel className="form-label" required>
                      {login_labels.password}
                    </FormLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="password"
                      name="password"
                      placeholder={login_labels.password_placeholder}
                      type={type}
                      value={password}
                      onChange={handlePasswordChange}
                    />
                  </FormControl>
                </FormControlWrapper>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={checked} onClick={showPassword} />
                    }
                    label={login_labels.show_password}
                  />
                </FormGroup>
                <FormButtonWrapper style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button color="primary" variant="contained" type="submit">
                    {login_labels.login}
                  </Button>
                </FormButtonWrapper>
                <div style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                  <Typography align="center">
                    <Link
                      to="/forgetpassword"
                      style={{ color: '#FFBF00', textDecoration: 'none' }}
                    >
                      {login_labels.forgot_password}
                    </Link>
                  </Typography>
                </div>
                <div style={{ marginTop: '0.5rem' }}>
                  <Typography align="center">
                    <Link
                      to="/register"
                      style={{ color: '#FFBF00', textDecoration: 'none' }}
                    >
                      {login_labels.sign_up}
                    </Link>
                  </Typography>
                </div>
              </>
            )}
            {props.askOTP && (
              <>
                <p>
                  For security purposes, a Security code has been sent to your email
                  address. Please input the code below. If you do not receive your
                  code in the next 15 minutes, check your spam folder or you may
                  request a new code
                  <MaterialLink
                    component="button"
                    variant="body2"
                    onClick={(e) => submitForm(e, true)}
                  >
                    &nbsp; here
                  </MaterialLink>
                  .
                </p>
                <FormControlWrapper>
                  <FormControl className="w-100">
                    <OtpInput
                      value={otp}
                      onChange={(otp) => handleOTPChange(otp)}
                      numInputs={6}
                      isInputNum={true}
                      separator={<span>&nbsp;</span>}
                      data-test-id="Login-OTP"
                      inputStyle={{
                        width: '3rem',
                        height: '3rem',
                        margin: '0 1rem',
                        fontSize: '2rem',
                        borderRadius: 6,
                        border: '1px solid rgba(0,0,0,0.3)',
                      }}
                    />
                  </FormControl>
                </FormControlWrapper>
                <FormButtonWrapper className="text-center">
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    size="large"
                    data-test-id="Login-Submit"
                    className="my-2"
                  >
                    Complete Login
                  </Button>
                </FormButtonWrapper>
              </>
            )}
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  askOTP: state.user.askOTP,
  locale: state.locale
});
export default connect(mapStateToProps, { submitLoginForm, setAskLoginOtp })(
  LoginComponent
);
