import { BlobServiceClient } from '@azure/storage-blob';

const blobServiceClient = new BlobServiceClient(`${process.env.REACT_APP_BLOB_SAS_URL}`);

export async function uploadImage(containerName, subcontainer, file) {
    try {
        // Get a container client
        const containerClient = blobServiceClient.getContainerClient(containerName);

        const blobName = `${subcontainer}/${file.name}`;
        // Get a block blob client
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        // Upload data to blob storage
        await blockBlobClient.uploadData(file, {
            blockSize: 4 * 1024 * 1024, // 4MB block size
            concurrency: 20,
            onProgress: ev => console.log(ev)
        });
        const fileUrl = blockBlobClient.url;
        console.log(`Upload of file '${file.name}' completed`);
        return { fileUrl, fileName: file.name, etag: blockBlobClient.etag, lastModified: blockBlobClient.lastModified };
    } catch (error) {
        if (error.statusCode === 404) {
            return false; // Blob does not exist
        }
        throw error; // Some other error occurred
    }
}

export async function deleteImage(containerName, blobName) {
    try {
        // Get a container client
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Get a block blob client
        const blockBlobClient = containerClient.getBlockBlobClient(blobName);

        // Delete the blob
        await blockBlobClient.delete();
        console.log(`Deletion of blob '${blobName}' completed`);
    } catch (error) {
        if (error.statusCode === 404) {
            return false; // Blob does not exist
        }
        throw error; // Some other error occurred
    }
}

export async function doesBlobExist(containerName, blobName) {
    try {
        // Get a container client
        const containerClient = blobServiceClient.getContainerClient(containerName);

        // Get a blob client
        const blobClient = containerClient.getBlobClient(blobName);

        // Check if the blob exists by attempting to get its properties
        await blobClient.getProperties();
        return true; // Blob exists
    } catch (error) {
        if (error.statusCode === 404) {
            return false; // Blob does not exist
        }
        throw error; // Some other error occurred
    }
}
