import { setShowLoader } from './LoaderReducer';
import { enqueueSnackbar } from './NotifierReducer';
import { push } from 'connected-react-router';
import { editDeal, editDealWithMessage } from "./DealReducer";
import { getTransactionList } from "./TransactionReducer";
export const DEAL_SET_LIST = 'DEAL_SET_LIST';
export const DEAL_SET_MESSAGELIST = 'DEAL_SET_MESSAGELIST';
export const DEAL_SET_RECEIVCD_MESSAGELIST = 'DEAL_SET_RECEIVCD_MESSAGELIST';

export const getDealMessageList = (dealId, loggedInUserData) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(false));
            const client = getState().restClient.rest;
            const fetchedDealMessageList = await client.service('transactioncommunication').find(
                {
                    query: {
                        dealId
                    }
                }
            );
            let dealCommunicationList = [];
            if (fetchedDealMessageList.transactioncommunications && fetchedDealMessageList.transactioncommunications.data)
                dealCommunicationList = fetchedDealMessageList.transactioncommunications.data.map(item => {
                    const dealCommunication = {};
                    dealCommunication.id = item._id;
                    dealCommunication.dealId = item.dealId;
                    dealCommunication.transactionEquipment = item.transactionEquipment;
                    dealCommunication.transactionId = item?.transactionId ?? "";
                    dealCommunication.message = item.message;
                    dealCommunication.from = item.from;
                    dealCommunication.to = item.to;
                    dealCommunication.notificationType = item.notificationType;
                    dealCommunication.notes = item.notes;
                    dealCommunication.askPrice = item.askPrice;
                    dealCommunication.offerPrice = item.offerPrice;
                    dealCommunication.priceUnit = item.priceUnit;
                    dealCommunication.addedBy = item.addedBy;
                    dealCommunication.createdAt = new Date(item.createdAt).toLocaleString().split(', ').join(" ");
                    dealCommunication.updatedAt = item.updatedAt;
                    dealCommunication.blobPath = item.blobPath ?? [];
                    return dealCommunication;
                })
            dispatch({ type: DEAL_SET_MESSAGELIST, payload: dealCommunicationList });
            dispatch(setShowLoader(false));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch({ type: DEAL_SET_MESSAGELIST, payload: [] });
        }
    }
}

export const getDealMessageListById = (dealId, transactionId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const fetchedDealMessageList = await client.service('transactioncommunication').find({
                query: {
                    dealId: dealId,
                    transactionId: transactionId
                }
            });
            let dealCommunicationList = [];
            if (fetchedDealMessageList.transactioncommunications && fetchedDealMessageList.transactioncommunications.data)
                dealCommunicationList = fetchedDealMessageList.transactioncommunications.data.map(item => {
                    const dealCommunication = {};
                    dealCommunication.id = item._id;
                    dealCommunication.dealId = item.dealId;
                    dealCommunication.transactionEquipment = item.transactionEquipment;
                    dealCommunication.transactionId = item?.transactionId ?? "";
                    dealCommunication.message = item.message;
                    dealCommunication.from = item.from;
                    dealCommunication.to = item.to;
                    dealCommunication.notificationType = item.notificationType;
                    dealCommunication.notes = item.notes;
                    dealCommunication.askPrice = item.askPrice;
                    dealCommunication.offerPrice = item.offerPrice;
                    dealCommunication.priceUnit = item.priceUnit;
                    dealCommunication.addedBy = item.addedBy;
                    dealCommunication.createdAt = new Date(item.createdAt).toLocaleString().split(', ').join(" ");
                    dealCommunication.updatedAt = item.updatedAt;
                    dealCommunication.blobPath = item.blobPath ?? [];
                    return dealCommunication;
                })
            dispatch({ type: DEAL_SET_MESSAGELIST, payload: dealCommunicationList })
            dispatch(setShowLoader(false));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitGroupChatDealMessage = (dealID = "", dealData = {}, payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": payload
            });
            dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealMessageList });
            dispatch(enqueueSnackbar({
                message: `Deal message Created successfully.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Deal Message Creation failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitDealMessage = (dealID = "", dealData = {}, payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(false));
            const client = getState().restClient.rest;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": payload
            });
            if (dealMessageList.transactioncommunication.notificationType !== 'General Message') {
                dispatch(editDeal(dealID, dealData, true));
            } else if (dealMessageList.transactioncommunication.notificationType == 'General Message') {
                dispatch(editDeal(dealID, dealData, true, true));
            }
            const dealCommunicationList = {};
            dealCommunicationList.id = dealMessageList.transactioncommunication._id;
            dealCommunicationList.dealId = dealMessageList.transactioncommunication.dealId;
            dealCommunicationList.transactionEquipment = dealMessageList.transactioncommunication.transactionEquipment;
            dealCommunicationList.transactionId = dealMessageList?.transactioncommunication?.transactionId ?? "";
            dealCommunicationList.message = dealMessageList.transactioncommunication.message;
            dealCommunicationList.from = dealMessageList.transactioncommunication.from;
            dealCommunicationList.to = dealMessageList.transactioncommunication.to;
            dealCommunicationList.notificationType = dealMessageList.transactioncommunication.notificationType;
            dealCommunicationList.notes = dealMessageList.transactioncommunication.notes;
            dealCommunicationList.askPrice = dealMessageList.transactioncommunication.askPrice;
            dealCommunicationList.offerPrice = dealMessageList.transactioncommunication.offerPrice;
            dealCommunicationList.priceUnit = dealMessageList.transactioncommunication.priceUnit;
            dealCommunicationList.addedBy = dealMessageList.transactioncommunication.addedBy;
            dealCommunicationList.createdAt = dealMessageList.transactioncommunication.createdAt;
            dealCommunicationList.updatedAt = dealMessageList.transactioncommunication.updatedAt;
            dealCommunicationList.blobPath = dealMessageList.transactioncommunication.blobPath ?? '';
            dispatch(setShowLoader(false));
            // dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealCommunicationList });
            if (dealMessageList.transactioncommunication.notificationType !== 'General Message') {
                dispatch(enqueueSnackbar({
                    message: `Deal message Created successfully.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }
                    },
                }));
            }
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Deal Message Creation failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitDealMessageWithoutTransactionId = (dealID = "", transaction) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(false));
            const client = getState().restClient.rest;
            const transactionForIndividual = await client.service('transaction').create({
                "transaction": transaction
            });
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Transaction Created successfully.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
            return transactionForIndividual;
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Deal Message Creation failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
        dispatch(getTransactionList(dealID));
    }
}

export const submitAcceptMessage = (dealID = "", dealData = {}, payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": payload.transactioncommunication,
                dealname: payload.participantemail.dealname,
                dealparticipantemails: payload.participantemail.dealparticipantemails
            });

            dispatch(editDealWithMessage(dealID, dealData, true));

            const dealCommunicationList = {};
            dealCommunicationList.id = dealMessageList.transactioncommunication._id;
            dealCommunicationList.dealId = dealMessageList.transactioncommunication.dealId;
            dealCommunicationList.transactionEquipment = dealMessageList.transactioncommunication.transactionEquipment;
            dealCommunicationList.transactionId = dealMessageList.transactioncommunication.transactionId;
            dealCommunicationList.message = dealMessageList.transactioncommunication.message;
            dealCommunicationList.from = dealMessageList.transactioncommunication.from;
            dealCommunicationList.to = dealMessageList.transactioncommunication.to;
            dealCommunicationList.notificationType = dealMessageList.transactioncommunication.notificationType;
            dealCommunicationList.notes = dealMessageList.transactioncommunication.notes;
            dealCommunicationList.askPrice = dealMessageList.transactioncommunication.askPrice;
            dealCommunicationList.offerPrice = dealMessageList.transactioncommunication.offerPrice;
            dealCommunicationList.priceUnit = dealMessageList.transactioncommunication.priceUnit;
            dealCommunicationList.addedBy = dealMessageList.transactioncommunication.addedBy;
            dealCommunicationList.createdAt = dealMessageList.transactioncommunication.createdAt;
            dealCommunicationList.updatedAt = dealMessageList.transactioncommunication.updatedAt;
            dispatch(setShowLoader(false));
            // dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealCommunicationList });
            dispatch(enqueueSnackbar({
                message: `Offer Accepted successfully.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Offer Accepted failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitRejectMessage = (dealID = "", dealData = {}, payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": payload.transactioncommunication,
                dealname: payload.participantemail.dealname,
                dealparticipantemails: payload.participantemail.dealparticipantemails
            });
            dispatch(editDealWithMessage(dealID, dealData, true));
            const dealCommunicationList = {};
            dealCommunicationList.id = dealMessageList.transactioncommunication._id;
            dealCommunicationList.dealId = dealMessageList.transactioncommunication.dealId;
            dealCommunicationList.transactionEquipment = dealMessageList.transactioncommunication.transactionEquipment;
            dealCommunicationList.transactionId = dealMessageList.transactioncommunication.transactionId;
            dealCommunicationList.message = dealMessageList.transactioncommunication.message;
            dealCommunicationList.from = dealMessageList.transactioncommunication.from;
            dealCommunicationList.to = dealMessageList.transactioncommunication.to;
            dealCommunicationList.notificationType = dealMessageList.transactioncommunication.notificationType;
            dealCommunicationList.notes = dealMessageList.transactioncommunication.notes;
            dealCommunicationList.askPrice = dealMessageList.transactioncommunication.askPrice;
            dealCommunicationList.offerPrice = dealMessageList.transactioncommunication.offerPrice;
            dealCommunicationList.priceUnit = dealMessageList.transactioncommunication.priceUnit;
            dealCommunicationList.addedBy = dealMessageList.transactioncommunication.addedBy;
            dealCommunicationList.createdAt = dealMessageList.transactioncommunication.createdAt;
            dealCommunicationList.updatedAt = dealMessageList.transactioncommunication.updatedAt;
            dispatch(setShowLoader(false));
            // dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealCommunicationList });
            dispatch(enqueueSnackbar({
                message: `Offer Rejected successfully.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Offer Rejected failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitPaymentMessage = (payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": payload.transactioncommunication,
                dealname: payload.dealname,
                dealparticipantemails: payload.dealparticipantemails
            });
            const dealCommunicationList = {};
            dealCommunicationList.id = dealMessageList.transactioncommunication._id;
            dealCommunicationList.dealId = dealMessageList.transactioncommunication.dealId;
            dealCommunicationList.transactionEquipment = dealMessageList.transactioncommunication.transactionEquipment;
            dealCommunicationList.transactionId = dealMessageList.transactioncommunication.transactionId;
            dealCommunicationList.message = dealMessageList.transactioncommunication.message;
            dealCommunicationList.from = dealMessageList.transactioncommunication.from;
            dealCommunicationList.to = dealMessageList.transactioncommunication.to;
            dealCommunicationList.notificationType = dealMessageList.transactioncommunication.notificationType;
            dealCommunicationList.notes = dealMessageList.transactioncommunication.notes;
            dealCommunicationList.askPrice = dealMessageList.transactioncommunication.askPrice;
            dealCommunicationList.offerPrice = dealMessageList.transactioncommunication.offerPrice;
            dealCommunicationList.priceUnit = dealMessageList.transactioncommunication.priceUnit;
            dealCommunicationList.addedBy = dealMessageList.transactioncommunication.addedBy;
            dealCommunicationList.createdAt = dealMessageList.transactioncommunication.createdAt;
            dealCommunicationList.updatedAt = dealMessageList.transactioncommunication.updatedAt;
            dispatch(setShowLoader(false));
            // dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealCommunicationList });
            dispatch(enqueueSnackbar({
                message: `Payment Done successfully.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Payment failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitMakeOfferMessage = (payload = {}, message = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": payload.transactioncommunication,
                dealname: payload.dealname,
                dealparticipantemails: payload.dealparticipantemails
            });
            const dealCommunicationList = {};
            dealCommunicationList.id = dealMessageList.transactioncommunication._id;
            dealCommunicationList.dealId = dealMessageList.transactioncommunication.dealId;
            dealCommunicationList.transactionEquipment = dealMessageList.transactioncommunication.transactionEquipment;
            dealCommunicationList.transactionId = dealMessageList.transactioncommunication.transactionId;
            dealCommunicationList.message = dealMessageList.transactioncommunication.message;
            dealCommunicationList.from = dealMessageList.transactioncommunication.from;
            dealCommunicationList.to = dealMessageList.transactioncommunication.to;
            dealCommunicationList.notificationType = dealMessageList.transactioncommunication.notificationType;
            dealCommunicationList.notes = dealMessageList.transactioncommunication.notes;
            dealCommunicationList.askPrice = dealMessageList.transactioncommunication.askPrice;
            dealCommunicationList.offerPrice = dealMessageList.transactioncommunication.offerPrice;
            dealCommunicationList.priceUnit = dealMessageList.transactioncommunication.priceUnit;
            dealCommunicationList.addedBy = dealMessageList.transactioncommunication.addedBy;
            dealCommunicationList.createdAt = dealMessageList.transactioncommunication.createdAt;
            dealCommunicationList.updatedAt = dealMessageList.transactioncommunication.updatedAt;
            dispatch(setShowLoader(false));
            // dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealCommunicationList });

            if (message) {
                dispatch(enqueueSnackbar({
                    message: `Thanks for making the offer. Please wait for the response from the seller.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }
                    },
                }));
                dispatch(push('/dealpage'));
            }
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Message failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const submitMakeOfferMessageWithoutTransactionId = (dealID = "", dealData = {}, payload = {}, transactioncommunication, message = false) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const transaction = await client.service('transaction').create({
                "transaction": payload.transaction
            });
            transactioncommunication.transactioncommunication.transactionId = transaction?.transaction?._id;
            const dealMessageList = await client.service('transactioncommunication').create({
                "transactioncommunication": transactioncommunication.transactioncommunication,
                dealname: transactioncommunication.dealname,
                dealparticipantemails: transactioncommunication.dealparticipantemails
            });
            const dealCommunicationList = {};
            dealCommunicationList.id = dealMessageList.transactioncommunication._id;
            dealCommunicationList.dealId = dealMessageList.transactioncommunication.dealId;
            dealCommunicationList.transactionEquipment = dealMessageList.transactioncommunication.transactionEquipment;
            dealCommunicationList.transactionId = dealMessageList.transactioncommunication.transactionId;
            dealCommunicationList.message = dealMessageList.transactioncommunication.message;
            dealCommunicationList.from = dealMessageList.transactioncommunication.from;
            dealCommunicationList.to = dealMessageList.transactioncommunication.to;
            dealCommunicationList.notificationType = dealMessageList.transactioncommunication.notificationType;
            dealCommunicationList.notes = dealMessageList.transactioncommunication.notes;
            dealCommunicationList.askPrice = dealMessageList.transactioncommunication.askPrice;
            dealCommunicationList.offerPrice = dealMessageList.transactioncommunication.offerPrice;
            dealCommunicationList.priceUnit = dealMessageList.transactioncommunication.priceUnit;
            dealCommunicationList.addedBy = dealMessageList.transactioncommunication.addedBy;
            dealCommunicationList.createdAt = dealMessageList.transactioncommunication.createdAt;
            dealCommunicationList.updatedAt = dealMessageList.transactioncommunication.updatedAt;
            dispatch(editDealWithMessage(dealID, dealData, true));
            dispatch(setShowLoader(false));
            // dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: dealCommunicationList });
            if (message) {
                dispatch(enqueueSnackbar({
                    message: `Thanks for making the offer. Please wait for the response from the seller.`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                        anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                        }
                    },
                }));
            }
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Message failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const getDealMessageListByDealId = (dealId) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(false));
            const client = getState().restClient.rest;
            const getDealMessageList = await client.service('transactioncommunication').get(dealId, {})
            const fetchedDealMessageList = getDealMessageList.networks || {};
            let networkContactList = [];
            networkContactList.networkId = fetchedDealMessageList._id;
            if (fetchedDealMessageList && fetchedDealMessageList.networkContacts)
                networkContactList = fetchedDealMessageList.networkContacts.map(item => {
                    const networkContact = {};
                    networkContact.id = item._id;
                    networkContact.firstName = item.firstName;
                    networkContact.middleName = item.middleName;
                    networkContact.lastName = item.lastName;
                    networkContact.name = `${item.firstName} ${item.middleName} ${item.lastName}`;
                    networkContact.emailAddress = item.emailAddress;
                    networkContact.countryCode = item.countryCode;
                    networkContact.mobileNumber = item.mobileNumber;
                    networkContact.whatsAppNumber = item.whatsAppNumber;
                    networkContact.smsNumber = item.smsNumber;
                    return networkContact;
                })
            dispatch({ type: DEAL_SET_RECEIVCD_MESSAGELIST, payload: networkContactList })
            dispatch(setShowLoader(false));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const editTransactionBlobs = (transactionId, payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            await client.service('transactioncommunication').patch(transactionId, payload);
            dispatch(enqueueSnackbar({
                message: 'Transaction Communication information EDITED And Saved.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
            dispatch(getDealMessageList(transactionId));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Edit Transaction information failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export const DealMessageCommunication = (messageInfo = {}) => {

    let payload = '';
    let message = '';
    let errorMessage = '';
    let redirectLogin = false;
    if (messageInfo.messageType === 'email') {
        payload = messageInfo;
        message = 'Your email updated successfully';
        errorMessage = 'Verify email'
        redirectLogin = true;
    }
    else if (messageInfo.messageType === 'sms') {
        payload = messageInfo;
        message = 'Your sms updated successfully';
        errorMessage = 'Verify sms'
        redirectLogin = true;
    }
    else if (messageInfo.messageType === 'whatsapp') {
        payload = messageInfo;
        message = 'Your whatsapp updated successfully';
        errorMessage = 'Verify whatsapp'
        redirectLogin = true;
    }
    return async (dispatch, getState) => {
        try {
            const client = getState().restClient.rest;
            const dealMessage = await client.service('deal-message').create(payload);
            dispatch({ type: DEAL_SET_MESSAGELIST, payload: dealMessage })
            dispatch(enqueueSnackbar({
                message: `${message}.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
            if (redirectLogin) {
                dispatch(push('/deal/details?dealId=61666be60414460012fcd41f'));
            }
        } catch (error) {
            dispatch(enqueueSnackbar({
                message: `${errorMessage} Failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export default function reducer(
    state = {
        dealList: [],
        dealMessageList: [],
        dealCommunicationList: []
    },
    action
) {
    switch (action.type) {
        case DEAL_SET_LIST:
            return {
                ...state,
                dealList: action.payload
            };
        case DEAL_SET_MESSAGELIST:
            return {
                ...state,
                dealCommunicationList: action.payload
            };
        case DEAL_SET_RECEIVCD_MESSAGELIST:
            // Ensure action.payload is an object and convert it into an array
            // const updatedDealCommunication = Array.isArray(action.payload) ? action.payload : [];
            const dealCommunicationList = Array.isArray(state.dealCommunicationList) ? state.dealCommunicationList : [];
            return {
                ...state,
                dealCommunicationList: [...dealCommunicationList, action.payload]
            };
        default:
            break;
    }
    return state;
}