import React, { useEffect, useRef } from 'react';
import './footer.css'
import SelectLanguageComponent from '../SelectLanguage';

function Footer({ onHeightChange }) {
    const footerRef = useRef(null);

    useEffect(() => {
        // Calculate the height of the header and pass it to the parent component
        onHeightChange(footerRef.current.offsetHeight);
    }, []);
    return (
        <footer ref={footerRef} class="atg-footer" id="atg-footer">
            <div class="footer-wrapper">

            </div>
            <div class="footer-strip">
                <SelectLanguageComponent />
                <div class="copyright">
                    <p>Copyright 2024 by <a href="https://asktakego.com/" rel='noreferrer' target='_blank' class='atg-footer-link'>AskTakeGo.inc</a> All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}
export default Footer;
