
export const schema = {
    roleType: {
      presence: { allowEmpty: false, message: 'Role type is required' }
    },
    firstName: {
      presence: { allowEmpty: false, message: 'First name is required' }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'Last name is required' }
    },
    countryCode: {
      presence: { allowEmpty: false, message: 'Country code is required' }
    },
    mobileNumber: {
      presence: { allowEmpty: false, message: 'Mobile number is required' }
    },
    smsNumber: {
      presence: { allowEmpty: false, message: 'SMS number is required' }
    },
    email: {
      presence: { allowEmpty: false, message: 'Email is required' }
    },
    phone: {
      presence: { allowEmpty: false, message: 'Phone is required' },
      //IsValidPhoneNumber: {},
    },
    roleSystem: {
      presence: { allowEmpty: false, message: 'Role System is required' }
    },

  };

  export const getPayload = (values) => {
    const payload = {
      parentCompany: values.parentCompany,
      profilePath: values.profilePath,
      "atgRole": {
        roleType: values.roleType,
        dominantRole: values.dominantRole,
        traderSubType: values.traderSubType
      },
      "contact": {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        alias: values.alias,
        emailAddress: values.emailAddress,
        webURL: values.webURL,
        countryCode: values.countryCode,
        mobileNumber: values.mobileNumber,
        whatsAppNumber: values.whatsAppNumber,
        smsNumber: values.smsNumber
      },
      "serviceProvider": {
        serviceProviderRole: values.serviceProviderRole,
        forCompany: values.serviceProviderForCompany             // 60c70b1b471e684e48e67ead
      },
      "trustedAgent": {
        trustedAgentRole: values.trustedAgentRole,
        forCompany: values.trustedAgentForCompany             // 60c70b1b471e684e48e67ead
      },
      "rating": {
        author: values.author,
        bestRating: values.bestRating,             //10
        worstRating: values.worstRating,            //2
        ratingValue: values.ratingValue,            //10
        reviewAspect: values.reviewAspect,
        ratingComments: values.ratingComments
      },
      "assetState": {
        lastUpdated: values.lastUpdated,                  // 2021-06-14T08:51:06.157Z
        "ownershipTransition": {
          startDate: values.ownershipStartDate,                // 2021-06-16T08:51:06.157Z
          endDate: values.ownershipEndDate,                  // 2021-07-17T08:51:06.157Z
          ownershipStatus: values.ownershipStatus
        },
        "availabilityStateTransition": {
          startDate: values.availabilityStartDate,
          endDate: values.availabilityEndDate,
          availabilityStatus: values.availabilityStatus
        },
        "locationStateTransition": {
          startDate: values.locationStartDate,
          endDate: values.locationEndDate,
          locationLat: values.locationLatitude,
          locationLong: values.locationLongitude,
          locationStatus: values.locationStatus
        },
        "needStateTransition": {
          startDate: values.needStartDate,
          endDate: values.needEndDate,
          needStatus: values.needStatus
        },
        "usageStateTransition": {
          startDate: values.usageStartDate,
          endDate: values.usageEndDate,
          usageStatus: values.usageStatus
        }
      },
      "processState": {
        sequenceStart: values.sequenceStart,
        sequenceAvailable: values.sequenceAvailable,
        sequenceSale: values.sequenceSale,
        status: values.status
      },
      //email: values.email,
      // password: values.password,
      phone: values.phone,
      roleCompany: values.roleCompany,
      roleSystem: values.roleSystem,
      dealCreation: values.dealCreation,
      acceptOffer: values.acceptOffer,
      makeOffer: values.makeOffer,
      rejectOffer: values.rejectOffer
    }
    return payload;
  }
