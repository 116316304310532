import * as React from 'react';
import { styled } from '@mui/material/styles';
import AutoHeightOverlayNoSnap from './equipmentGrid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} placement="right" arrow disableInteractive={true} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 950,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        position: 'sticky',
        top: 0,
        left: 0,
        zIndex: 1
    },
}));

export default function CustomizedTooltips({ data }) {
    return (
        <>
            {/* <HtmlTooltip
                title={
                    <>
                        <AutoHeightOverlayNoSnap equipment={data?.dealEquipment} />
                    </>
                }
            > */}
            <h4>{data.dealName}</h4>
            {/* </HtmlTooltip> */}
        </>
    );
}
