import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  submitDealRegisterForm,
  getDealInvitationsList,
  deleteDeal,
  dealInvitationAccept,
  dealInvitationReject,
  editDeal
} from "../../reducers/DealReducer";
import { setLoggedInUserData } from "../../reducers/UserReducer";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  Card,
  Typography,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme } from '@mui/material';
import { submitMakeOfferMessage } from "../../reducers/DealCommunicationReducer";
import CustomizedTooltips from "./toolTip";
import { Stack } from '@mui/system';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1.5rem",
  },
  actions: {
    color: "rgb(17, 82, 147)",
  },
  fullWidth: {
    marginLeft: "20px",
  },
  link: {
    color: "rgb(105, 105, 105)",
  },
  childDiv: {
    paddingTop: "10px",
  },
  accept: {
    "&:hover": {
      backgroundColor: "#3BB143",
      border: "2px solid #e7e7e7",
      borderRadius: "5px",
    },
  },
  reject: {
    "&:hover": {
      backgroundColor: "#EC7063",
      border: "2px solid #e7e7e7",
      borderRadius: "5px",
    },
  },
  acceptText: {
    color: "#F4D03F",
  },
  rejectText: {
    backgroundColor: "#EC7063",
  },
  search: {
    paddingLeft: "90px"
  },
  tableheader: {
    marginTop: "-1rem"
  },
  cardStyle: {
    height: "455px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem"
  },
}));
const DealInvitations = (props) => {
  const {
    dealList = [],
    restClient: { rest },
    getDealInvitationsList,
    loggedInUserData,
  } = props;
  const classes = useStyles();
  const transactionId = "6231b09f5292800b4074918e";
  // const [filteredDealList, setFilterDealList] = useState([]);

  // let sortedDeals;
  // useEffect(() => {
  //   const getList = () => {
  //     if (dealList) {
  //       const filterDeals = dealList.filter(
  //         (user) =>
  //           user.dealInvites.some(
  //             (un) => un.invitedUser === loggedInUserData.user._id
  //           ) && user.dealInvites.some((un) => un.inviteStatus === "Sent")
  //       );
  //       console.log(filterDeals);
  //       sortedDeals = filterDeals.sort(function (a, b) {
  //         // Turn your strings into dates, and then subtract them
  //         // to get a value that is either negative, positive, or zero.
  //         return new Date(b.createdAt) - new Date(a.createdAt);
  //       });
  //     }
  //   };
  //   getList();
  //   setFilterDealList(sortedDeals);
  // }, [dealList, loggedInUserData.user._id]);

  useEffect(() => {
    const getList = async () => {
      if (rest) {
        const isInvited = true;
        await getDealInvitationsList(loggedInUserData, isInvited);
      }
    };
    getList();
  }, [rest, getDealInvitationsList, loggedInUserData]);

  const handleAccept = async (event, deal) => {
    const dealId = deal.id;
    const dealInvitesId = (deal.dealInvites || []).filter(
      (un) => un.invitedUser === loggedInUserData.user._id
    );
    const filterInvites = dealInvitesId.map((un) => un._id);
    const result = filterInvites.reduce((acc, cur) => {
      acc = cur;
      return acc;
    }, {});
    const payload = {
      isAccepted: true,
      dealInvites: {
        "dealInvites.$.inviteStatus": "Joined",
      },
      dealParticipants: {
        dealParticipants: [
          {
            startDate: new Date().toISOString(),
            endDate: new Date(
              new Date().setDate(new Date().getDate() + 10)
            ).toISOString(),
            participant: loggedInUserData.user._id,
          },
        ],
      },
    };
    // const transactionCommunication = {
    //   dealId: dealId,
    //   message: `${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} created this group chat`,
    //   transactionEquipment: equipment,
    //   from: `${loggedInUserData.user._id}`,
    //   to: groupMembers.map((user) => user.participant._id),
    //   notificationType: "General Message",
    // };
    // const transaction = {
    //   transaction: {
    //     dealId: dealId,
    //     equipment: equipment,
    //     selectionName: groupName,
    //     price: "$345",
    //     addedBy: `${loggedInUserData.user._id}`
    //   }
    // };
    // await GenerateTransactionRegisterForm(transaction, { ...transactionCommunication, selectionName: groupName });
    const transactioncommunication = {
      transactioncommunication: {
        dealId: dealId,
        transactionId: transactionId,
        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} added to this deal successfully`,
        transactionEquipment: deal.dealEquipment,
        from: loggedInUserData.user._id,
        to: deal.dealParticipants.map((p) => { return p.participant._id }),
        notificationType: "General Message",
        notes: "notes",
        askPrice: "",
        offerPrice: "",
        priceUnit: "USD",
        addedBy: loggedInUserData.user._id
      },
      dealname: deal.dealName,
      dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
    }
    props.dealInvitationAccept(dealId, result, payload, loggedInUserData.user._id);
    props.submitMakeOfferMessage(transactioncommunication);
  };

  const handleReject = (event, deal) => {
    const dealId = deal.id;
    const dealInvitesId = (deal.dealInvites || []).filter(
      (un) => un.invitedUser === loggedInUserData.user._id
    );
    const filterInvites = dealInvitesId.map((un) => un._id);
    const rejectResult = filterInvites.reduce((acc, cur) => {
      acc = cur;
      return acc;
    }, {});
    const payload = {
      isAccepted: false,
      dealInvites: {
        "dealInvites.$.inviteStatus": "Left",
      },
      dealParticipants: {
        dealParticipants: [
          {
            startDate: "",
            endDate: "",
            participant: "",
          },
        ],
      },
    };

    const transactioncommunication = {
      transactioncommunication: {
        dealId: dealId,
        transactionId: transactionId,
        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} was rejected this deal`,
        transactionEquipment: deal.dealEquipment,
        from: loggedInUserData.user._id,
        to: deal.dealParticipants.map((p) => { return p.participant._id }),
        notificationType: "General Message",
        notes: "notes",
        askPrice: "",
        offerPrice: "",
        priceUnit: "USD",
        addedBy: loggedInUserData.user._id
      },
      dealname: deal.dealName,
      dealparticipantemails: deal.dealParticipants.map((p) => { return p.participant.email })
    }
    props.dealInvitationReject(dealId, rejectResult, payload, loggedInUserData.user._id);
    props.submitMakeOfferMessage(transactioncommunication);
  };

  const columns = [
    {
      field: "dealName",
      headerName: "Deal Name",
      width: 250,
      renderCell: (params) => (
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            alignItems="center">
            <div style={{ textDecoration: "none", pointerEvents: (loggedInUserData.user.roleSystem === "admin") ? "none" : "auto" }} className={classes.link} target="_self">
              <CustomizedTooltips data={params.row} />
            </div>
          </Stack>
        </>
      ),
    },
    {
      field: "dealDescription",
      headerName: "Deal Description",
      type: "string",
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.dealDescription} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.row.dealDescription}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "dealStartDate",
      headerName: "Deal Start Date",
      type: "string",
      width: 250,
    },
    {
      field: "creatorCompany",
      headerName: "Company",
      width: 200,
    },
    {
      field: "creatorPeople",
      headerName: "Created By",
      width: 180,
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {(params.row.dealInvites || [])
              .filter((un) => un.invitedUser === loggedInUserData.user._id)
              .find((un) => un.inviteStatus === "Sent") ? (
              <div>
                <button
                  onClick={(event) => handleAccept(event, params.row)}
                  className={classes.accept}
                >
                  Accept
                </button>
                &nbsp;&nbsp;
                <button
                  onClick={(event) => handleReject(event, params.row)}
                  className={classes.reject}
                >
                  Reject
                </button>
              </div>
            ) : (
              <div>
                {(params.row.dealInvites || [])
                  .filter((un) => un.invitedUser === loggedInUserData.user._id)
                  .find((un) => un.inviteStatus === "Joined") ? (
                  <div>
                    <p className={classes.acceptText}>Accepted</p>
                  </div>
                ) : (
                  <div>
                    <p className={classes.rejectText}>Rejected</p>
                  </div>
                )}
              </div>
            )}
          </>
        );
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              DEAL INVITATIONS
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={1}>
              <Grid item lg={12} xs={12}>
                <div className={classes.search}>
                  <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  return (
    <div className={classes.tableheader}>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={dealList || []}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[100]}
            checkboxSelection
            disableSelectionOnClick
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            // density='standard'
            components={{ Toolbar: DataGridTitle }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  dealList: state.deal && state.deal.dealList.dealList,
  loggedInUserData: state.user && state.user.loggedInUserData,
});
export default connect(mapStateToProps, {
  submitDealRegisterForm,
  getDealInvitationsList,
  submitMakeOfferMessage,
  deleteDeal,
  setLoggedInUserData,
  dealInvitationAccept,
  dealInvitationReject,
  editDeal,
})(DealInvitations);
