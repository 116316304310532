import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Payment from "../../Payment/index";
import CardPayment from "../../CardPayment/index";

const useTabStyles = makeStyles({
    root: {
        justifyContent: "center"
    },
    scroller: {
        flexGrow: "1"
    },
    sizes: {
        width: "580px",
        margin: "auto"
    }
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            sx={{ paddingLeft: "100px" }}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography >{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const TabsContainer = (props) => {
    const classes = useTabStyles();

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    console.log(props.setOpen);

    return (
        <Box className={classes.sizes}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                indicatorColor="primary"
                textColor="primary"
                variant={"scrollable"}
                scrollButtons="auto"
                classes={{ root: classes.root, scroller: classes.scroller }}
            >
                <Tab label="ACH PAYMENT" />
                <Tab label="CARD PAYMENT" />
            </Tabs>
            <TabPanel value={value} index={0} style={{ marginTop: "50px" }}>
                <Payment users={props.users} setOpen={props.setOpen} transaction={props.transaction} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CardPayment users={props.users} setOpen={props.setOpen} transaction={props.transaction} />
            </TabPanel>
        </Box>
    );
};

export default TabsContainer