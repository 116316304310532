import React, { useState } from "react";
import { connect } from "react-redux";
import { submitChangePassword } from "../../reducers/AccountReducer";
import { getUserById } from "../../reducers/UserReducer";
// import validate from 'validate.js';
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Button,
  Card,
  Container,
  FormControl,
  CardContent,
  TextField,
  InputLabel
} from "@mui/material";
// import { isValid } from "date-fns";
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../styles/appStyles';
import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../styles/formStyles';
import { Link } from 'react-router-dom';
import getTranslations from "../../utils/getTranslations";

const useStyles = makeStyles(
  {
    text: {
      // display: "grid",
      // gridTemplateColumns: "10rem 15rem"
      // display: "flex"
    },
    input: {
      width: "250px",
    },
    inputBottom: {
      paddingBottom: "20px",
      margin: "-26px 82px 0px 177px",
    },
    label: {
      // textIndent: "30%"
      paddingTop: "14px",
    },
    changePassword: {
      fontSize: "x-large",
      fontWeight: "800",
      color: "rgb(120, 120, 120)",
    },
    error: {
      color: "red",
    },
    changePass: {
      textAlign: "center",
      fontSize: "x-large",
      fontWeight: "800",
      color: "#FF7043",
      marginTop: "-33px",
      marginBottom: "0px"
    }
  },
  { index: 1 }
);

const UserChangePassword = (props) => {
  const classes = useStyles();
  // const initialValues = {
  //   existingPassword: '',
  //   password: '',
  //   confirmPassword: ''
  // };
  const change_password_labels = getTranslations(props.locale).change_password_page;

  const userId = props.user && props.user.user && props.user.user._id;
  // const password = props.user && props.user && props.user.password;
  const userEmail = props.user && props.user.user && props.user.user.email;

  const [existingPassword, setExistingPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [error, setError] = useState({
    existingPassword: "",
    password: "",
    confirmPassword: "",
  });

  // const [formState, setFormState] = useState({
  //   isValid: false,
  //   // values: initialValues,
  //   touched: {},
  //   errors: {},
  // });

  // const schema = {
  //   existingPassword: {
  //     presence: { allowEmpty: false, message: 'Existing Password is required' },
  //     format: {
  //       pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  //       message: function (value, attribute, validatorOptions, attributes, globalOptions) {
  //         return validate.format("Password must be between 8 to 15 characters and contain at least one numeric digit and a special character", {
  //           num: value
  //         });
  //       }
  //     },
  //   },
  //   password: {
  //     presence: { allowEmpty: false, message: 'Password is required' },
  //     format: {
  //       pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  //       message: function (value, attribute, validatorOptions, attributes, globalOptions) {
  //         return validate.format("Password must be between 8 to 15 characters and contain at least one numeric digit and a special character", {
  //           num: value
  //         });
  //       },
  //     },
  //   },
  //   confirmPassword: {
  //     presence: { allowEmpty: false, message: 'Confirm password is required' },
  //     format: {
  //       pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
  //       message: function (value, attribute, validatorOptions, attributes, globalOptions) {
  //         return validate.format("Password must be between 8 to 15 characters and contain at least one numeric digit and a special character", {
  //           num: value
  //         });
  //       },
  //     },
  //     equality: "password"
  //   }

  // };

  // const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  // const handleChange = (event) => {
  //   event.persist();

  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     values: {
  //       ...prevFormState.values,
  //       [event.target.name]: event.target.value
  //     },
  //     touched: {
  //       ...prevFormState.touched,
  //       [event.target.name]: true
  //     }
  //   }));
  // };

  // useEffect(() => {
  //   const errors = validate(formState.values, schema, { fullMessages: false });

  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     isValid: !errors,
  //     errors: errors || {}
  //   }));

  // }, [formState.values,schema]);

  // const checkErrors = () => {
  //   let isErrorExist = false;
  //   let touched = {};
  //   for (let key in formState.values) {
  //     if (formState.values.hasOwnProperty(key)) {
  //       touched = {
  //         ...touched,
  //         [key]: true
  //       }
  //     }
  //   }

  //   const errors = validate(formState.values, schema, { fullMessages: false });
  //   if (errors) {
  //     isErrorExist = true;
  //   }

  //   setFormState((prevFormState) => ({
  //     ...prevFormState,
  //     isValid: !errors,
  //     errors: errors || {},
  //     touched: touched,
  //   }));

  //   return isErrorExist;
  // }

  const handleClear = (event) => {
    // event.persist();
    // let { name, value } = event.target;
    setExistingPassword("");
    setPassword("");
    setConfirmPassword("");
    setError({
      existingPassword: "",
      password: "",
      confirmPassword: "",
    });

    // setFormState((prevFormState) => ({
    //   ...prevFormState,
    //   values: {
    //     formState : ''
    //   },
    // }));
  };

  // const validateInput = e => {
  //   let { name, value } = e.target;
  //   setFormState(prevFormState => {
  //     const stateObj = { ...prevFormState, [name]: "" } })}
  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "existingPassword":
          if (!value) {
            stateObj[name] = change_password_labels.existing_pass_err;
          }
          break;

        case "password":
          if (!value) {
            stateObj[name] = change_password_labels.new_pass_err;
          } else if (!/^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(value)) {
            stateObj[name] = change_password_labels.pass_length_err;
          } else if (confirmPassword && value !== confirmPassword) {
            stateObj["confirmPassword"] =
              change_password_labels.mismatch_pass_err;
          } else {
            stateObj["confirmPassword"] = confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = change_password_labels.confirm_pass_err;
          } else if (password && value !== password) {
            stateObj[name] = change_password_labels.mismatch_pass_err;
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  };

  const submitForm = (event) => {
    event.preventDefault();

    // Check for validation errors
    let validationErrors = false;

    if (!existingPassword) {
      setError((prev) => ({
        ...prev,
        existingPassword: change_password_labels.existing_pass_err,
      }));
      validationErrors = true;
    }

    if (!password || !/^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/.test(password)) {
      setError((prev) => ({
        ...prev,
        password: change_password_labels.pass_length_err,
      }));
      validationErrors = true;
    }

    if (!confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: change_password_labels.confirm_pass_err,
      }));
      validationErrors = true;
    } else if (password !== confirmPassword) {
      setError((prev) => ({
        ...prev,
        confirmPassword: change_password_labels.mismatch_pass_err,
      }));
      validationErrors = true;
    }

    // If there are validation errors, prevent form submission
    if (validationErrors) return;

    // Clear any existing error messages if the input is valid
    setError({
      existingPassword: "",
      password: "",
      confirmPassword: "",
    });

    // Proceed with form submission
    props.submitChangePassword(userId, existingPassword, password);
    setExistingPassword("");
    setPassword("");
    setConfirmPassword("");
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Card sx={{ width: '100%', maxWidth: 600, padding: '15px' }}> {/* Optional maxWidth to control card size */}
        <Grid container>
          <Grid item xs={12} lg={12}>
            <CardContent>
              <h5 className={classes.changePass}>{change_password_labels.page_title}</h5>
              <p style={{ color: "rgb(120, 120, 120)" }}>User Name/Email : {userEmail}</p>
              <FormControlWrapper>
                <FormControl>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label={change_password_labels.existing_password}
                    placeholder={change_password_labels.existing_pw_placeholder}
                    data-test-id="Create-Password-existingPassword"
                    name="existingPassword"
                    id="existingPassword"
                    onChange={(e) => setExistingPassword(e.target.value)}
                    onBlur={validateInput}
                    type="password"
                    value={existingPassword}
                  />
                  {error.existingPassword && (
                    <span className={classes.error}>{error.existingPassword}</span>
                  )}
                </FormControl>
              </FormControlWrapper>
              <FormControlWrapper>
                <FormControl>
                  <TextField
                    value={password}
                    variant="outlined"
                    fullWidth
                    label={change_password_labels.new_password}
                    placeholder={change_password_labels.new_pw_placeholder}
                    data-test-id="Register-User-password"
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={validateInput}
                    type="password"
                  />
                  {error.password && (
                    <span className={classes.error}>{error.password}</span>
                  )}
                </FormControl>
              </FormControlWrapper>
              <FormControlWrapper>
                <FormControl>
                  <TextField
                    value={confirmPassword}
                    variant="outlined"
                    fullWidth
                    label={change_password_labels.confirm_password}
                    placeholder={change_password_labels.confirm_pw_placeholder}
                    data-test-id="Verify-Confirm -Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={validateInput}
                    type="password"
                  />
                  {error.confirmPassword && (
                    <span className={classes.error}>{error.confirmPassword}</span>
                  )}
                </FormControl>
              </FormControlWrapper>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" spacing={5}>
                    <Grid item>
                      <FormButtonWrapper>
                        <Link to="/dealpage" className={classes.link}>
                          <Button
                            type="cancel"
                            variant="contained"
                            size="large"
                            sx={{ backgroundColor: "#f50057" }}
                          >
                            {change_password_labels.cancel}
                          </Button>
                        </Link>
                      </FormButtonWrapper>
                    </Grid>
                    <Grid item>
                      <FormButtonWrapper>
                        <Button
                          onClick={handleClear}
                          type="reset"
                          variant="contained"
                          size="large"
                          sx={{ backgroundColor: "#f50057" }}
                        >
                          {change_password_labels.clear}
                        </Button>
                      </FormButtonWrapper>
                    </Grid>
                    <Grid item>
                      <FormButtonWrapper>
                        <Button
                          onClick={submitForm}
                          color="primary"
                          variant="contained"
                          size="large"
                          className="Buttons"
                        >
                          {change_password_labels.submit}
                        </Button>
                      </FormButtonWrapper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.userData,
  locale: state.locale
});

export default connect(mapStateToProps, { submitChangePassword, getUserById })(
  UserChangePassword
);
