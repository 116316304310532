import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  Container,
  Card,
  CardContent,
  FormControl,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import validate from 'validate.js';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../styles/appStyles';

import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../styles/formStyles';

import useQuery from '../../utils/useQuery';
import { verifyUser } from '../../reducers/AccountReducer';

const Activate = props => {
  const values = {
    password: '',
    confirmPassword: ''
  };
  const [formState, setFormState] = useState({
    isValid: false,
    values: values,
    touched: {},
    errors: {},
  });

  const [passwordShown, setPasswordShown] = useState(false);
  const query = useQuery();
  const token = query.get('token');
  const verifyRole = useLocation().pathname;
  let role;
  if (verifyRole === '/verify/passwordset' || verifyRole === "/verify") {
    role = 'regular';
  } else if (verifyRole === '/forgotpassword') {
    role = 'userVerified';
  }
  else {
    role = "admin";
  }
  const schema = {
    password: {
      presence: { allowEmpty: false, message: 'New Password is required' },
      format: {
        pattern: /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/,
        message: "Password must be between 8 to 15 characters and contain at least one numeric digit and a special character",
      },
    },
    confirmPassword: {
      presence: { allowEmpty: false, message: 'Re-enter new password is required' },
      equality: {
        attribute: "password",
        message: "Passwords do not match"
      }
    }
  };

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  useEffect((schema) => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));

  }, [formState.values]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true
        }
      }
    }

    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  }

  const submitForm = event => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      if (token) {
        const payload = {
          token,
          password: values.password
        }
        if (role === 'regular')
          props.verifyUser('verifySignupSetPasswordLong', payload);  // verify-and-password-set for regular users
        else
          props.verifyUser('resetPwdLong', payload);  // forgot-password 
      }
    }
  }

  useEffect(() => {
    const activate = async () => {
      if (token && role === 'admin') {
        await props.verifyUser('verifySignupLong', token);
      }
    }
    activate();
  }, [props, role, token]);

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="sm">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card >
                    <Header >
                      <HeaderText >
                        <h5 style={{color: "#FF7043"}}>Welcome to AskTakeGo</h5>
                        <p style={{textAlign: "start", paddingBottom: "10px", marginBottom: "-10px"}}>Reset Your password</p>
                      </HeaderText>
                    </Header>
                    {token && (role === 'regular' || verifyRole === '/forgotpassword') &&
                      <>
                        <CardContent >
                          <form onSubmit={submitForm}>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError('password')}
                                  helperText={
                                    hasError('password') ? formState.errors.password[0] : null
                                  }
                                  label="New Password*"
                                  placeholder="New Password*"
                                  name="password"
                                  data-test-id="New-Password-Password"
                                  onChange={handleChange}
                                  type={passwordShown ? "text" : "password"}
                                  value={formState.values.password || ''}
                                  variant="outlined"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment>
                                        <IconButton>
                                          <VisibilityIcon onClick={togglePasswordVisiblity} />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError('confirmPassword')}
                                  fullWidth
                                  helperText={
                                    hasError('confirmPassword') ? formState.errors.confirmPassword[0] : null
                                  }
                                  label="Re-enter new Password"
                                  placeholder="Re-enter new Password"
                                  name="confirmPassword"
                                  onChange={handleChange}
                                  data-test-id="New-Password-Confirm"
                                  type="password"
                                  value={formState.values.confirmPassword || ''}
                                  variant="outlined"
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormButtonWrapper>
                              <Button color="primary" variant="contained" type="submit">
                                Submit
                              </Button>
                            </FormButtonWrapper>
                          </form>
                        </CardContent>
                      </>
                    }
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, { verifyUser })(Activate);
