import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import validate from "validate.js";
import {
  submitUserRegisterForm,
  getUserById,
  editUser,
  removeUserFromPending,
  getUserList,
} from "../../../reducers/UserReducer";
import { getContactById } from "../../../reducers/EspoCrmReducer";
import { getCompanyList } from "../../../reducers/CompanyReducer";
import useQuery from "../../../utils/useQuery";
import {
  schema,
  initialFormValues,
  getPayload,
  adminSelectableRoles,
} from "./utils";
import { makeStyles } from "@mui/styles";
import {
  HeaderText,
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
} from "../../../styles/appStyles";

import {
  FormControlWrapper,
  FormButtonWrapper,
} from "../../../styles/formStyles";

import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: "6rem"
  },
  numberField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  basicinfo: {
    paddingBottom: "10px",
    fontSize: "x-large",
    fontWeight: "800",
    color: "rgb(120, 120, 120)",
  },
}));

validate.validators.IsValidPhoneNumber = function (value) {
  if (!validate.isEmpty(value) && value.length !== 10)
    return "Phone Number should be exactly 10 digits long";
  return null;
};

validate.validators.IsValidCompanyWebsite = function (value) {
  // var regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  var regex = new RegExp(
    "^(https?:\\/\\/)?" +
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
    "((\\d{1,3}\\.){3}\\d{1,3}))" +
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
    "(\\?[;&a-z\\d%_.~+=-]*)?" +
    "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Enter a valid Web URL";
  }
  return null;
};

const CreateUser = (props) => {
  const classes = useStyles();
  const { userList = [], restClient: { rest }, userData, companyList, contactData, getUserList, getUserById, getCompanyList, getContactById } = props;
  const query = useQuery();
  const action = query.get("action");
  const userId = query.get("id");

  const [formState, setFormState] = useState({
    isValid: false,
    values: initialFormValues,
    touched: {},
    errors: {},
  });

  const [parentCompany, setParentCompany] = useState([
    { id: "", companyName: "" },
  ]);
  const [selectedRole, setSelectedRole] = useState("");
  let [disabled, setDisabled] = useState();
  const history = useHistory();

  const handleCancel = (event) => {
    event.preventDefault();
    // Go back to the previous page
    history.goBack();
  };

  useEffect(() => {
    if ((action === "edit" || action === "editPending") && userId && rest) {
      const getUser = async () => {
        getUserById(userId);
      };
      getUser();
    }
  }, [action, userId, getUserById, rest]);

  useEffect(() => {
    if ((action === 'espoContact') && userId && rest) {
      const getContact = async () => {
        getContactById(userId);
      };
      getContact();
    }
  }, [action, userId, getContactById, rest]);

  useEffect(() => {
    if ((action === "edit" || action === "editPending") && userData) {
      setFormState({
        isValid: false,
        values: userData,
        touched: {},
        errors: {},
      });
    }
  }, [action, userData]);

  useEffect(() => {
    if ((action === 'espoContact') && contactData) {
      setFormState({
        isValid: false,
        values: contactData,
        touched: {},
        errors: {},
      });
    }
  }, [action, contactData]);

  useEffect(() => {
    if (rest) {
      const getUserParentCompanies = async () => {
        getCompanyList();
      };
      getUserParentCompanies();
    }
  }, [rest, getCompanyList]);

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },

      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  const handleRoleChange = (event) => {
    // event.persist();
    const roleSel = event.target.value;
    let companySel = [];
    let disabled = false;
    switch (roleSel) {
      case "Customer Success Lead":
      case "Cusomer success manager":
      case "HR lead":
      case "HR Manager":
      case "Implementation Lead":
      case "Implementation Manager":
      case "Leadership":
      case "Marketing Lead":
      case "Marketing Manager":
      case "Sales Lead":
      case "Sales Manager":
      case "Trusted Agent":
        companySel = companyList
          .filter((un) => un.companyName === process.env.REACT_APP_ATG_COMPANY)
          .map((item) => ({ id: item.id, companyName: item.companyName }));
        disabled = true;
        break;
      default:
        companySel = companyList;
    }
    setParentCompany(companySel);
    setSelectedRole(roleSel);
    setDisabled(disabled);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value,
        parentCompany: companySel[0].id,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value,
      },
      touched: {
        ...prevFormState.touched,
        [field]: true,
      },
    }));
  };

  useEffect(() => {
    const getCompany = async () => {
      try {
        if (
          (action === "edit" ||
            action === "editPending" ||
            action === "espoContact") &&
          userId &&
          rest &&
          userData &&
          companyList
        ) {
          const companySel = companyList
            .filter((un) => un.id === userData.parentCompany)
            .map((item) => ({ id: item.id, companyName: item.companyName }));
          let disabled = false;
          if (companySel[0].companyName === "ATG") {
            setParentCompany(companySel);
            disabled = true;
            setDisabled(disabled);
          } else if (companySel[0].companyName !== "ATG") {
            setParentCompany(companyList);
            disabled = false;
            setDisabled(disabled);
          }
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getCompany();
  }, [action, userId, userData, companyList, rest]);

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  };

  const StartCase = (text) => {
    return text
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true,
        };
      }
    }

    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  };

  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          await getUserList();
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getUserList]);

  const handleUserDelete = (event, user) => {
    props.removeUserFromPending(userId, userList);
  };

  const submitForm = (event) => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = getPayload(values);
      // pass payload to Api layer.
      if ((action === 'edit' || action === 'editPending' || action === 'espoContact') && userId) {
        props.editUser(userId, payload);
      } else {
        payload.email = values.email;
        payload.password = values.password;
        props.submitUserRegisterForm(payload);
      }
    }
  };

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12}>
                  <Card>
                    <HeaderText>
                      {action === "edit" ? (
                        <h5>Edit User</h5>
                      ) : (
                        <h5>Create New User</h5>
                      )}
                    </HeaderText>
                    <CardContent>
                      <form onSubmit={submitForm}>
                        <Grid container spacing={4}>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("firstName")}
                                  helperText={
                                    hasError("firstName")
                                      ? formState.errors.firstName[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="First Name *"
                                  fullWidth
                                  data-test-id="Create-User-FirstName"
                                  placeholder="First Name"
                                  type="text"
                                  name="firstName"
                                  value={formState.values.firstName}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("lastName")}
                                  helperText={
                                    hasError("lastName")
                                      ? formState.errors.lastName[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="Last Name *"
                                  fullWidth
                                  data-test-id="Create-User-LastName"
                                  placeholder="Last Name"
                                  type="text"
                                  name="lastName"
                                  value={formState.values.lastName}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("alias")}
                                  helperText={
                                    hasError("alias")
                                      ? formState.errors.alias[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="Alias"
                                  fullWidth
                                  data-test-id="Create-User-Alias"
                                  placeholder="Alias"
                                  type="text"
                                  name="alias"
                                  value={formState.values.alias}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className="w-100">
                                <InputLabel
                                  id="roleType-select-outlined-label"
                                >
                                  User Type *
                                </InputLabel>
                                <Select
                                  error={hasError("roleType")}
                                  labelId="roleType-select-outlined-label"
                                  id="roleType-select-outlined"
                                  label="User Type"
                                  data-test-id="Create-User-RoleType"
                                  placeholder="User Type"
                                  value={formState.values.roleType}
                                  onChange={handleRoleChange}
                                  inputProps={{
                                    name: "roleType",
                                    id: "roleType",
                                  }}
                                >
                                  <MenuItem value="0" disabled>
                                    Select User Type
                                  </MenuItem>
                                  {adminSelectableRoles.map((roleType) => (
                                    <MenuItem value={roleType.name}>
                                      {roleType.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {hasError("roleType") && (
                                  <FormHelperText
                                    htmlFor="form-selector"
                                    error={hasError("roleType")}
                                  >
                                    {formState.errors.roleType[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className="w-100">
                                <InputLabel id="parentCompany-select-outlined-label">
                                  Parent Company
                                </InputLabel>
                                <Select
                                  error={hasError("parentCompany")}
                                  labelId="parentCompany-select-outlined-label"
                                  id="parentCompany"
                                  label="Parent Company"
                                  data-test-id="Create-User-ParentCompany"
                                  placeholder="Parent Company"
                                  value={formState.values.parentCompany}
                                  // value={parentCompany[0].id}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: "parentCompany",
                                    id: "parentCompany",
                                  }}
                                  disabled={disabled}
                                >
                                  <MenuItem value="0" disabled>
                                    Select Parent Company
                                  </MenuItem>
                                  {(parentCompany || []).map((company) => (
                                    <MenuItem value={company.id}>
                                      {company.companyName}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {hasError("parentCompany") && (
                                  <FormHelperText
                                    htmlFor="form-selector"
                                    error={hasError("parentCompany")}
                                  >
                                    {formState.errors.parentCompany[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <div>
                            {/* <div className={classes.basicinfo}>Security Check</div> */}
                            <div>
                                  <FormControl component="fieldset">
                                    <FormControlLabel
                                      checked={formState.values.secondFactor}
                                      onChange={handleChange}
                                      type="checkbox"
                                      name="secondFactor"
                                      control={<Checkbox color="primary" />}
                                      label="2FA Check"
                                      labelPlacement="end"
                                    />
                                  </FormControl>
                                </div>
                            <div>
                            </div>
                              <div className={classes.basicinfo}>Notifications</div>
                              <div>
                                <div>
                                  <FormControl component="fieldset">
                                    <FormControlLabel
                                      checked={formState.values.dealCreation}
                                      onChange={handleChange}
                                      type="checkbox"
                                      name="dealCreation"
                                      control={<Checkbox color="primary" />}
                                      label="Deal Creation"
                                      labelPlacement="end"
                                    />
                                  </FormControl>
                                </div>
                                <div>
                                  <FormControl component="fieldset">
                                    <FormControlLabel
                                      checked={formState.values.makeOffer}
                                      onChange={handleChange}
                                      type="checkbox"
                                      name="makeOffer"
                                      control={<Checkbox color="primary" />}
                                      label="Make Offer"
                                      labelPlacement="end"
                                    />
                                  </FormControl>
                                </div>
                                <div>
                                  <FormControl component="fieldset">
                                    <FormControlLabel
                                      checked={formState.values.acceptOffer}
                                      onChange={handleChange}
                                      type="checkbox"
                                      name="acceptOffer"
                                      control={<Checkbox color="primary" />}
                                      label="Accept Offer"
                                      labelPlacement="end"
                                    />
                                  </FormControl>
                                </div>
                                <div>
                                  <FormControl component="fieldset">
                                    <FormControlLabel
                                      checked={formState.values.rejectOffer}
                                      onChange={handleChange}
                                      type="checkbox"
                                      name="rejectOffer"
                                      control={<Checkbox color="primary" />}
                                      label="Reject Offer"
                                      labelPlacement="end"
                                    />
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("email")}
                                  helperText={
                                    hasError("email")
                                      ? formState.errors.email[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="Email Address *"
                                  fullWidth
                                  disabled={
                                    action === "edit" ||
                                    action === "editPending" ||
                                    action === "espoContact"
                                  }
                                  data-test-id="Create-User-Email"
                                  placeholder="Email"
                                  type="text"
                                  name="email"
                                  value={formState.values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("webURL")}
                                  helperText={
                                    hasError("webURL")
                                      ? formState.errors.webURL[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="companyWebsite"
                                  fullWidth
                                  data-test-id="Create-User-WebURL"
                                  placeholder="Enter CompanyWebsite"
                                  type="text"
                                  name="webURL"
                                  value={formState.values.webURL}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className="w-100">
                                <InputLabel
                                  id="countryCode-select-outlined-label"
                                >
                                  Country *
                                </InputLabel>
                                <Select
                                  error={hasError("countryCode")}
                                  labelId="countryCode-select-outlined-label"
                                  id="countryCode-select-outlined"
                                  label="Country"
                                  data-test-id="Create-User-CountryCode"
                                  placeholder="Enter Country"
                                  value={formState.values.countryCode}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: "countryCode",
                                    id: "countryCode",
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select country</MenuItem>
                                  {[
                                    { name: "USA", value: "1" },
                                    { name: "India", value: "91" },
                                  ].map((countryCode) => (
                                    <MenuItem
                                      key={countryCode.value}
                                      value={countryCode.value}
                                    >
                                      {countryCode.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {hasError("countryCode") && (
                                  <FormHelperText
                                    htmlFor="form-selector"
                                    error={hasError("countryCode")}
                                  >
                                    {formState.errors.countryCode[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("mobileNumber")}
                                  helperText={
                                    hasError("mobileNumber")
                                      ? formState.errors.mobileNumber[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="Mobile Number *"
                                  className={classes.numberField}
                                  fullWidth
                                  data-test-id="Create-User-MobileNumber"
                                  placeholder="Enter Mobile Number"
                                  type="number"
                                  name="mobileNumber"
                                  value={formState.values.mobileNumber}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl>
                                <TextField
                                  error={hasError("smsNumber")}
                                  helperText={
                                    hasError("smsNumber")
                                      ? formState.errors.smsNumber[0]
                                      : null
                                  }
                                  variant="outlined"
                                  label="Sms Number"
                                  className={classes.numberField}
                                  fullWidth
                                  data-test-id="Create-User-SmsNumber"
                                  placeholder="Enter Sms Number"
                                  type="number"
                                  name="smsNumber"
                                  value={formState.values.smsNumber}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                />
                              </FormControl>
                            </FormControlWrapper>
                            {/* <FormControlWrapper>
                              <FormControl variant="outlined" className="w-100">
                                <InputLabel
                                  id="roleSystem-select-outlined-label"
                                >
                                  Role System *
                                </InputLabel>
                                <Select
                                  error={hasError("roleSystem")}
                                  labelId="roleSystem-select-outlined-label"
                                  id="roleSystem-select-outlined"
                                  label="Role System"
                                  data-test-id="Create-User-RoleSystem"
                                  placeholder="Enter Role System"
                                  value={formState.values.roleSystem}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: "roleSystem",
                                    id: "roleSystem",
                                  }}
                                >
                                  <MenuItem value="0" disabled>
                                    Select Role System
                                  </MenuItem>
                                  {[{ name: "regular" }].map((roleSystem) => (
                                    <MenuItem value={roleSystem.name}>
                                      {roleSystem.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {hasError("roleSystem") && (
                                  <FormHelperText
                                    htmlFor="form-selector"
                                    error={hasError("roleSystem")}
                                  >
                                    {formState.errors.roleSystem[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper> */}
                          </Grid>
                          <Grid container className={classes.root} spacing={0}>
                            <Grid item xs={12}>
                              <Grid container justifyContent="center" spacing={5} >
                                <Grid item>
                                  <FormButtonWrapper className="align-box-row justify-box-row">
                                    {/* <Link to="/users" className='linkStyle'> */}
                                      <Button
                                        type="cancel"
                                        color="secondary"
                                        className="my-2"
                                        style={{ textDecoration: 'none' }}
                                        data-test-id="Create-User-Cancel"
                                        variant="contained"
                                        size="large"
                                        onClick={handleCancel}
                                      >
                                        cancel
                                      </Button>
                                    {/* </Link> */}
                                  </FormButtonWrapper>
                                  {/* <button onClick={handleCancel}>Cancel</button> */}
                                </Grid>
                                <Grid item>
                                  <FormButtonWrapper className="align-box-row justify-box-row">
                                    <div hidden={action === "edit"} >
                                      <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        data-test-id="Create-User-Submit"
                                        size="large"
                                        className="Buttons">
                                        {action === 'editPending' ? 'Approve' : 'Submit'}
                                      </Button>
                                    </div>
                                  </FormButtonWrapper>
                                </Grid>
                                <Grid item >
                                  <FormButtonWrapper className="align-box-row justify-box-row">
                                    <div hidden={action !== "edit"} >
                                      <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        data-test-id="Create-User-Submit"
                                        size="large"
                                        className="Buttons"
                                      >
                                        {action === 'edit' ? 'Update' : 'Submit'}
                                      </Button>
                                    </div>
                                  </FormButtonWrapper>
                                </Grid>
                                <Grid item >
                                  <div hidden={action !== "editPending"} >
                                    <Button
                                      // type="submit"
                                      color="secondary"
                                      variant="contained"
                                      data-test-id="Create-User-Submit"
                                      size="large"
                                      onClick={() => { if (window.confirm('Are you sure want to delete it?')) { handleUserDelete() } }}
                                      className="my-2">
                                      {/* {action === 'editPending' ? 'Reject' : null} */}
                                      Reject
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  userData: state.user.userData,
  companyList: state.company && state?.company?.companyList?.companyList,
  userList: state.user && state?.user?.userList?.userList,
  contactData: state.espoCrm && state.espoCrm.contactData,
});

export default connect(mapStateToProps, {
  submitUserRegisterForm,
  getUserById,
  editUser,
  getCompanyList,
  getUserList,
  removeUserFromPending,
  getContactById,
})(CreateUser);
