import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import UserReducer from './UserReducer';
import RestClientReducer from './RestClientReducer';
import CompanyReducer from './CompanyReducer';
import NotifierReducer from './NotifierReducer'
import NetworkReducer from './NetworkReducer';
import NetworkContactReducer from './NetworkContactReducer';
import EquipmentReducer from './EquipmentReducer';
import LoaderReducer from './LoaderReducer';
import DealReducer from './DealReducer'
import AccountReducer from './AccountReducer';
import DealCommunicationReducer from './DealCommunicationReducer';
import BrandReducer from './BrandReducer';
import CompanyBranchReducer from './CompanyBranchReducer';
import EspoCrmReducer from './EspoCrmReducer';
import TransactionReducer from './TransactionReducer';
import VendorReducer from './VendorReducer';
import DealInvitationCountReducer from './DealInvitationCountReducer';
import LocaleReducer from './LocaleReducer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  restClient: RestClientReducer,
  user: UserReducer,
  loader: LoaderReducer,
  equipment: EquipmentReducer,
  company: CompanyReducer,
  notifier: NotifierReducer,
  network: NetworkReducer,
  networkContact: NetworkContactReducer,
  deal: DealReducer,
  account: AccountReducer,
  dealCommunication: DealCommunicationReducer,
  brand: BrandReducer,
  companyBranch: CompanyBranchReducer,
  espoCrm: EspoCrmReducer,
  transaction: TransactionReducer,
  vendor: VendorReducer,
  dealInvitationCount: DealInvitationCountReducer,
  locale: LocaleReducer
})

export default createRootReducer
