// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container {
  margin-top: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;

  .add-icon {
    width: 50px;
    height: 50px;
    color: #3e5272;
  }

  input {
    display: none;
  }

  .imgcontainer {
    margin-top: 30px;
    border-radius: 50%;
    border: 3px solid black;

    img {
      display: block;
      border-radius: 50%;
    }
  }
}`, "",{"version":3,"sources":["webpack://./src/components/UserProfile/UserProfileAvatar/avatar.css"],"names":[],"mappings":"AAAA;EACE,wIAAwI;AAC1I;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,iBAAiB;EACjB,mBAAmB;;EAEnB;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,kBAAkB;IAClB,uBAAuB;;IAEvB;MACE,cAAc;MACd,kBAAkB;IACpB;EACF;AACF","sourcesContent":["* {\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;\n}\n\n.container {\n  margin-top: 50px;\n  display: flex;\n  flex-flow: column;\n  align-items: center;\n\n  .add-icon {\n    width: 50px;\n    height: 50px;\n    color: #3e5272;\n  }\n\n  input {\n    display: none;\n  }\n\n  .imgcontainer {\n    margin-top: 30px;\n    border-radius: 50%;\n    border: 3px solid black;\n\n    img {\n      display: block;\n      border-radius: 50%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
