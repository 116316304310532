import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PersonIcon from '@mui/icons-material/Person';
import { submitNetworkRegisterForm, getNetworkList, removeNetwork } from '../../../reducers/NetworkReducer';
import { getUploadPayload } from './utils';
import { getUserList } from "../../../reducers/UserReducer"
import { getCompanyList } from "../../../reducers/CompanyReducer"
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchBar from '../../../utils/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';
import { uploadImage } from "../../DealChat/blobStorage";
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1.5rem",
  },
  actions: {
    color: "#3E5272",
  },
  header: {
    maxWidth: "730px",
    marginLeft: "-5rem"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-31px"
  },
  cardStyle: {
    height: "480px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem",
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      height: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
  },
  textInput: {
    paddingLeft: "10px"
  }
}));

function getBlobUrls(uploadResults) {
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) {
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}

const NetworkList = (props) => {
  const classes = useStyles();
  const {
    networkList = [],
    companyList = [],
    userList = [],
    restClient: { rest },
    getNetworkList,
    getUserList,
    getCompanyList,
    totalCount
  } = props;
  const history = useHistory();

  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: ""
  });
  const [rowCountState, setRowCountState] = useState(totalCount);
  const [file, setFile] = useState();
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [filteredNetworkList, setFilteredNetworkList] = useState([]);
  const [search, setSearch] = useState('');
  const [files, setFiles] = useState([]);

  // useEffect(() => {
  //   // Set filteredUserList to userList initially
  //   if(networkList?.length > 0){
  //     setFilteredNetworkList(networkList);
  //   }
  // }, [networkList]);

  useEffect(() => {
    // Filter user list when search state changes
    if (networkList?.length > 0) {
      const filteredList = networkList.filter(user =>
        (user.networkName && user.networkName.toLowerCase().includes(search.toLowerCase())) ||
        (user.parentPeople && user.parentPeople.toLowerCase().includes(search.toLowerCase())) ||
        (user.parentCompany && user.parentCompany[0].toLowerCase().includes(search.toLowerCase()))
      );

      setFilteredNetworkList(filteredList);
      if (filteredList.length > 0) {
        setRowCountState(filteredList.length);
      }
    }
  }, [search, networkList]);

  //get the data from networkreducer
  useEffect(() => {
    const getList = async () => {
      if (rest) {
        if (pageState) {
          await getNetworkList(pageState);
        }
        await getCompanyList();
        await getUserList();
      }
    };
    getList();
  }, [rest, getNetworkList, getCompanyList, getUserList, pageState]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  function handleChange(event) {
    setFile(event.target.files[0]);
    setDisabled(false);
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }

  const networks = userList?.filter((un) => un.firstName && un.lastName).map((item) => ({
    id: item.id,
    name: `${item.firstName} ${item.lastName}`,
  }));

  // edit network.
  const handleNetworkEdit = (event, network) => {
    history.push(`/network/create?action=edit&id=${network.id}`);
  };

  // view network contacts.
  const handleViewNetworkContact = (event, network) => {
    history.push(`/networkcontacts?id=${network.id}`);
  };

  // delete network.
  const handleNetworkDelete = (event, network) => {
    props.removeNetwork(network.id, networkList, totalCount);
  };

  const columns = [
    {
      field: "networkName",
      headerName: "Network",
      width: 400,
    },
    {
      field: "parentPeople",
      headerName: "Parent People",
      type: "string",
      width: 350,
    },
    {
      field: "parentCompany",
      headerName: "Parent Company",
      type: "string",
      width: 350,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Network">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleViewNetworkContact(event, params.row);
                }}
              >
                <PersonIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Network">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleNetworkEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Network">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.networkName +
                    " ?"
                  ) && handleNetworkDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  // validation for upload networks
  const ImportExport = (event) => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase()) && companyList) {
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = async (e) => {
            const payload = getUploadPayload(
              reader.result,
              companyList,
              networks
            );
            if (payload?.length) {
              let blobPath = [];
              if (files.length > 0) {
                blobPath = files.map((file) => file.name);
                await uploadMultipleFiles("fileupload", "network", files);
                setFiles([]);
              }
              props.submitNetworkRegisterForm(payload, "upload"); // send the model data to submitNetworkRegisterForm
            } else {
              return 0;
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref.current.form.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };


  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              NETWORKS
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New Network">
                    <Link to="/network/create">
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }



  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };

  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  return (
    <Card className={classes.cardStyle}>
      <Grid container xs={12} my={2}>
        <Grid item xs={12} lg={8}>
          <form>
            <h3>Upload Networks Excel File (.xlsx) :
              <input type="file" onChange={handleChange} ref={ref} className={classes.textInput} />
              <Button component="label" variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={ImportExport} disabled={disabled}>
                Upload file
              </Button>
            </h3>
          </form>
        </Grid>
        <Grid item xs={12} lg={4} sx={isSmallScreen ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start'
        } : {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}>
          <SearchBar
            searchText={search}
            filterChange={onFilterChange}
            onClickFunc={handleClearText}
          />
        </Grid>
      </Grid>
      <ThemeProvider theme={theme}>
        <DataGrid
          rowCount={filteredNetworkList.length >= 0 ? filteredNetworkList.length : rowCountState}
          paginationMode="server"
          rowsPerPageOptions={[100]}
          pagination
          pageSize={pageState.pageSize}
          onPageChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage + 1 }));
          }}
          columns={columns}
          rows={filteredNetworkList || []}
          checkboxSelection
          sortingMode="server"
          onSortModelChange={(col) => {
            if (col.length > 0) {
              if (col[0].sort === "asc") {
                setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
              } else {
                setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
              }
            } else {
              setPageState((old) => ({ ...old, sort: null }));
            }
          }}
          sx={{
            "& .css-ptiqhd-MuiSvgIcon-root": {
              color: "#ee9907",
            },
            // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            //   display: 'none'
            // }
          }}
          // density='standard'
          components={{ Toolbar: DataGridTitle }}
        />
      </ThemeProvider>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  networkList: state.network && state?.network?.networkList?.networkList,
  companyList: state.company && state.company.companyList.companyList,
  userList: state.user && state.user.userList.userList,
  totalCount: state.network && state?.network?.networkList?.total,
  limit: state.network && state?.network?.networkList?.limit,
});

export default connect(mapStateToProps, {
  submitNetworkRegisterForm,
  removeNetwork,
  getNetworkList,
  getUserList,
  getCompanyList,
})(NetworkList);