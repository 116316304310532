import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useQuery from '../../utils/useQuery';
import validate from 'validate.js';
import { Grid, Button, Select, MenuItem, TextField, InputLabel, Checkbox, FormControlLabel, FormControl, CardContent, DialogActions } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Dialog from "@mui/material/Dialog";
import UserChangePassword from "../UserChangePassword/index";
import { editUserProfile } from '../../reducers/UserReducer';
import { getCompanyList } from '../../reducers/CompanyReducer';
import { schema, getPayload } from './utils';
import UserProfileAvatar from './UserProfileAvatar';
import getTranslations from '../../utils/getTranslations';

import {
  FormControlWrapper,
  FormButtonWrapper,
} from "../../styles/formStyles";
import defaultAvatar from '../../assets/images/DefaultAvatar.jpg';
import { uploadImage } from "../../components/DealChat/blobStorage";


const useStyles = makeStyles(
  {
    input: {
      width: "250px",
    },
    inputBottom: {
      paddingBottom: "20px",
      margin: "-26px 30px 0px 153px",
    },
    label: {
      paddingTop: "14px",
    },
    myprofile: {
      textAlign: "center",
      fontSize: "x-large",
      fontWeight: "800",
      color: "rgb(120, 120, 120)",
      paddingBottom: "20px",
    },
    link: {
      textDecoration: "none",
    },
    basicinfo: {
      paddingBottom: "30px",
      fontSize: "x-large",
      fontWeight: "800",
      color: "rgb(120, 120, 120)",
    },
    basicinfoNotification: {
      paddingBottom: "10px",
      fontSize: "x-large",
      fontWeight: "800",
      color: "rgb(120, 120, 120)",
    },
    floatRight: {
      float: "right"
    },
    textFieldWidth: {
      width: "80%"
    },
    numberField: {
      '& input[type=number]': {
        '-moz-appearance': 'textfield'
      },
      '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
      },
      '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0

      },
    }
  },
  { index: 1 }
);

// function getBlobUrls(uploadResults) {
//   return uploadResults.map(result => result.fileUrl);
// }

// async function uploadMultipleFiles(containerName, subcontainer, files) {
//   const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
//   const results = await Promise.all(uploadPromises);
//   const urls = getBlobUrls(results);
//   return urls;
// }

validate.validators.IsValidPhoneNumber = function (value) {
  if (!validate.isEmpty(value) && value.length !== 10)
    return "Phone Number should be exactly 10 digits long";
  return null;
};

const UserProfile = props => {
  const { restClient: { rest }, userProfileData, userData, companyList, getCompanyList, locale } = props;
  const classes = useStyles();

  const query = useQuery();
  const action = query.get("action");

  // console.log("userProfileData", userProfileData);
  // console.log("userData", userData);

  const user_profile_labels = getTranslations(locale).user_profile_page;

  const profileValues = {
    userID: userData.user._id,
    parentCompany: userData.user.parentCompany._id,
    roleType: userData.user.atgRole && userData.user.atgRole.roleType,
    firstName: userData.user.contact.firstName,
    middleName: userData.user.contact.middleName,
    lastName: userData.user.contact.lastName,
    alias: userData.user.contact.alias,
    emailAddress: userData.user.contact.emailAddress,
    webURL: userData.user.contact.webURL,
    countryCode: userData.user.contact.countryCode,
    mobileNumber: userData.user.contact.mobileNumber,
    whatsAppNumber: userData.user.contact.whatsAppNumber,
    smsNumber: userData.user.contact.smsNumber,
    email: userData.user.email,
    password: userData.user.password,
    phone: userData.user.phone,
    roleCompany: userData.user.roleCompany,
    roleSystem: userData.user.roleSystem,
    dealCreation: userData.user.dealCreation,
    acceptOffer: userData.user.acceptOffer,
    makeOffer: userData.user.makeOffer,
    rejectOffer: userData.user.rejectOffer
  };

  const [formState, setFormState] = useState({
    isValid: false,
    values: profileValues,
    touched: {},
    errors: {},
  });

  const [avatar, setAvatar] = useState(defaultAvatar);
  const [file, setFile] = useState(null);

  const LoggedID = userData && userData.user && userData.user._id;

  useEffect(() => {
    if (userProfileData) {
      setAvatar(userProfileData?.profilePath || defaultAvatar);
    }
  }, [userProfileData]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result);
      };
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  //   Dailog Box State -------
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (rest) {
      const getUserParentCompanies = async () => {
        getCompanyList();
      };
      getUserParentCompanies();
    }
  }, [rest, getCompanyList]);

  useEffect(() => {
    if (action === "edit" && userProfileData) {
      setFormState({
        isValid: false,
        values: userProfileData,
        touched: {},
        errors: {},
      });
      setAvatar(userProfileData?.profilePath || defaultAvatar);
    }
  }, [action, userProfileData]);

  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value,
      },
      touched: {
        ...prevFormState.touched,
        [field]: true,
      },
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.values]);

  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  };

  const StartCase = (text) => {
    return text
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true,
        };
      }
    }
    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  };
  const submitForm = async (event) => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = getPayload(values);
      // pass payload to Api layer.
      if (file) {
        const result = await uploadImage("user", `${LoggedID}`, file);
        payload.profilePath = result.fileUrl;
      }
      props.editUserProfile(values.userID, payload);
    }
  };
  return (
    <div style={{ padding: '15px' }}>
      <div className={classes.myprofile}>{user_profile_labels.page_title}</div>
      <div className={classes.basicinfo}>{user_profile_labels.basic_info}</div>

      <form onSubmit={submitForm}>
        <Grid container spacing={0}>
          <Grid item xs={12} lg={6}>
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>First Name:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  error={hasError('firstName')}
                  helperText={
                    hasError('firstName') ? formState.errors.firstName[0] : null
                  }
                  variant="outlined"
                  label={user_profile_labels.first_name}
                  fullWidth
                  data-test-id="Register-User-FirstName"
                  placeholder={user_profile_labels.first_name_placeholder}
                  type="text"
                  name="firstName"
                  value={formState.values.firstName}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>

            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel>Middle Name:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  // error={hasError('middleName')}
                  // helperText={
                  //     hasError('middleName') ? formState.errors.middleName[0] : null
                  // }
                  label={user_profile_labels.middle_name}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-MiddleName"
                  placeholder={user_profile_labels.middle_name}
                  type="text"
                  name="middleName"
                  value={formState.values.middleName}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>Last Name:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  error={hasError('lastName')}
                  helperText={
                    hasError('lastName') ? formState.errors.lastName[0] : null
                  }
                  label={user_profile_labels.last_name}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-LastName"
                  placeholder={user_profile_labels.last_name}
                  type="text"
                  name="lastName"
                  value={formState.values.lastName}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel>Title:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  label={user_profile_labels.title}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-Title"
                  placeholder={user_profile_labels.title}
                  type="text"
                  name="title"
                  value={formState.values.title}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel>Company Name:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <Select
                  // label="Select Parent Company"
                  error={hasError('parentCompany')}
                  variant="outlined"
                  id="parentCompany-select-outlined"
                  fullWidth
                  data-test-id="Register-User-ParentCompany"
                  placeholder={user_profile_labels.parent_company}
                  value={formState.values.parentCompany}
                  onChange={handleChange}
                  inputProps={{
                    name: 'parentCompany',
                    id: 'parentCompany',
                  }}
                  style={{ width: "80%" }}
                >
                  <MenuItem value="0">{user_profile_labels.select_company}</MenuItem>
                  {(companyList || []).map((company) => <MenuItem value={company.id}>{company.companyName}</MenuItem>)}
                </Select>
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel>Phone Number:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  error={hasError('phone')}
                  helperText={
                    hasError('phone') ? formState.errors.phone[0] : null
                  }
                  label={user_profile_labels.phone}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-Phone"
                  placeholder={user_profile_labels.phone}
                  type="number"
                  name="phone"
                  className={classes.numberField}
                  value={formState.values.phone}
                  onChange={handleStartCaseChange}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>Mobile Number:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  error={hasError('mobileNumber')}
                  helperText={
                    hasError('mobileNumber') ? formState.errors.mobileNumber[0] : null
                  }
                  label={user_profile_labels.mobile_number}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-MobileNumber"
                  placeholder={user_profile_labels.mobile_number}
                  type="number"
                  name="mobileNumber"
                  className={classes.numberField}
                  value={formState.values.mobileNumber}
                  onChange={handleStartCaseChange}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel>WhatsApp Number:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  // error={hasError('whatsAppNumber')}
                  // helperText={
                  //     hasError('whatsAppNumber') ? formState.errors.whatsAppNumber[0] : null
                  // }
                  label={user_profile_labels.whats_app}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-WhatsAppNumber"
                  placeholder={user_profile_labels.whats_app}
                  type="number"
                  name="whatsAppNumber"
                  className={classes.numberField}
                  value={formState.values.whatsAppNumber}
                  onChange={handleStartCaseChange}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            <div>
              <div className={classes.basicinfoNotification}>{user_profile_labels.notifications}</div>
              <div>
                <div>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      checked={formState.values.dealCreation}
                      onChange={handleChange}
                      type="checkbox"
                      name="dealCreation"
                      control={<Checkbox color="primary" />}
                      label={user_profile_labels.deal_creation}
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      checked={formState.values.makeOffer}
                      onChange={handleChange}
                      type="checkbox"
                      name="makeOffer"
                      control={<Checkbox color="primary" />}
                      label={user_profile_labels.make_offer}
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      checked={formState.values.acceptOffer}
                      onChange={handleChange}
                      type="checkbox"
                      name="acceptOffer"
                      control={<Checkbox color="primary" />}
                      label={user_profile_labels.accept_offer}
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
                <div>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      checked={formState.values.rejectOffer}
                      onChange={handleChange}
                      type="checkbox"
                      name="rejectOffer"
                      control={<Checkbox color="primary" />}
                      label={user_profile_labels.reject_offer}
                      labelPlacement="end"
                    />
                  </FormControl>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>Email Address:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  error={hasError('email')}
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label={user_profile_labels.email}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-Email"
                  placeholder={user_profile_labels.email}
                  type="text"
                  name="email"
                  value={formState.values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>Country:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl variant="outlined" className="w-100">
                <InputLabel
                  id="countryCode-select-outlined-label"
                >
                  {user_profile_labels.country}
                </InputLabel>
                <Select
                  error={hasError("countryCode")}
                  labelId="countryCode-select-outlined-label"
                  id="countryCode-select-outlined"
                  label={user_profile_labels.country}
                  data-test-id="Create-User-CountryCode"
                  placeholder={user_profile_labels.country_placeholder}
                  value={formState.values.countryCode}
                  onChange={handleChange}
                  inputProps={{
                    name: "countryCode",
                    id: "countryCode",
                  }}
                  style={{ width: "80%" }}
                >
                  <MenuItem value="0" disabled>{user_profile_labels.select_country}</MenuItem>
                  {[
                    { name: "USA", value: "1" },
                    { name: "India", value: "91" },
                  ].map((countryCode) => (
                    <MenuItem
                      key={countryCode.value}
                      value={countryCode.value}
                    >
                      {countryCode.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>Zip/Postal Code:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  label={user_profile_labels.postal_code}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-Zip"
                  placeholder={user_profile_labels.postal_code}
                  type="number"
                  name="zip"
                  className={classes.numberField}
                  value={formState.values.zip}
                  onChange={handleStartCaseChange}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>Address 1:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  label={user_profile_labels.address_1}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-Address1"
                  placeholder={user_profile_labels.address_1}
                  type="text"
                  name="address1"
                  value={formState.values.address1}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel>Address 2:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  label={user_profile_labels.address_2}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-Address2"
                  placeholder={user_profile_labels.address_2}
                  type="text"
                  name="address2"
                  value={formState.values.address2}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>City:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  label={user_profile_labels.city}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-City"
                  placeholder={user_profile_labels.city}
                  type="text"
                  name="city"
                  value={formState.values.city}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>State/Province:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <TextField
                  label={user_profile_labels.state}
                  variant="outlined"
                  fullWidth
                  data-test-id="Register-User-State"
                  placeholder={user_profile_labels.state}
                  type="text"
                  name="state"
                  value={formState.values.state}
                  onChange={handleStartCaseChange}
                  onBlur={handleBlur}
                  style={{ width: "80%" }}
                />
              </FormControl>
            </FormControlWrapper>
            {/* </div>
            </div> */}
            {/* <div className={classes.text}>
              <div className={classes.label}><InputLabel required>My Role Type:</InputLabel></div>
              <div className={classes.inputBottom}> */}
            <FormControlWrapper>
              <FormControl>
                <Select
                  variant="outlined"
                  fullWidth
                  error={hasError('roleType')}
                  id="roleType-select-outlined"
                  data-test-id="Register-User-roleType"
                  placeholder="Dominant Role"
                  value={formState.values.roleType}
                  onChange={handleChange}
                  inputProps={{
                    name: 'roleType',
                    id: 'roleType',
                  }}
                  style={{ width: "80%" }}
                >
                  <MenuItem value="0">{user_profile_labels.select_role}</MenuItem>
                  {[{ name: 'Trader' }, { name: 'User' }, { name: 'Service Provider' }, { name: 'Executive' }].map((roleType) => <MenuItem value={roleType.name}>{roleType.name}</MenuItem>)}
                </Select>
              </FormControl>
            </FormControlWrapper>
            <div>
              <div className={classes.basicinfoNotification}>{user_profile_labels.user_profile_avatar}</div>
              <div>
                <img src={avatar} alt="Avatar" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
              </div>
              <input type="file" accept="image/*" onChange={handleFileChange} />
            </div>
          </Grid>
        </Grid>
        <div>
          {/* <div><UserProfileAvatar /></div> */}
        </div>
        {/* <div>
          <div className={classes.basicinfoNotification}>Notifications</div>
          <div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  checked={formState.values.dealCreation}
                  onChange={handleChange}
                  type="checkbox"
                  name="dealCreation"
                  control={<Checkbox color="primary" />}
                  label="Deal Creation"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  checked={formState.values.makeOffer}
                  onChange={handleChange}
                  type="checkbox"
                  name="makeOffer"
                  control={<Checkbox color="primary" />}
                  label="Make Offer"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  checked={formState.values.acceptOffer}
                  onChange={handleChange}
                  type="checkbox"
                  name="acceptOffer"
                  control={<Checkbox color="primary" />}
                  label="Accept Offer"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
            <div>
              <FormControl component="fieldset">
                <FormControlLabel
                  checked={formState.values.rejectOffer}
                  onChange={handleChange}
                  type="checkbox"
                  name="rejectOffer"
                  control={<Checkbox color="primary" />}
                  label="Reject Offer"
                  labelPlacement="end"
                />
              </FormControl>
            </div>
          </div>
        </div> */}
        {/* <div><UserChangePassword /></div> */}
        {/* <div><UserProfilePreferences /></div> */}

        {/* <div>
          <UserChangePassword />
        </div> */}

        {/* --- CHANGE PASSWORD DAILOG BOX --- */}
        {/* <Dialog
          open={open}
          onClose={handleClose}
          fullWidth="true"
        //   maxWidth="md"
        >
          <CardContent>
            <UserChangePassword />
          </CardContent>&nbsp;
          <DialogActions>
            <Button
              onClick={handleClose}
              type="submit"
              color="secondary"
              variant="contained"
              size="large"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog> */}

        {/* <div><UserProfilePreferences /></div> */}

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "10px",
            paddingTop: "20px",
            gap: "40px",
          }}
        > */}
        {/* <Link
            style={{
              color: "#FFBF00",
              textDecoration: "none",
            }}
            onClick={handleClickOpen}
          >
            Change Password
          </Link> */}
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={5}>
              <Grid item>
                <FormButtonWrapper className="align-box-row justify-box-row">
                  <div>
                    <Link to="/dealpage" className={classes.link}>
                      <Button
                        type="cancel"
                        variant="contained"
                        size="large"
                        sx={{ backgroundColor: "#f50057" }}
                      >
                        {user_profile_labels.cancel}
                      </Button>
                    </Link>
                  </div>
                </FormButtonWrapper>
              </Grid>
              <Grid item>
                <FormButtonWrapper className="align-box-row justify-box-row">
                  <div style={{ paddingLeft: "1px" }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="large"
                      className="Buttons"
                    >
                      {user_profile_labels.save}
                    </Button>
                  </div>
                </FormButtonWrapper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* </div> */}
      </form>
    </div>
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state.company.companyList.companyList,
  userProfileData: state.user.userProfile,
  userData: state.user.userData,
  locale: state.locale
});

export default connect(mapStateToProps, { editUserProfile, getCompanyList })(
  UserProfile
);
