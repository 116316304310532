import React, { useState, useEffect } from "react";
import { Visibility, VisibilityOff, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getDealById } from "../../reducers/DealReducer";
import { setLoggedInUserData } from "../../reducers/UserReducer";
import useQuery from "../../utils/useQuery";
import DealSome from "../DealCloseOut";
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid";
import {
  Card,
  Grid,
  Checkbox,
  Button,
  TextField,
  Box,
  Typography,
  InputAdornment,
  IconButton
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { submitTransactionRegisterForm } from "../../reducers/TransactionReducer";
import { ThemeProvider, createTheme } from '@mui/material';
import { submitMakeOfferMessage } from "../../reducers/DealCommunicationReducer";
import DealMessageChat from "../DealChat";


const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  makeOfferContainer: {
    // margin: 10,
    display: "flex",
    justifyContent: "flex-start",
    gap: 10,
    flexShrink: 0,
  },
  makeOffer: {
    color: "rgb(204, 102, 0)",
    fontSize: "large",
    fontWeight: 700,

    // marginTop: "270px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "medium",
      // fontWeight: 700,
    },
  },

  makeOfferFlex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },

  gridChild: {
    // paddingTop: "20px",
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  icon: {
    // marginTop: "-3px",
    // paddingRight: "5px",

    color: "rgb(204, 102, 0)",
    alignItems: "center",
  },
  parent: {
    display: "grid",
    gridTemplateColumns: "7rem 2rem 7rem",
    paddingLeft: "2px",
    marginTop: "-2px",
  },
  input: {
    padding: "7px 0px 8px 0px",
    width: "150px",
    textAlign: "center",
    borderRadius: 3,
    border: "1px solid #555",
  },
  makeSeller: {
    paddingLeft: "90px",
    color: "rgb(204, 102, 0)",
    fontSize: "large",
    fontWeight: 700,
  },
  salConst: {
    fontSize: "X-large",
    fontWeight: "bolder",
  },
  salConsttwo: {
    fontSize: "large",
    fontWeight: "bolder",
  },
  bottonGrid: {
    paddingLeft: "90px",
    marginTop: "-78px",
  },
  link: {
    textDecoration: "none",
  },
  mediaLink: {
    textDecoration: "none",
    color: "black",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  btnwidth: {
    width: "134%",
    paddingTop: "10px",
  },
  dataGrid: {
    // paddingBottom: "1px",
  },
  numberField: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  MakeButton: {
    color: "white",
    backgroundColor: "rgb(62, 82, 114)",
    "&:hover": {
      backgroundColor: "#fbbc05",
      //   color: "black"
    },
  },
  childDiv: {
    paddingTop: "10px",
    paddingLeft: "50px",
  },
  dealEquip: {
    paddingLeft: "10px",
  },
}));

const DataGridDemo = (props) => {
  const {
    restClient: { rest },
    dealData,
    getDealById,
    loggedInUserData,
    dealCommunicationList = []
  } = props;
  const classes = useStyles();
  const [data, setData] = useState();
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [showSerialNumbers, setShowSerialNumbers] = useState(false);
  const [footerHeight, setFooterHeight] = useState(0);
  const [sortedUserList, setSortedUserList] = useState([]);
  const [equipment, setEquipment] = useState([]);

  // console.log("dealData", dealData);

  const toggleSerialNumberVisibility = () => {
    setShowSerialNumbers(!showSerialNumbers);
  };

  const val = dealData?.dealParticipants?.filter((p) => p.participant._id !== loggedInUserData.user._id);

  const query = useQuery();
  const dealID = query.get("dealId");
  const [first, setFirst] = useState([]);
  const transactionId = "6231b09f5292800b4074918e";


  useEffect(() => {
    if (dealID && rest) {
      const getDeal = async () => {
        getDealById(dealID);
      };
      getDeal();
    }
  }, [dealID, rest, getDealById]);

  useEffect(() => {
    if (Array.isArray(dealData.dealEquipment)) {
      setEquipment(dealData.dealEquipment);
    }
  }, [dealData.dealEquipment]);

  useEffect(() => {
    const getList = async () => {
      const otherDealMembers = (dealData?.dealParticipants || [])?.filter((item) => item?.participant?._id != loggedInUserData?.user?._id);
      setSortedUserList(otherDealMembers);
    }
    getList();
  }, [dealData, setSortedUserList, loggedInUserData]);

  const getRowData = () => {
    // const equipmentsData = rows
    //   .filter((object) => selectedRows.includes(object._id))
    //   .map((un) => un._id);
    const transaction = {
      dealId: dealID,
      equipment: first,
      selectionName: "selectionName",
      price: data,
      addedBy: loggedInUserData.user._id,
    };
    const transactioncommunication = {
      transactioncommunication: {
        dealId: dealID,
        transactionId: transactionId,
        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
        transactionEquipment: first,
        from: loggedInUserData.user._id,
        to: dealData?.seller,
        notificationType: "Make Offer",
        notes: "notes",
        askPrice: "",
        offerPrice: data,
        priceUnit: "USD",
        addedBy: loggedInUserData.user._id,
      },
      dealname: dealData.dealName,
      dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
    }

    const payload = { transaction };

    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        val?.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    props.submitTransactionRegisterForm(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
    props.submitMakeOfferMessage(transactioncommunication);
  };

  function generateRandom() {
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const handleCheckboxChange = (event, rowId) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const handleTextFieldChange = (event, rowId) => {
    if (rowId) {
      let val = 0;
      const elems = document.getElementsByName("num1");
      elems.forEach(function (valueCheck) {
        if (valueCheck.value !== "") val = val + parseFloat(valueCheck.value);
      });
      setData(val);
      setFirst([...first, rowId]);
    }
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              DEAL EQUIPMENT
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={12} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function formatCurrency(value) {
    if (value) {
      return `$${value}`;
    } else {
      return null;
    }
  }

  const reserveValue = loggedInUserData?.user?._id === dealData?.seller;

  const isSeller = loggedInUserData?.user?._id === dealData?.seller;

  const isBuyer = dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData?.user?._id);

  const usertype = loggedInUserData?.user?._id === dealData?.seller ? "Seller" : dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) ? "Buyer" : loggedInUserData?.user?.atgRole?.roleType[0];
  // console.log("logged", loggedInUserData);

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: isSeller ? "#2ecd71" : isBuyer ? "#1a75c3" : "#f1c40f",
              color: "white"
            },
          },
          columnSeparator: {
            visibility: "hidden",
          },
        },
      },
    },
  });
  useEffect(() => {
    const footerElement = document.getElementById('atg-footer');

    if (footerElement) {
      const heightOfFooter = footerElement.offsetHeight;
      setFooterHeight(heightOfFooter);
    }
  }, []);

  return (
    <Card sx={{ height: '100%' }}>
      <DealMessageChat users={sortedUserList} equipment={equipment} isBuyer={isBuyer} isSeller={isSeller} usertype={usertype} />
    </Card>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  dealData: state.deal && state.deal.dealData,
  loggedInUserData: state.user && state.user.loggedInUserData,
  dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
});
export default connect(mapStateToProps, {
  getDealById,
  setLoggedInUserData,
  submitTransactionRegisterForm,
  submitMakeOfferMessage
})(DataGridDemo);
