import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { submitCompanyRegisterForm, getCompanyList, deleteCompany } from '../../../reducers/CompanyReducer';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SearchBar from '../../../utils/Search';
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  root: {
    maxWidth: "100%",
    height: "400px",
  },
  actions: {
    color: "#3E5272",
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-3rem"
  },
  cardStyle: {
    height: "480px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem",
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      height: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
  },
});

const CompanyList = (props) => {
  const {
    companyList = [],
    restClient: { rest },
    getCompanyList,
    totalCount,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });
  const [rowCountState, setRowCountState] = React.useState(totalCount);
  const [filteredCompanyList, setFilteredCompanyList] = useState([]);
  const [search, setSearch] = React.useState('');

  // useEffect(() => {
  //   // Set filteredUserList to userList initially
  //   if(companyList?.length > 0){
  //     setFilteredCompanyList(companyList);
  //   }
  // }, [companyList]);

  useEffect(() => {
    // Filter user list when search state changes
    if (companyList?.length > 0) {
      const filteredList = companyList.filter(user =>
        (user.companyName && user.companyName.toLowerCase().includes(search.toLowerCase())) ||
        (user.description && user.description.toLowerCase().includes(search.toLowerCase())) ||
        (user.emailAddress && user.emailAddress[0].toLowerCase().includes(search.toLowerCase())) ||
        (user.phoneNumber && user.phoneNumber.toLowerCase().includes(search.toLowerCase()))
      );

      setFilteredCompanyList(filteredList);
      if (filteredList.length > 0) {
        setRowCountState(filteredList.length);
      }
    }
  }, [search, companyList]);

  // Get the data from companyreducer
  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          if (pageState) {
            await getCompanyList(pageState);
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getCompanyList, pageState]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  //company edit
  const handleCompanyEdit = (event, company) => {
    history.push(`/company/create?action=edit&id=${company.id}`);
  };
  //company view
  const handleCompanyView = (event, company) => {
    history.push(`/view/company?action=ViewColumn&id=${company.id}`);
  };
  //company delete
  const handleCompanyDelete = (event, company) => {
    props.deleteCompany(company.id, companyList, totalCount);
  };
  //view company branches
  const handleCompanyBranchList = (event, company) => {
    history.push(`/companybranches?id=${company.id}`);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const columns = [
    {
      field: "companyName",
      headerName: "Company",
      width: 270,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              "&:hover": {
                cursor: "pointer",
                fontWeight: "bold",
              },
            }}
            onClick={(event) => {
              handleCompanyView(event, params.row);
            }}
          >
            {params.row.companyName}
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      type: "string",
      width: 280,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.description} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.row.description}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "emailAddress",
      headerName: "Email",
      type: "string",
      width: 300,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 170,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View Company Branches">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleCompanyBranchList(event, params.row);
                }}
              >
                <LocationCityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="View Company">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleCompanyView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit Company">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleCompanyEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete Company">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.companyName +
                    " ?"
                  ) && handleCompanyDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];


  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              COMPANIES
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} sx={isSmallScreen ? {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          } : {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
            <Tooltip title="Create New Company">
              <Link to="/company/create">
                <IconButton sx={{ color: "#3E5272" }}>
                  <AddBoxIcon />
                </IconButton>
              </Link>
            </Tooltip>
            <SearchBar
              searchText={search}
              filterChange={onFilterChange}
              onClickFunc={handleClearText}
            />
          </Grid>
        </Grid>
      </Box>
    )
  }

  // const onFilterChange = (event) => {
  //   // Here you save the data you need from the filter model and perform server-side search/filtering
  //   // Once the server-side search is completed, you can update the searchInput state with the new value

  //   // Example: Simulating server-side search delay with setTimeout
  //   setSearch(event.target.value);
  //   // if (search.length > 4 || search.trim() == '') {
  //   if (event?.target?.value?.length >= 3)
  //     setTimeout(() => {
  //       setPageState((old) => ({ ...old, searchData: event.target.value }));
  //     }, 2000);
  //   if (event?.target?.value?.length < 1)
  //     setPageState((old) => ({ ...old, searchData: '' }));
  //   // }
  // };

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };

  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };
  return (
    <div className={classes.tableheader}>

      <Card className={classes.cardStyle} >
        <ThemeProvider theme={theme}>
          <DataGrid
            rowCount={filteredCompanyList.length >= 0 ? filteredCompanyList.length : rowCountState}
            paginationMode="server"
            rowsPerPageOptions={[100]}
            pagination
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            columns={columns}
            rows={filteredCompanyList || []}
            disableSelectionOnClick
            checkboxSelection
            sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            density="standard"
            // components={{ Toolbar: DataGridTitle }}
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state.company && state.company.companyList.companyList,
  totalCount: state.company && state.company.companyList.total,
  limit: state.company && state.company.companyList.limit,
});

export default connect(mapStateToProps, {
  submitCompanyRegisterForm,
  getCompanyList,
  deleteCompany,
})(CompanyList);