import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitEquipmentRegisterForm,
  getEquipmentList,
  deleteEquipment,
} from "../../../reducers/EquipmentReducer";
import { GetUploadPayload } from "./utils";
import { getCompanyList } from "../../../reducers/CompanyReducer";
import { DataGridPro, GridPagination } from '@mui/x-data-grid-pro';
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  TextField,
  MenuItem,
  Tooltip,
  Button,
  useMediaQuery,
  Stack
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Sample_equipment_file from "../../../utils/Sample_equipment_file.xlsx";
import { getUserList, setLoggedInUserData } from '../../../reducers/UserReducer';
import SearchBar from '../../../utils/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';
import { industryList, industyCategories } from '../CreateEquipment/utils';
import { uploadImage } from "../../DealChat/blobStorage";
import { maxWidth } from "@mui/system";
import getTranslations, { getDataGridLocale } from "../../../utils/getTranslations";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-container--top [role=row]": {
            background: '#3E5272',
            color: "white"
          },
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  header: {
    maxWidth: "730px",
    marginLeft: "-5rem"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-31px"
  },
  textInput: {
    paddingLeft: "10px"
  },
  dropDiv: {
    display: "flex",
    marginLeft: "-8rem"
  },
  dropDiv2: {
    paddingLeft: "70px"
  },
});

function getBlobUrls(uploadResults) {
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) {
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}

const EquipmentList = (props) => {
  const {
    equipmentList = [],
    restClient: { rest },
    getEquipmentList,
    companyList = [],
    getCompanyList,
    loggedInUserData,
    userData,
    getUserList,
    userList,
    totalCount,
    locale
  } = props;

  const dataGridLocaleProps = getDataGridLocale(locale);

  const history = useHistory();
  const classes = useStyles();
  const [file, setFile] = useState();
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });

  // console.log("equipmentList", equipmentList);
  // console.log("loggedInUserData", loggedInUserData);
  // console.log("userData", userData);

  const [rowCountState, setRowCountState] = React.useState(totalCount);
  // const [filteredEquipmentDataList, setFilteredEquipmentDataList] = useState([]);
  const [search, setSearch] = React.useState('');
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [companyValue, setCompanyValue] = useState(null);
  const [industryValue, setIndustryValue] = useState(null);
  const [categoryValue, setCategoryValue] = useState('');
  const [filteredEquipmentList, setFilteredEquipmentList] = useState(equipmentList);
  const [files, setFiles] = useState([]);

  const translationSet = getTranslations(locale);
  const equipment_labels = translationSet.equipment_page;

  // useEffect(() => {
  //   if (equipmentList.length > 0) {
  //     setFilteredEquipmentList(equipmentList);
  //   }
  // }, [equipmentList])
  useEffect(() => {
    const getList = async () => {
      if (rest && loggedInUserData) {
        await getEquipmentList(pageState, loggedInUserData);
        await getCompanyList(pageState);
      }
    };
    getList();
  }, [rest, getEquipmentList, loggedInUserData, getCompanyList, pageState]);

  useEffect(() => {
    if (rest) {
      const getList = async () => {
        getUserList();
      }
      getList();
    }
  }, [rest, getUserList])

  useEffect(() => {
    if (equipmentList.length > 0) {
      setRowCountState((prevRowCountState) =>
        totalCount !== undefined ? totalCount : prevRowCountState
      );
      setFilteredEquipmentList(equipmentList);
    }
  }, [totalCount, equipmentList]);

  useEffect(() => {
    if (search) {
      const filteredList = equipmentList?.filter(user => {
        const modelYearString = user.modelYear ? user.modelYear.toString() : '';
        const comparableRetailPriceString = user.comparableRetailPrice ? user.comparableRetailPrice.toString() : '';
        return (
          (user.equipmentName && user.equipmentName.toLowerCase().includes(search.toLowerCase())) ||
          (user.make && user.make.toLowerCase().includes(search.toLowerCase())) ||
          (user.model && user.model.toLowerCase().includes(search.toLowerCase())) ||
          (modelYearString && modelYearString.includes(search.toLowerCase())) ||
          (user.majorCategory && user.majorCategory.toLowerCase().includes(search.toLowerCase())) ||
          (user.mediaLink && user.mediaLink.toLowerCase().includes(search.toLowerCase())) ||
          (comparableRetailPriceString && comparableRetailPriceString.includes(search.toLowerCase()))
        );
      });

      setFilteredEquipmentList(filteredList);
      // if(filteredList?.length >= 0){
      setRowCountState(filteredList.length);
      // }
    } else {
      setFilteredEquipmentList(equipmentList);
      // if(equipmentList?.length > 0){
      setRowCountState(totalCount);
      // }
    }
  }, [search, totalCount]);


  // useEffect(() => {
  //   const getList = async () => {
  //     if (rest && loggedInUserData) {
  //       await getEquipmentList(pageState, loggedInUserData);
  //       await getCompanyList(pageState);
  //     }
  //   };
  //   getList();
  // }, [rest, getEquipmentList, loggedInUserData, getCompanyList, pageState]);

  // useEffect(() => {
  //   if (rest) {
  //     const getList = async () => {
  //       getUserList();
  //     }
  //     getList();
  //   }
  // }, [rest, getUserList])

  // useEffect(() => {
  //   if (rest) {
  //     const getEquipmentCompany = async () => {
  //       getCompanyList();
  //     };
  //     getEquipmentCompany();
  //   }
  // }, [rest, getCompanyList]);

  // React.useEffect(() => {
  //   setRowCountState((prevRowCountState) =>
  //     totalCount !== undefined ? totalCount : prevRowCountState
  //   );
  // }, [totalCount, setRowCountState]);

  function handleChange(event) {
    setFile(event.target.files[0])
    setDisabled(false);
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  }

  const handleEquipmentEdit = (event, equipment) => {
    history.push(`/equipment/create?action=edit&id=${equipment.id}`);
  };
  const handleEquipmentView = (event, equipment) => {
    history.push(`/view/equipment?action=ViewColumn&id=${equipment.id}`);
  };
  const handleEquipmentDelete = (event, equipment) => {
    props.deleteEquipment(equipment.id, equipmentList, totalCount);
  };


  const columns = [
    {
      field: "equipmentName",
      headerName: equipment_labels.equipment_name,
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.equipmentName} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    fontWeight: "bold",
                  },
                }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                {params.row.equipmentName}
              </Box>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "companyName",
      headerName: equipment_labels.company,
      width: 160
    },
    {
      field: "make",
      headerName: equipment_labels.make,
      width: 100,
    },
    {
      field: "model",
      headerName: equipment_labels.model,
      width: 120,
    },
    {
      field: "modelYear",
      headerName: equipment_labels.model_year,
      width: 120
    },
    {
      field: "majorCategory",
      headerName: equipment_labels.major_category,
      type: "string",
      width: 150,
    },
    // {
    //   field: "fleetType",
    //   headerName: "Fleet Type",
    //   width: 100,
    // },
    {
      field: "mediaLink",
      headerName: equipment_labels.media,
      width: 100,
      renderCell: (params) => (
        <a
          href={`https://${params.value}`}
          rel="noreferrer"
          target="_blank"
          className={classes.link}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: "comparableRetailPrice",
      headerName: equipment_labels.retail_price,
      type: "string",
      width: 150,
    },
    {
      field: "actions",
      headerName: equipment_labels.actions,
      sortable: false,
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={equipment_labels.view_equipment}>
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={equipment_labels.edit_equipment}>
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleEquipmentEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title={equipment_labels.delete_equipment}>
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " +
                    params.row.equipmentName +
                    " ?"
                  ) && handleEquipmentDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const ImportExport = (event) => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase()) && companyList) {
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = async (e) => {
            const payload = GetUploadPayload(reader.result, companyList, userData, userList, loggedInUserData);
            if (payload?.length > 0) {
              const invalidCreatedBy = payload.some(item => !item.createdBy);
              if (invalidCreatedBy) {
                if (loggedInUserData.user.roleSystem === "regular") {
                  alert("Invalid 'createdBy' in the uploaded Excel file.");
                }
                return;

              } else {
                let blobPath = [];
                if (files.length > 0) {
                  blobPath = files.map((file) => file.name);
                  await uploadMultipleFiles("fileupload", "equipment", files);
                  setFiles([]);
                }
                props.submitEquipmentRegisterForm(payload, "upload", loggedInUserData);
              }
            } else {
              return 0;
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref.current.form.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };


  function DataGridTitle() {
    return (
      <>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} lg={6} md={6} sm={3}>
            <form>
              <h3>Upload Equipment Excel File (.xlsx):
                <input
                  type="file"
                  onChange={handleChange}
                  ref={ref}
                  className={classes.textInput}
                />
              </h3>
            </form>
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <Button
              component="label"
              variant="contained"
              endIcon={<UploadFileOutlinedIcon />}
              onClick={ImportExport}
              disabled={disabled}
            >
              Upload file
            </Button>
          </Grid>
          <Grid item lg={3} xs={12} md={3} sm={3} sx={{ display: 'flex', justifyContent: "flex-end" }}>
            <a
              href={Sample_equipment_file}
              download="Sample_equipment_file"
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined" size="small" sx={{ color: "#3E5272" }}>
                Download Sample Excel file
              </Button>
            </a>
          </Grid>
        </Grid>
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <TextField
              id="filled-select-currency"
              select
              label="Select Company"
              variant="outlined"
              onChange={handleCompanyChange}
              size="medium"
              value={companyValue}
              fullWidth
            >
              {companyList.map((option) => (
                <MenuItem key={option.value} value={option.id}>
                  {option.companyName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="filled-select-currency"
              select
              label="Select Industry"
              variant="outlined"
              onChange={handleIndustryChange}
              size="medium"
              fullWidth
              value={industryValue}
            >
              {industryList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3}>
            <TextField
              id="filled-select-currency"
              select
              label="Select Category"
              variant="outlined"
              onChange={handleCategoryChange}
              size="medium"
              value={categoryValue}
              fullWidth
            >
              {transformedData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3} sx={{ display: 'flex', justifyContent: "flex-end" }}>
            <SearchBar
              searchText={search}
              filterChange={onFilterChange}
              onClickFunc={handleClearText}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={9} md={9} sm={9}>
            <Typography variant="h5"
              sx={{
                margin: '6px',
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              EQUIPMENT
            </Typography>
          </Grid>
          <Grid item xs={12} lg={3} md={3} sm={3} sx={{ display: 'flex', justifyContent: "flex-end" }}>
            <Tooltip title={equipment_labels.create_equipment_tooltip}>
              <Link to="/equipment/create">
                <IconButton sx={{ color: "#3E5272" }}>
                  <AddBoxIcon />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid >
      </>
    );
  }

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  const handleCompanyChange = (event) => {
    const selectedCompany = event.target.value;
    setCompanyValue(selectedCompany);

    // Filter equipment based on selected company and industry and category
    filterEquipment(selectedCompany, industryValue, categoryValue);
  };

  const handleIndustryChange = (event) => {
    const selectedIndustry = event.target.value;
    setIndustryValue(selectedIndustry);

    // Filter equipment based on selected company and industry and category
    filterEquipment(companyValue, selectedIndustry, categoryValue);
  };

  const handleCategoryChange = (event) => {
    const selectedCategory = event.target.value;
    setCategoryValue(selectedCategory);

    // Filter equipment based on selected company and industry and category
    filterEquipment(companyValue, industryValue, selectedCategory);
  };

  const filterEquipment = (selectedCompany, selectedIndustry, selectedCategory) => {
    let filteredList = equipmentList;

    if (selectedCompany) {
      filteredList = filteredList.filter((item) => item.companyId === selectedCompany);
    }

    if (selectedIndustry) {
      filteredList = filteredList.filter((item) => item.industry === selectedIndustry);
    }

    if (selectedCategory) {
      filteredList = filteredList.filter((item) => item.majorCategory === selectedCategory);
    }

    setFilteredEquipmentList(filteredList);

    // setPageState((old) => ({ ...old, total: filteredList.length }));
  };

  const transformedData = industyCategories.reduce((acc, category) => {
    const categoryOptions = category.majorCategory.map((subcategory) => ({
      value: subcategory,
      label: subcategory,
    }));

    return [...acc, ...categoryOptions];
  }, []);


  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Card takes 100% height of its parent
        minHeight: 0,   // Ensures proper handling of flexbox in some situations
        padding: '15px'
      }}
    >
      <DataGridTitle />
      <ThemeProvider theme={theme}>
        <DataGridPro
          rows={filteredEquipmentList || []}
          columns={columns}
          rowCount={rowCountState}
          paginationMode="server"
          rowsPerPageOptions={[100]}
          pagination
          onPageChange={(newPage) => {
            setPageState((old) => ({ ...old, page: newPage + 1 }));
          }}
          checkboxSelection
          disableSelectionOnClick
          sortingMode="server"
          onSortModelChange={(col) => {
            if (col.length > 0) {
              if (col[0].sort === "asc") {
                setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
              } else {
                setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
              }
            } else {
              setPageState((old) => ({ ...old, sort: null }));
            }
          }}
          sx={{
            "& .css-ptiqhd-MuiSvgIcon-root": {
              color: "#ee9907",
            },
            // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
            //   display: 'none'
            // }
          }}
          // density='standard'
          // components={{ Toolbar: DataGridTitle }}
          components={{
            Toolbar: DataGridTitle,
            Pagination: GridPagination,
          }}
          localeText={dataGridLocaleProps}
        />
      </ThemeProvider>
    </Card>
  );
};
const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyList: state.company && state?.company?.companyList?.companyList,
  equipmentList: state.equipment && state?.equipment?.equipmentList?.equipmentList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  totalCount: state.equipment && state?.equipment?.equipmentList?.total,
  userData: state.user.userData,
  userList: state.user && state.user.userList.userList,
  locale: state.locale
});
export default connect(mapStateToProps, {
  submitEquipmentRegisterForm,
  getEquipmentList,
  deleteEquipment,
  getCompanyList,
  setLoggedInUserData,
  getUserList
})(EquipmentList);
