import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../../styles/appStyles';
import {
  FormButtonWrapper
} from '../../../styles/formStyles';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import { getEquipmentById } from '../../../reducers/EquipmentReducer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';


const ViewEquipment = props => {
  const { restClient: { rest }, equipmentData, getEquipmentById } = props;
  const query = useQuery();
  const equipmentID = query.get('id');
  const history = useHistory();

  const handleCancel = (event) => {
    event.preventDefault();
    // Go back to the previous page
    history.goBack();
  };

  useEffect(() => {
    if (equipmentID && rest) {
      const getEquipment = async () => {
        getEquipmentById(equipmentID);
      }
      getEquipment();
    }
  }, [equipmentID, rest, getEquipmentById])

  return (
    <Grid
      item
      xs={12}
      lg={12}>
      <Card>
        <Header >
          <HeaderText >
            <h5>{'View Equipment Details'}</h5>
          </HeaderText>
        </Header>
        <CardContent >
          <form className='formSpacing'>
            <Grid container rowSpacing={1}>
              <Grid container xs={12} md={12} lg={12}>
                <TableContainer elevation={0} >
                  <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                    <TableBody>
                      <TableRow key={equipmentData.branchID} >
                        <TableCell component="th" scope="row" style={{ width: 50 }}>
                          {"Branch Id"}
                        </TableCell>
                        <TableCell style={{ width: 160 }} >
                          {equipmentData.branchID}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.createdByName} >
                        <TableCell component="th" scope="row" style={{ width: 50 }}>
                          {"CreatedBy"}
                        </TableCell>
                        <TableCell style={{ width: 160 }} >
                          {equipmentData.createdByName}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.companyEquipmentID}>
                        <TableCell component="th" scope="row">
                          {"Company Equipment ID"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.companyEquipmentID}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.equipmentName}>
                        <TableCell component="th" scope="row">
                          {"Equipment Name"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.equipmentName}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.equipmentDescription}>
                        <TableCell component="th" scope="row">
                          {"Equipment Description"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.equipmentDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.companyName}>
                        <TableCell component="th" scope="row">
                          {"Company Name"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.companyName}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.fleetType}>
                        <TableCell component="th" scope="row">
                          {"Fleet Type"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.fleetType}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.make}>
                        <TableCell component="th" scope="row">
                          {"Manufacturing Company"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.make}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.majorCategory}>
                        <TableCell component="th" scope="row">
                          {"Major Category"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.majorCategory}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.model}>
                        <TableCell component="th" scope="row">
                          {"Model"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.model}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.modelYear}>
                        <TableCell component="th" scope="row">
                          {"Model Year"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.modelYear}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.meterRead}>
                        <TableCell component="th" scope="row">
                          {"Meter Read"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.meterRead}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.serialNum}>
                        <TableCell component="th" scope="row">
                          {"Serial Number"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.serialNum}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.quantity}>
                        <TableCell component="th" scope="row">
                          {"Quantity"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.quantity}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.currentLocationCity}>
                        <TableCell component="th" scope="row">
                          {"CurrentLocationCity"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.currentLocationCity}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.pricingModel}>
                        <TableCell component="th" scope="row">
                          {"Pricing Model"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.pricingModel}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.reservePrice}>
                        <TableCell component="th" scope="row">
                          {"Reserve Price"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.reservePrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.mediaLink}>
                        <TableCell component="th" scope="row">
                          {"MediaLink"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          <a href={`https://${equipmentData.mediaLink}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "rgb(120, 120, 120)" }} >{equipmentData.mediaLink}</a>
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.industry}>
                        <TableCell component="th" scope="row">
                          {"Industry"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.industry}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.locationLat}>
                        <TableCell component="th" scope="row">
                          {"LocationLat"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.locationLat}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.locationLong}>
                        <TableCell component="th" scope="row">
                          {"LocationLong"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.locationLong}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.locationStatus}>
                        <TableCell component="th" scope="row">
                          {"LocationStatus"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.locationStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.usageStatus}>
                        <TableCell component="th" scope="row">
                          {"UsageStatus"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.usageStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.qtrProjected}>
                        <TableCell component="th" scope="row">
                          {"QtrProjected"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.qtrProjected}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.photoLink}>
                        <TableCell component="th" scope="row">
                          {"PhotoLink"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.photoLink}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.pricingTiers}>
                        <TableCell component="th" scope="row">
                          {"Pricing Tiers"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.pricingTiers}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.assetDescription}>
                        <TableCell component="th" scope="row">
                          {"Asset Description"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.assetDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.reservePrice}>
                        <TableCell component="th" scope="row">
                          {"Reserve Price"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.reservePrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.listingPrice}>
                        <TableCell component="th" scope="row">
                          {"Listing Price"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.listingPrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.comparableRetailPrice}>
                        <TableCell component="th" scope="row">
                          {"Comparable Retail Price"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.comparableRetailPrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.needStatus}>
                        <TableCell component="th" scope="row">
                          {"Need Status"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.needStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.availabilityStatus}>
                        <TableCell component="th" scope="row">
                          {"Availability Status"}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.availabilityStatus}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            &nbsp;&nbsp;&nbsp;
            <FormButtonWrapper>
              {/* <Link to="/equipment" className='linkStyle'> */}
              <Button color='primary' variant='contained' type='cancel' onClick={handleCancel} data-test-id="View-Equipment-GoBack" text-decoration='none'>
                Go Back
              </Button>
              {/* </Link> */}
            </FormButtonWrapper>
            {/* <div><button onClick={handleCancel}>Cancel</button></div> */}
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};
const mapStateToProps = state => ({
  restClient: state.restClient,
  equipmentData: state.equipment.equipmentData
});
export default connect(mapStateToProps, { getEquipmentById })(ViewEquipment);